import { isDev } from "../../../common/utils";
import { defaultDevShop, PLANS } from "../../../common/constants";

const initialState = {
  shopData: null,
  shopName: isDev() ? defaultDevShop : null,
  isInstalling: false,
  billingDetails: null,
  isActive: null,
  isAuthenticating: true,
  installationParams: {},
  redirect: false,
  permissions: PLANS.FREE.permissions, // default permissions (FREE PLAN)
  counters: null, // count for credits & reviewReqEmails
  introStep: 0,
  isThemeApp: false,
  showIntroSteps: false,
  loggedIn: false,
  aiEnabled: false,
};

export default initialState;

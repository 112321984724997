import "./index.scss";
import React from "react";
import AuthorName from "../../components/AuthorName";
import Avatar from "../../components/Avatar";
import { ReviewContent } from "../../components/CardBody";
import Country from "../../components/Country";
import { PlainRating } from "../../components/RatingBadge";
import ReviewDate from "../../components/ReviewDate";
import { textRefactor } from "../../../../../../../../common/Utils/textRefactor";
import ReviewImages from "../../components/ReviewImages";
import { Row } from "antd";

const DynamicLayout = (props) => {
  const { setShowModal } = props;
  return (
    <>
      <div className="dynamicBody" onClick={() => setShowModal(true)}>
        <div className="dynamicHeader">
          <Row>
            <Avatar {...props} />
            <div className="dynamicInfo">
              <AuthorName {...props} />
              <ReviewDate {...props} />
            </div>
          </Row>
          <Country {...props} />
        </div>
        <PlainRating {...props} />
        <ReviewContent
          {...{ ...props, content: textRefactor(props?.content, 200) }}
        />
      </div>
      <ReviewImages {...{ ...props, displayAll: true }} />
    </>
  );
};

export default DynamicLayout;

import React from "react";

import { Button } from "antd";

const SubmitReviewButton = ({
  loading,
  reviewFormData,
  Header,
  storeFrontData,
  handleSubmit,
  setWriteReview,
}) => {
  return (
    <div className="submitBtnContainer">
      <Button
        className="submitBtn"
        loading={loading || reviewFormData.isUploading}
        style={{
          backgroundColor: Header.subBtnColor,
          color: Header.subRevBtnTextColor,
          borderRadius: `${Header.subBtnShape === "round" ? "100px" : "0px"}`,
        }}
        onClick={
          storeFrontData ? () => handleSubmit() : () => setWriteReview(false)
        }
      >
        Submit Review
      </Button>
    </div>
  );
};

export default SubmitReviewButton;

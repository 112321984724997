import moment from "moment";
import Papa from "papaparse";
import { ReviewTypes } from "../../../../common/constants/reviewTypes";
import { isEmpty, shouldApprove } from "../ReviewForm/helpers";

export const getMultipleProductReviewsCount = (reviews) => {
  let count = 0;
  Object.keys(reviews).forEach(
    (productId) => (count += reviews[productId]?.length)
  );
  return count;
};

export const checkImportLimitCount = (newReviews, productId, permissions) => {
  const canImportUnlimitedly = permissions["unlimitedReviewImport"];
  if (productId) {
    if (!canImportUnlimitedly && newReviews?.length > 100) {
      return false;
    } else {
      return true;
    }
  } else {
    if (
      !canImportUnlimitedly &&
      Object.keys(newReviews).some(
        (productId) => newReviews[productId]?.length > 100
      )
    ) {
      return false;
    } else {
      return true;
    }
  }
};

export const parseFileData = (
  file,
  setError,
  settings,
  onComplete,
  productId
) => {
  Papa.parse(file, {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    complete: ({ data, meta }) => {
      if (data.length <= 0) {
        setError("The file doesn't have any reviews");
      } else {
        if (verifyFileHeaders(meta.fields, setError, productId)) {
          if (productId)
            onComplete(processSingleProductReviews(data, settings, productId));
          else onComplete(processMultipleProductReviews(data, settings));
        }
      }
    },
  });
};

const verifyFileHeaders = (fields, setError, productId) => {
  let preset = [
    // "approved",
    "content",
    // "country",
    "date",
    "displayName",
    "email",
    "rating",
    "photos",
  ];
  productId && preset.push("productId");
  return preset.every((key) => {
    if (fields.includes(key)) return true;
    else {
      // setError(`${key} field missing in CSV file !`);
      setError("CSV does not match the template. Please check and re upload.");
      return false;
    }
  });
};

const processMultipleProductReviews = (data, settings) => {
  const processedReviews = {};
  let newReviewAsArray = [];
  data.forEach((review) => {
    // if productId is not empty, the review is processed for importing
    // else discarded
    if (!isEmpty(review?.productId)) {
      newReviewAsArray = processSingleProductReviews(
        [review],
        settings,
        review?.productId
      );
      // if there are already reviews with same productId
      // insert new one along with others
      if (processedReviews[review?.productId])
        processedReviews[review?.productId] = [
          ...processedReviews[review?.productId],
          ...newReviewAsArray,
        ];
      // else the array with one review is stored under the productId
      else processedReviews[review?.productId] = newReviewAsArray;
    }
  });
  return processedReviews;
};

// returns an array with 1 review
const processSingleProductReviews = (data, settings, productId) => {
  const processedReviews = [];
  data.forEach((review) => {
    let isFilterApproved = true,
      approved = false;

    if (settings.autoPub)
      approved = shouldApprove(settings?.autoPub, review?.rating);

    let country = "US";
    if (review.country) country = review?.country;

    // splitting photos with ","
    let photos = review.photos ? review?.photos.split(",") : [];

    // filtring out empty urls if any
    photos = photos?.filter((imgUrl) => imgUrl !== "");

    // checking if the filter is available
    if (settings.contentFilter) {
      // if content filter is both, all reviews are imported
      if (settings?.contentFilter !== "both") {
        if (
          // if content filter is text, reviews with images are discarded
          (settings?.contentFilter === "text" && photos && photos.length > 0) ||
          // if content filter is image, reviews without images are discarded
          (settings?.contentFilter === "image" &&
            (!photos || photos.length === 0))
        ) {
          isFilterApproved = false;
        }
      }
    }

    if (settings.countryFilter) {
      // if country filter have a specific value (!== "All")
      // and review country is not that value
      // review is discarded
      if (
        settings?.countryFilter !== "All" &&
        settings?.countryFilter !== country
      ) {
        isFilterApproved = false;
      }
    }

    // if the review's rating is lesser than the filter value
    // the review is discarded
    if (settings.ratingFilter) {
      if (review?.rating < settings?.ratingFilter) {
        isFilterApproved = false;
      }
    }

    // if the date is invalid, current date is taken
    let date = moment(review?.date).format("YYYY-MM-DD HH:mm:ss");
    if (date === "Invalid date")
      date = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");

    if (isFilterApproved) {
      return processedReviews.push({
        productId: productId,
        displayName: review?.displayName,
        content: review?.content,
        rating: review?.rating,
        approved,
        country,
        date,
        email: review?.email,
        photos,
        source: ReviewTypes.IMPORT_CSV,
      });
    }
  });
  return processedReviews;
};

import React from "react";
import "./index.scss";
import { CheckCircleFilled } from "@ant-design/icons";

const Avatar = (props) => {
  const { templates, currentTheme, displayName } = props;

  const { Body } = templates[currentTheme];

  return (
    <div className="avatar" style={{ height: "fit-content" }}>
      {Body?.custAvatarDisplay === "image" ? (
        <img
          alt="avatar"
          src={`https://avatars.dicebear.com/api/bottts/${displayName}.svg`}
          style={{
            borderRadius: `${Body?.custAvatar === "round" ? "50%" : "0px"}`,
          }}
        ></img>
      ) : (
        <div
          className="avatarLetter"
          style={{
            backgroundColor: Body?.reviewBg,
            borderRadius: `${Body?.custAvatar === "round" ? "50%" : "0px"}`,
          }}
        >
          {displayName[0]}
        </div>
      )}

      {Body?.verified && (
        <CheckCircleFilled
          style={{
            color: Body?.verifiedColor,
          }}
        />
      )}
    </div>
  );
};

export default Avatar;

import { message } from "antd";
import logError from "../../../../common/utils/logError";

import {
  getPopupSettings,
  setPopupSettings,
} from "../../../../infrastructure/store";
import { togglePopup } from "../../../../infrastructure/widgets";
import initialState from "./initialState";
import { popupThemes } from "../../../../common/constants";

const actions = {
  setLoading:
    (loading) =>
    ({ setState }) => {
      setState({ loading });
    },

  alterState:
    (key, value) =>
    ({ setState, getState }) => {
      const { changedSettings } = getState();
      setState({
        [key]: value,
        changedSettings: { ...changedSettings, [key]: value },
      });
    },

  getTheme:
    () =>
    ({ getState }) => {
      let themeName = "Custom Theme";
      Object.keys(popupThemes).forEach((theme) => {
        if (
          Object.keys(popupThemes[theme].styles).every(
            (style) => getState()[style] === popupThemes[theme]?.styles[style]
          )
        )
          themeName = theme;
      });
      return themeName;
    },

  applyTheme:
    (theme) =>
    ({ getState, setState }) => {
      const { changedSettings } = getState();
      setState({
        ...getState(),
        ...popupThemes[theme]?.styles,
        changedSettings: { ...changedSettings, ...popupThemes[theme]?.styles },
      });
    },

  loadPopupSettings:
    (shopName) =>
    async ({ setState, dispatch }) => {
      try {
        const settingsData = await getPopupSettings(shopName);
        if (settingsData.exists()) {
          // replaces the initialState with custom values
          setState({
            ...initialState,
            ...settingsData.val(),
            changedSettings: { ...settingsData.val() },
          });
        }
      } catch (err) {
        logError(err, "error in gettings popup settings");
      } finally {
        dispatch(actions.setLoading(false));
      }
    },

  savePopupSettings:
    (shopName, isThemeApp) =>
    async ({ getState, setState }) => {
      setState({ isSaving: true });
      const { changedSettings, popupEnabled } = getState();
      try {
        await setPopupSettings(shopName, { ...changedSettings, popupEnabled });
        await togglePopup(
          `${shopName}.myshopify.com`,
          "popup",
          popupEnabled,
          isThemeApp
        );
        message.success("Settings saved.");
      } catch (err) {
        logError(err, "Error in saving popup settings");
      } finally {
        setState({ isSaving: false });
      }
    },

  resetPopupSettings:
    () =>
    ({ getState, setState, dispatch }) => {
      const { popupEnabled } = getState();
      setState({ ...initialState, popupEnabled });
      dispatch(actions.setLoading(false));
    },
};

export default actions;

import "./index.scss";
import React, { useContext, useEffect, useState } from "react";
import {
  MinimalDynamicHeart,
  MinimalDynamicStar,
  MinimalGridHeart,
  MinimalGridStar,
  MinimalListHeart,
  MinimalListStar,
  MinimalZedgeHeart,
  MinimalZedgeStar,
  StandardDynamicHeart,
  StandardDynamicStar,
  StandardGridHeart,
  StandardGridStar,
  StandardListHeart,
  StandardListStar,
  StandardZedgeHeart,
  StandardZedgeStar,
} from "../../../common/Assets/Images";
import ObBodyLayout from "../ObBodyLayout";
import InputTitle from "./common/InputTitle";
import { Col, Row, Select } from "antd";
import { SETTINGS } from "../../../Settings/Theme/components/ReviewThemeSettings/constants";
import { SketchPicker } from "react-color";
import SelectBlock from "./common/SelectBlock";
import { FeatherIcon } from "../../../common/Assets/Icons";
import { useReviewWidget } from "../../../Widgets/DisplayReviews/ReviewContainer/store";
import { useAuthData } from "../../../Auth/store";
import { MixpanelEvents } from "../../../../common/constants/analytics/events";
import { AnalyticsContext } from "../../../common/Context/AnalyticsContext";

const CustomizeOB = ({ slider }) => {
  const [loading, setLoading] = useState(false);
  const [
    { currentTheme, templates },
    { setDemoData, getSettings, manipulateData, saveStoreTheme },
  ] = useReviewWidget();
  const [{ shopName }] = useAuthData();
  const { track } = useContext(AnalyticsContext);
  const styles = { minWidth: "250px" };

  const { icon, iconColor } = templates[currentTheme]["General"];
  const { headerLayout } = templates[currentTheme]["Header"];
  const { listLayout } = templates[currentTheme]["Body"];

  const props = { icon, iconColor, headerLayout, listLayout };

  const previewImages = {
    minimal_dynamic_heart: <MinimalDynamicHeart {...props} />,
    minimal_dynamic_star: <MinimalDynamicStar {...props} />,
    minimal_grid_heart: <MinimalGridHeart {...props} />,
    minimal_grid_star: <MinimalGridStar {...props} />,
    minimal_list_heart: <MinimalListHeart {...props} />,
    minimal_list_star: <MinimalListStar {...props} />,
    minimal_zedge_heart: <MinimalZedgeHeart {...props} />,
    minimal_zedge_star: <MinimalZedgeStar {...props} />,
    standard_dynamic_heart: <StandardDynamicHeart {...props} />,
    standard_dynamic_star: <StandardDynamicStar {...props} />,
    standard_grid_heart: <StandardGridHeart {...props} />,
    standard_grid_star: <StandardGridStar {...props} />,
    standard_list_heart: <StandardListHeart {...props} />,
    standard_list_star: <StandardListStar {...props} />,
    standard_zedge_heart: <StandardZedgeHeart {...props} />,
    standard_zedge_star: <StandardZedgeStar {...props} />,
  };

  const updateGeneralElement = (value, key) => {
    track(MixpanelEvents.ONBOARDING.CHANGE_THEME.GENERAL, {
      step: 1,
      key,
      value,
    });
    return manipulateData(currentTheme, "General", key, value);
  };

  const updateBodyElement = (value, key) => {
    track(MixpanelEvents.ONBOARDING.CHANGE_THEME.BODY, {
      step: 1,
      key,
      value,
    });
    return manipulateData(currentTheme, "Body", key, value);
  };

  const updateHeaderElement = (value, key) => {
    track(MixpanelEvents.ONBOARDING.CHANGE_THEME.HEADER, {
      step: 1,
      key,
      value,
    });
    return manipulateData(currentTheme, "Header", key, value);
  };

  useEffect(() => {
    setDemoData();
    getSettings({ shopName });
    return () => {
      setDemoData(true);
    };
  }, [getSettings, shopName, setDemoData]);

  return (
    <ObBodyLayout
      image={previewImages[`${headerLayout}_${listLayout}_${icon}`]}
      title="Customize Review Widgets"
      nextBtnText="Save & Continue"
      nextBtnLoading={loading}
      nextBtnAction={async () => {
        setLoading(true);
        await saveStoreTheme(shopName);
        track(MixpanelEvents.ONBOARDING.FEATURE_SELECT, {
          step: 2,
        });
        slider?.current?.next();
        setLoading(false);
      }}
    >
      <div className="obCustomize">
        <Row gutter={25}>
          <Col xs={24} lg={12}>
            <InputTitle title="Header Layout" />
            <Select
              style={styles}
              placeholder="Select Header Layout"
              size="large"
              defaultValue={headerLayout}
              onChange={(value) => updateHeaderElement(value, "headerLayout")}
            >
              {SETTINGS.THEME.header.header_layouts.map((theme) => (
                <Select.Option key={theme.value}>{theme.key}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} lg={12}>
            <InputTitle title="Body Layout" />
            <Select
              style={styles}
              placeholder="Select Body Layout"
              size="large"
              defaultValue={listLayout}
              onChange={(value) => updateBodyElement(value, "listLayout")}
            >
              {SETTINGS.THEME.body.list_layouts.map((theme) => (
                <Select.Option key={theme.value}>{theme.key}</Select.Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row style={{ margin: "30px 0" }}>
          <Col xs={24} lg={12}>
            <InputTitle title="Rating Color" />
            <SketchPicker
              disableAlpha={true}
              color={iconColor}
              onChange={({ hex }) => {
                updateGeneralElement(hex, "iconColor");
              }}
            />
          </Col>
          <Col xs={24} lg={12}>
            <Col>
              <InputTitle title="Rating Icon" />
              <Row>
                {/* <Select.Option key={theme.value}>{theme.key}</Select.Option> */}
                {SETTINGS.THEME.general.ratingIcons.map((theme) => (
                  <SelectBlock
                    isSelected={icon === theme.value}
                    value={theme.value}
                    label={<FeatherIcon icon={theme.value} />}
                    onClick={(value) => updateGeneralElement(value, "icon")}
                  />
                ))}
              </Row>
            </Col>
          </Col>
        </Row>
      </div>
    </ObBodyLayout>
  );
};

export default CustomizeOB;

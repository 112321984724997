import React, { useEffect } from "react";
import config from "../../../common/config";

const WhatsNew = () => {
  useEffect(() => {
    if (window.Olvy) {
      window.Olvy.init({
        organisation: config.OLVY_ID,
        target: "#olvy-target",
        type: "modal",
        view: {
          showSearch: false,
          compact: false,
          showHeader: true,
          showUnreadIndicator: true,
          unreadIndicatorColor: "#cc1919",
          unreadIndicatorPosition: "top-right",
        },
      });
    }
    return function cleanup() {
      if (window.Olvy) {
        window.Olvy.teardown();
      }
    };
  });
  return <p style={{ margin: 0 }}>What's New</p>;
};

export default WhatsNew;

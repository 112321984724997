import React from "react";
import "./index.scss";
import { HeartFilled, StarFilled } from "@ant-design/icons";
import { Rate, Row } from "antd";

const AverageBar = (props) => {
  const { templates, currentTheme, reviews } = props;
  const { General } = templates[currentTheme];

  return (
    <Row align="middle" className="averageBar">
      <Rate
        style={{ color: General?.iconColor, fontSize: 24 }}
        defaultValue={
          reviews?.map((review) => review?.rating)?.reduce((a, b) => (a + b),0) /
          reviews?.length
        }
        disabled
        character={General?.icon === "heart" ? <HeartFilled /> : <StarFilled />}
      />
      <p>({reviews?.length} Reviews)</p>
    </Row>
  );
};

export default AverageBar;

import { createStore, createHook } from "react-sweet-state";

import actions from "./actions";
import initialState from "./initialState";

const Store = createStore({
  name: "AIREVIEWS-review-widget-" + window.location.origin,
  initialState,
  actions,
});

export const useReviewWidget = createHook(Store, {
  selector: (state) => state,
});

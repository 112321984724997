import "./index.scss";
import React from "react";
import { CameraFilled } from "@ant-design/icons";
import { ProductPlaceholder } from "../../../../../../../../common/Assets/Images";
import { Image } from "antd";

const ReviewImages = (props) => {
  const { photos, templates, currentTheme, displayAll = false } = props;
  // const [visible, setVisible] = useState(false);
  const { Body } = templates[currentTheme];

  const onError = (e) => {
    e.target.onerror = null;
    e.target.src = ProductPlaceholder;
  };

  if (!photos || !photos.length) return null;
  return (
    <figure
      className={`reviewCardFigure reviewCardFigure-${
        displayAll ? "multiple" : "single"
      }`}
    >
      {!Body?.reviewPic ? (
        <img src={ProductPlaceholder} alt="review img" />
      ) : (
        <Image.PreviewGroup>
          {photos?.map((photo, i) => (
            <Image
              key={i}
              src={photo}
              style={{ display: i === 0 || displayAll ? "inherit" : "none" }}
              onError={onError}
              alt="review-img"
            />
          ))}
        </Image.PreviewGroup>
      )}
      {!displayAll && (
        <figcaption>
          {photos.length} <CameraFilled />
        </figcaption>
      )}
    </figure>
  );
};

export default ReviewImages;

/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import "./index.scss";
import { PrimaryLoadingIndicator } from "../../../common/UI/LoadingIndicator/PrimaryLoadingIndicator";
import { useReviewWidget } from "./store";
import { triggerPostMessage } from "./helpers/helper";

import { useAuthStore } from "../../../common/Stores";
import WriteReviewForm from "./components/WriteReviewForm";
import AverageRating from "./components/AverageRating";
import AverageStars from "./components/AverageStars";
import ReviewList from "./components/ReviewList";
import WriteReviewButton from "./components/WriteReviewButton";
import AverageBar from "./components/AverageBar";
import { Row, Select } from "antd";
import { sorters, sortReviews } from "../../../common/Utils/ReviewActions";

export default ({ reviewPopup }) => {
  const [{ shopName }] = useAuthStore();
  const [widgetState, widgetActions] = useReviewWidget();
  const [sorter, setSorter] = useState(null);

  const {
    loader,
    reviews,
    storeFrontData,
    currentTheme,
    widgetActive,
    templates,
    writeReview,
    brandFlag,
  } = widgetState;

  const { setWriteReview } = widgetActions;

  const childProps = {
    templates,
    currentTheme,
    reviews: JSON.parse(JSON.stringify(sortReviews(reviews, sorter))),
  };

  const styles = {
    marginRight: 10,
    minWidth: "120px",
  };
  const clear = (val) => (val === "x" ? null : val);

  useEffect(() => {
    widgetActions.getDbTheme(shopName);
    widgetActions.getBrandFlagStatus({ shopName });
  }, [widgetActions, shopName]);

  useEffect(() => {
    if (!loader) {
      //used to adjust height of the container using post message communication
      triggerPostMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader]);

  if (loader) {
    return <PrimaryLoadingIndicator />;
  }

  //check active
  if (!widgetActive && storeFrontData) {
    return null;
  }

  const { General, Header, Body } = templates[currentTheme];

  return (
    <div
      className="reviewCardMain"
      style={{ backgroundColor: General?.reviewsBg }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "15px 0",
        }}
      >
        <h2 style={{ color: General?.textPrimary, margin: 0 }}>
          {Header?.title}
        </h2>
        {brandFlag && (
          <p
            style={{
              color: General?.textPrimary,
              opacity: 0.8,
              margin: 0,
              paddingRight: 16.4,
            }}
          >
            Powered by{" "}
            <a
              style={{ color: General?.textPrimary }}
              href="https://revyu.xyz"
              target="__blank"
            >
              Revyu
            </a>
          </p>
        )}
      </div>
      {Body?.minimalistView && !reviews?.length ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <h4>Be the first one to leave a review</h4>
          {(Header?.writeReviewBtnVisibility || reviewPopup) && (
            <WriteReviewButton
              {...childProps}
              {...{ writeReview, setWriteReview }}
            />
          )}
        </div>
      ) : (
        <div
          className={`average-container average-container-${Header?.headerLayout}`}
        >
          {Header?.headerLayout === "standard" ? (
            <div className="average-content">
              <AverageRating {...childProps} />
              <AverageStars {...childProps} />
            </div>
          ) : (
            <div className="average-content">
              <AverageBar {...childProps} />
            </div>
          )}
          {(Header?.writeReviewBtnVisibility || reviewPopup) && (
            <WriteReviewButton
              {...childProps}
              {...{ writeReview, setWriteReview }}
            />
          )}
        </div>
      )}

      {writeReview && (
        <WriteReviewForm
          {...childProps}
          {...{ writeReview, setWriteReview, storeFrontData, reviews }}
        />
      )}

      <div className="cardsContainer" style={{ flexDirection: "column" }}>
        {reviews?.length !== 0 && (
          <Row justify="end">
            {" "}
            <Select
              value={sorter}
              placeholder="Sort by"
              style={styles}
              onChange={(val) => setSorter(clear(val))}
            >
              <Select.Option value="x">Clear</Select.Option>
              {Object.values(sorters).map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Row>
        )}
        <ReviewList {...childProps} {...{ storeFrontData }} />
      </div>
    </div>
  );
};

import React from "react";

import { Divider, Input, Row, Select } from "antd";
import { LogoIconWhite } from "../../../../../common/Assets/Logo";
import { useReviewFormStore } from "../../../../../common/Stores";
import { Button } from "../../../../../common/UI/buttons/buttons";
import { KeyWords } from "./constants";

const FeedbackStep = ({ productDetails, storeFrontData, setUsedKeyGen }) => {
  const [reviewFormData, reviewFormActions] = useReviewFormStore();

  let children = KeyWords[reviewFormData?.rating || 5].map((doc) => (
    <Select.Option key={doc}>{doc}</Select.Option>
  ));

  // const permittedBillings = ["SMART", "LEGEND"];

  return (
    <>
      {reviewFormData.keywordGenEnabled && reviewFormData.credits >= 64 && (
        <>
          <Divider orientation="left" type="horizontal">
            Generate Review based on keywords
          </Divider>
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Enter your keywords / Select from the existing ones"
            onChange={(value) => reviewFormActions.onDataChange("tags", value)}
          >
            {children}
          </Select>
          <Row justify="end">
            <Button
              type="info"
              style={{ marginTop: 20, height: 48 }}
              loading={reviewFormData.creatingReview}
              onClick={() => {
                setUsedKeyGen(true);
                reviewFormActions.generateReviewBasedOnKeywords(
                  productDetails,
                  storeFrontData.shopName
                );
              }}
            >
              {!reviewFormData.creatingReview && (
                <img src={LogoIconWhite} alt="" />
              )}
              Generate Review
            </Button>
          </Row>
          <Divider type="horizontal">Or</Divider>
        </>
      )}

      <Input.TextArea
        className="customInput"
        autoSize={{ minRows: 4, maxRows: 4 }}
        placeholder="Enter your feedback here"
        value={reviewFormData.content}
        onChange={({ target }) => {
          reviewFormActions.onDataChange("content", target.value);
        }}
      />
    </>
  );
};

export default FeedbackStep;

import { post } from "../common/remote";

export const sendReviewReplyMail = (shopData) => {
  return post("/mails/reviewReply", shopData);
};

export const sendNewReviewMail = (shopData) => {
  return post("/mails/newReview", shopData);
};

export const sendReviewRequestMail = (params) => {
  return post("/mails/reviewRequest", params);
};

export const sendCouponMail = (params) => {
  return post("/mails/couponCode", params);
};

export const sendDiscordNotification = (channel, message) => {
  return post("/send-notification", { channel, message });
};

import { Modal, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "./index.scss";
import { readReviews } from "../../../../../../infrastructure/reviews";
import { logError } from "../../../../../../common/utils";
import { useReviewWidget } from "../../store";
import { useReviewFormStore } from "../../../../../common/Stores";
import { saveReview } from "./actions";
import { Button } from "../../../../../common/UI/buttons/buttons";
import { useReviewData } from "../../../../../Reviews/store";
import RatingStep from "../common/RatingStep";
import DetailsStep from "../common/DetailsStep";
import FeedbackStep from "../common/FeedbackStep";
import ImageStep from "../common/ImageStep";
import SubmitReviewButton from "../common/SubmitReviewButton";
import { isEmpty } from "../../../../../common/Stores/ReviewForm/helpers";
import { validateEmail } from "../../../../../common/Utils/validators";
import { ReviewTypes } from "../../../../../../common/constants/reviewTypes";

const WriteReviewForm = (props) => {
  const {
    templates,
    currentTheme,
    writeReview,
    setWriteReview,
    storeFrontData,
  } = props;

  const { Header, General } = templates[currentTheme];
  const [, widgetActions] = useReviewWidget();
  const [reviewFormData, reviewFormActions] = useReviewFormStore();
  const [{ productDetails }] = useReviewData();
  const [loading, setLoading] = useState(false);
  const [previewOn, setpreviewOn] = useState(false);
  const [previewUrl, setpreviewUrl] = useState("");
  const [step, setStep] = useState(1);
  const [finished, setFinished] = useState(false);
  const [usedKeyGen, setUsedKeyGen] = useState(false);

  const noOfSteps = 4;

  const getStep = (step) => {
    switch (+step) {
      case 1:
        return (
          <RatingStep
            {...{ Header, General, reviewFormData, reviewFormActions }}
          />
        );
      case 2:
        return <DetailsStep {...{ reviewFormActions, reviewFormData }} />;
      case 3:
        return (
          <FeedbackStep
            {...{ productDetails, storeFrontData, setUsedKeyGen }}
          />
        );
      case 4:
        return (
          <ImageStep
            {...{
              reviewFormData,
              previewOn,
              previewUrl,
              setpreviewOn,
              reviewFormActions,
              setpreviewUrl,
            }}
          />
        );
      case 5:
        return (
          <SubmitReviewButton
            {...{
              loading,
              reviewFormData,
              Header,
              storeFrontData,
              handleSubmit,
              setWriteReview,
            }}
          />
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    reviewFormActions.getCountry();
    storeFrontData &&
      reviewFormActions.setSettings(
        storeFrontData.shopName,
        storeFrontData.productId
      );
  }, [productDetails, reviewFormActions, storeFrontData]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // fetching new review data from reviewForm Store
      const newReview = await reviewFormActions.getReviewFormData(
        storeFrontData.shopName,
        storeFrontData.productId
      );
      // fetching previous reviews
      const prevReviews = await readReviews(
        storeFrontData.shopName,
        storeFrontData.productId
      );
      // saving review to DB
      await saveReview(
        {
          ...newReview,
          source: usedKeyGen ? ReviewTypes.USER_GEN : ReviewTypes.USER_WROTE,
        },
        prevReviews,
        storeFrontData,
        setLoading,
        widgetActions
      );
      // closing modal
      setWriteReview(false);
      // resetting review form
      reviewFormActions.resetState();
    } catch (error) {
      message.error(error.toString());
      logError(error, "handleSubmit()");
    } finally {
      setLoading(false);
    }
  };

  const next = () => {
    if (step === 1 && reviewFormData.rating === 0)
      message.error("Please provide a rating");
    else if (step === 2 && isEmpty(reviewFormData.email))
      message.error("Please provide your email");
    else if (step === 2 && !validateEmail(reviewFormData.email))
      message.error("Please provide a valid email");
    else if (step === 3 && isEmpty(reviewFormData.content))
      message.error("Please provide a feedback");
    else step + 1 === noOfSteps + 1 ? setFinished(true) : setStep(step + 1);
  };

  const prev = () => {
    step - 1 === 0 ? setStep(1) : setStep(step - 1);
    setFinished(false);
  };

  const buttonStyles = {
    backgroundColor: Header?.writeReviewBtnC,
    borderRadius: Header?.writeReviewBtnS === "round" ? "50px" : "10px",
    color: Header?.writeReviewBtnTextC,
  };

  return (
    <Modal
      open={writeReview}
      footer={
        <div className="footer">
          <div style={{ visibility: step === 1 ? "hidden" : "visible" }}>
            <Button
              type="light"
              style={{ ...buttonStyles }}
              disabled={step === 1}
              onClick={prev}
            >
              Previous
            </Button>
          </div>
          {finished ? (
            <Button
              type="success"
              loading={loading}
              onClick={
                storeFrontData
                  ? () => handleSubmit()
                  : () => setWriteReview(false)
              }
            >
              Submit Review
            </Button>
          ) : (
            <Button type="primary" style={{ ...buttonStyles }} onClick={next}>
              Next
            </Button>
          )}
        </div>
      }
      onCancel={() => {
        setWriteReview(false);
        reviewFormActions.resetState();
      }}
      maskStyle={{ display: "none" }}
      title={
        <h2 style={{ color: "black", marginBottom: 0 }}>Write a Review</h2>
      }
      bodyStyle={{
        minHeight: 400,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="reviewContMain">
        {reviewFormData?.credits === null &&
        reviewFormData?.keywordGenEnabled === null &&
        storeFrontData ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          getStep(step)
        )}
      </div>
    </Modal>
  );
};

export default WriteReviewForm;

import { Carousel, message } from "antd";
import React, { createRef, useContext, useEffect } from "react";
import { MixpanelEvents } from "../../common/constants/analytics/events";
import { AnalyticsContext } from "../common/Context/AnalyticsContext";
import { useAuthStore } from "../common/Stores";
import { OnboardingLayout } from "../Layouts";
import { useReviewWidget } from "../Widgets/DisplayReviews/ReviewContainer/store";
import CustomizeOB from "./common/Customize";
import DoNowOB from "./common/DoNow";
import WelcomeOB from "./common/Welcome";
import "./index.scss";

const Onboarding = () => {
  const slider = createRef();
  const [, widgetActions] = useReviewWidget();
  const [{ isActive, shopName, isThemeApp, shopData }, { completeIntro }] =
    useAuthStore();
  const { track } = useContext(AnalyticsContext);

  useEffect(() => {
    track(MixpanelEvents.ONBOARDING.START, {
      step: 0,
    });
  }, [isThemeApp, shopData, shopName, track]);

  const onDone = async () => {
    if (isActive) {
      await completeIntro();
      message.success("Onboarding complete");
    } else {
      message.config({ maxCount: 1 });
      message.info("Enable the App to complete setup !");
    }
  };

  useEffect(() => {
    widgetActions.getDbTheme(shopName);
  }, [shopName, widgetActions]);

  return (
    <OnboardingLayout>
      <Carousel slickGoTo={2} ref={slider}>
        <div>
          <WelcomeOB slider={slider} />
        </div>
        <div>
          <CustomizeOB slider={slider} />
        </div>
        <div>
          <DoNowOB slider={slider} onDone={onDone} />
        </div>
      </Carousel>
    </OnboardingLayout>
  );
};

export default Onboarding;

import { get, post, put } from "../common/remote/base_api";
import { primaryDB } from "../common/firebase";

export const writeReviews = (shop, product_id, oldReviews, newReviews = []) => {
  const reviews = [...newReviews, ...oldReviews];
  return put("/metaReviews", { shop, product_id, reviews });
};

export const readReviews = (shop, product_id) => {
  return get("/metaReviews", { shop, product_id });
};

export const updateReviewById = (shop, product_id, review_id, review) => {
  return put("/metaReviewsById", { shop, product_id, review_id, review });
};

export const extractReviews = (params) => {
  return get("/scrapeReviews", params);
};

export const saveReviewSettings = (data, shopName) => {
  return post(`/settings/review`, { shopName, data });
  // return primaryDB.ref(`${shopName}/storeSettings/review`).set(payload);
};
export const getReviewSettings = (shopName) => {
  return primaryDB.ref(`${shopName}/storeSettings/review`).once("value");
};

export const saveAiSettings = (payload, shopName) => {
  return primaryDB.ref(`000_Settings/${shopName}/ai`).set(payload);
};

export const getReviewAutoPublishSettings = (shopName) => {
  return primaryDB
    .ref(`${shopName}/storeSettings/review/autoPublish`)
    .once("value");
};

export const getReviewFilterSettings = (shopName) => {
  return primaryDB.ref(`${shopName}/storeSettings/review/filter`).once("value");
};

export const getReviewAutoGenSettings = (shopName) => {
  return primaryDB
    .ref(`${shopName}/storeSettings/review/autoGen`)
    .once("value");
};

export const getReviewKeyGenSettings = (shopName) => {
  return primaryDB
    .ref(`${shopName}/storeSettings/review/ai/keyGen`)
    .once("value");
};

export const saveSingleCSV = (body) => {
  return post("/saveSingleCSV", body);
};

export const saveMultipleCSV = (body) => {
  return post("/saveMultipleCSV", body);
};

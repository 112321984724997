import { SMART } from "./SMART";

export const PG_SMART_A = {
  name: "PG Smart Plan A",
  price: 49.0,
  recurring: true,
  counters: {
    credits: 15000,
    reqEmails: 2000,
  },
  permissions: {
    ...SMART.permissions,
  },
  features: [],
};

export const PG_SMART_B = {
  name: "PG Smart Plan B",
  price: 97.0,
  counters: {
    credits: 30000,
    reqEmails: 4000,
  },
  permissions: {
    ...SMART.permissions,
  },
  features: [],
};

export const PG_SMART_C = {
  name: "PG Smart Plan C",
  price: 149.0,
  counters: {
    credits: 100000,
    reqEmails: 10000,
  },
  permissions: {
    ...SMART.permissions,
  },
  features: [],
};

import { Button } from "antd";
import React from "react";

const WriteReviewButton = (props) => {
  const { templates, currentTheme, setWriteReview, writeReview } = props;

  const { Body, Header } = templates[currentTheme];

  return (
    <>
      {!Body?.viewForm && (
        <Button
          // loading={credits === null}
          className="writeBtn"
          onClick={() => {
            setWriteReview(!writeReview);
          }}
          style={{
            backgroundColor: Header?.writeReviewBtnC,
            color: Header?.writeReviewBtnTextC,
            borderRadius: `${
              Header?.writeReviewBtnS === "round" ? "100px" : "4px"
            }`,
          }}
        >
          {Header?.writeReviewBtnT}
        </Button>
      )}
    </>
  );
};

export default WriteReviewButton;

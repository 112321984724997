import "./index.scss";

import { Popover } from "antd";
import { useAuthData } from "../../../Auth/store";
import { HomeIntroSteps } from "../constants/steps";

const IntroHighlight = ({ children, step }) => {
  const [{ introStep, isActive }] = useAuthData();

  return (
    <Popover
      open={
        introStep === step - 1 &&
        HomeIntroSteps[step - 1].popinfo !== undefined &&
        !(introStep === 4 && isActive)
      }
      overlayClassName="overlay-override"
      placement="bottom"
      content={HomeIntroSteps[step - 1]?.popinfo}
    >
      {children}
    </Popover>
  );
};

export default IntroHighlight;

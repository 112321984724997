import newFilter from "bad-words";

export const badWordsFilter = (text) => {
  const Filter = new newFilter();
  return Filter.clean(text);
};

export const includeFilter = (record, value) => {
  console.log(record, value);
  return String(record).toLowerCase().includes(String(value).toLowerCase());
};

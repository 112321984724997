const initialState = {
  popupEnabled: false,
  position: "bottomLeft",
  popupLayout: "row",
  backgroundColor: "#5F63F2",
  ratingColor: "#fadb2b",
  primaryColor: "#ffffff",
  secondaryColor: "#ffffff",
  pubDate: true,
  hideInMobile: true,
  changedSettings: {},
  loading: true,
  isSaving: false,
};

export default initialState;

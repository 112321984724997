import React from "react";
import "./index.scss";
import Author from "../Author";

export const ReviewContent = (props) => {
  const { templates, currentTheme, content } = props;
  const { General, Body } = templates[currentTheme];
  return (
    <div
      className="review-text"
      style={{
        color: General?.textPrimary,
        textAlign: Body?.alignment,
      }}
    >
      {content}
    </div>
  );
};

const CardBody = (props) => {
  const { onClick } = props;
  return (
    <div className="card-body" onClick={onClick}>
      <div className="desc">
        <Author {...props} />
        <ReviewContent {...props} />
      </div>
    </div>
  );
};

export default CardBody;

import "./index.scss";
import React from "react";
import FeatherIcon from "feather-icons-react";
import planFeatures from "./planFeatures";

const PlanListItems = ({ plan }) => {
  return (
    <div className="planListItems">
      {planFeatures[plan]?.map((item, key) => (
        <div className="planListItem" key={key}>
          <FeatherIcon icon="check" size={14} />
          <p>{item}</p>
        </div>
      ))}
    </div>
  );
};

export default PlanListItems;

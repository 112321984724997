import config from "../../../../common/config";
import { routes } from "../../../common/Routes";

const scopes =
  "read_themes,write_themes,read_products,write_products,read_script_tags,write_script_tags,read_orders,write_orders,write_price_rules,read_price_rules";

const redirectToShopifyInstallation = (shopName, param = "") => {
  const redirectUri = `${window.location.origin}${routes.INSTALLATION_CALLBACK}${param}`;
  const apiKey = config.SHOPIFY_API_KEY;
  const installUri = `https://${shopName}.myshopify.com/admin/oauth/authorize?client_id=${apiKey}&scope=${scopes}&state=${Date.now()}&redirect_uri=${redirectUri}`;

  window.location.href = installUri;
};

export default redirectToShopifyInstallation;

/*
  There are 3 environments setup in this project:
  1. development (yarn start or yarn build:dev)
  2. staging (yarn build:stage)
  3. production (yarn build)
*/

import { getEnv } from "../utils";

const ENV = getEnv();

const development = {
  // START: LOCAL CONFIG
  ENV: ENV,
  BASE_API: "https://dev-api.revyu.xyz",
  // END: LOCAL CONFIG

  // START: INTEGRATIONS
  INT_GENERATE_URL: "http://dev.neoapps.xyz/",
  // END: INTEGRATIONS

  // START: OLVY CONFIG
  OLVY_ID: "revyu-dev",
  // END: OLVY CONFIG

  // START: SHOPIFY CONFIG
  SHOPIFY_API_KEY: "3c6b938c68c35cd8d8a53a79d85ac915",
  // END: SHOPIFY CONFIG

  // START: BUGSNAG CONFIG
  BUGSNAG_API_KEY: "84f6bd2485527d741c9a7104b6153750",
  // END: BUGSNAG CONFIG

  // START: LOGROCKET CONFIG
  LOGROCKET_APP_ID: "lgdcou/aireviews",
  LOGROCKET_DASH_ID: "lgdcou/revyu_dashboard",
  LOGROCKET_WIDGET_ID: "lgdcou/revyu_widgets",
  // END: LOGROCKET CONFIG

  // MIXPANEL KEY
  MIXPANEL_KEY: "cdeefd2aa0a6b3271dc749bfa63811a3",

  // START: FIREBASE CONFIG
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyD9sSK-ZF9vjGIlLRPhMXKviMVsvLntKLg",
    authDomain: "ali-express-ed89f.firebaseapp.com",
    projectId: "ali-express-ed89f",
    storageBucket: "ali-express-ed89f.appspot.com",
    messagingSenderId: "855009012522",
    appId: "1:855009012522:web:397c2ee8d3d99c5a1e4c53",
    measurementId: "G-CLKRCBS845",
  },
  PRIMARY_DB_URL: "https://ali-express-ed89f.firebaseio.com",
  SECONDARY_DB_URL: "https://ali-express-ed89f-7193e.firebaseio.com",
  // END: FIREBASE CONFIG

  // START: STRIPE CONFIG
  STRIPE_API_KEY:
    "pk_test_51JiGSuSGBjUx8EheP1fEBNAWzdBP0rKSxXqe4y2jlIwJ7tKZmnCosa49NuiWn54s4jV1I73oQ3nwWpxh5GAbeUMm003fLS6eNZ",
  STRIPE_SECRET_KEY:
    "sk_test_51JiGSuSGBjUx8Ehe9zDK77Z0weUS2JMieeajUCLpAx8ekWhehrRJVIPfEvqOWwTayvZgo5IdwAbEcPSv5SoRrelY00k2709mgp",
  // END: STRIPE CONFIG

  CURRENCY_CONVERTER_API_KEY: "f779d9fd506153e1461b",
};

const production = {
  ...development,
  // CONFIG CHANGES FOR PRODUCTION (if required) SHOULD BE HERE (↓)
  BASE_API: "https://api.revyu.xyz",

  // END: LOCAL CONFIG

  // START: INTEGRATIONS
  INT_GENERATE_URL: "http://app.neoapps.xyz/",
  // END: INTEGRATIONS

  // SHOPIFY CONFIGS
  SHOPIFY_API_KEY: "c78d1fd2a82c74c1a3a851f77237638d",

  // START: LOGROCKET CONFIG
  LOGROCKET_APP_ID: "7bzpbb/revyu",
  LOGROCKET_DASH_ID: "revyu_prod_dashbord/dashboard-mi8im",
  LOGROCKET_WIDGET_ID: "revyu_prod_widgets/widgets",
  // END: LOGROCKET CONFIG

  // START: OLVY CONFIG
  OLVY_ID: "revyu",
  // END: OLVY CONFIG

  // START: BUGSNAG CONFIG
  BUGSNAG_API_KEY: "ba2773d82b1d9a3d390aec8aa8d27113",
  // END: BUGSNAG CONFIG

  // FIREBASE CONFIG
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyAW9TpZOK5vIxxi8kNkqI6HB5jE25f9E5k",
    authDomain: "airreviewsprod-35082.firebaseapp.com",
    projectId: "airreviewsprod-35082",
    storageBucket: "airreviewsprod-35082.appspot.com",
    messagingSenderId: "556045465310",
    appId: "1:556045465310:web:6c1b2cff2620b7b88eb44f",
    measurementId: "G-G36PZYZN46",
  },
  PRIMARY_DB_URL: "https://airreviewsprod-35082-default-rtdb.firebaseio.com",
  SECONDARY_DB_URL: "https://airreviewsprod-35082.firebaseio.com",
  // END: FIREBASE CONFIG

  // START: STRIPE CONFIG
  STRIPE_API_KEY:
    "pk_live_51JiGSuSGBjUx8EhewCMlSUBsgvSohkVhXUYGS49aqiiMsujwm4R6ZfbT4m54MKyUg0o3Q9a3rFUasHsbfRAdeNTP00gr3KxMEF",
  STRIPE_SECRET_KEY:
    "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",
  // END: STRIPE CONFIG
  //has to be filled with new config
};

const staging = {
  ...development,
  LOGROCKET_APP_ID: "067544/aireviews",

  // START: INTEGRATIONS
  INT_GENERATE_URL: "http://staging.neoapps.xyz/",
  // END: INTEGRATIONS

  // CONFIG CHANGES FOR STAGING (if required) SHOULD BE HERE (↓)
  BASE_API: "https://staging-api.revyu.xyz",
  // BASE_API: "http://localhost:5000",
  SHOPIFY_API_KEY: "5da4cddc0e8d5482cbff06d2008a76fe",

  // START: STRIPE CONFIG
  STRIPE_API_KEY:
    "pk_live_51JiGSuSGBjUx8EhewCMlSUBsgvSohkVhXUYGS49aqiiMsujwm4R6ZfbT4m54MKyUg0o3Q9a3rFUasHsbfRAdeNTP00gr3KxMEF",
  STRIPE_SECRET_KEY:
    "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",
  // END: STRIPE CONFIG
};

const local = {
  ...development,
  BASE_API: "http://localhost:5000",
  // CONFIG CHANGES FOR LOCAL (if required) SHOULD BE HERE (↓)
};

const configs = {
  production,
  staging,
  development,
  local,
};

const currentConfig = configs[ENV];
const config = {
  // START: LOCAL CONFIG
  ENV: ENV,
  BASE_API: currentConfig.BASE_API,
  // END: LOCAL CONFIG

  // START: INTEGRATIONS
  INT_GENERATE_URL: currentConfig.INT_GENERATE_URL,
  // END: INTEGRATIONS

  // START: SHOPIFY CONFIG
  SHOPIFY_API_KEY: currentConfig.SHOPIFY_API_KEY,
  // END: SHOPIFY CONFIG

  // START: OLVY CONFIG
  OLVY_ID: currentConfig.OLVY_ID,
  // END: OLVY CONFIG

  // START: BUGSNAG CONFIG
  BUGSNAG_API_KEY: currentConfig.BUGSNAG_API_KEY,
  // END: BUGSNAG CONFIG

  // START: LOGROCKET CONFIG
  LOGROCKET_APP_ID: currentConfig.LOGROCKET_APP_ID,
  LOGROCKET_DASH_ID: currentConfig.LOGROCKET_DASH_ID,
  LOGROCKET_WIDGET_ID: currentConfig.LOGROCKET_WIDGET_ID,
  // END: LOGROCKET CONFIG

  // START: FIREBASE CONFIG
  FIREBASE_CONFIG: currentConfig.FIREBASE_CONFIG,
  PRIMARY_DB_URL: currentConfig.PRIMARY_DB_URL,
  SECONDARY_DB_URL: currentConfig.SECONDARY_DB_URL,
  // END: FIREBASE CONFIG

  // START: STRIPE CONFIG
  STRIPE_API_KEY: currentConfig.STRIPE_API_KEY,
  STRIPE_SECRET_KEY: currentConfig.STRIPE_SECRET_KEY,
  // END: STRIPE CONFIG

  // MIXPANEL KEY
  MIXPANEL_KEY: currentConfig.MIXPANEL_KEY,

  CURRENCY_CONVERTER_API_KEY: currentConfig.CURRENCY_CONVERTER_API_KEY,
};

export default config;

import { Col, Popconfirm, Row, Tooltip } from "antd";
import { Link } from "react-router-dom";
import {
  AiSettings,
  // AliExpress,
  // CsvImport,
  Enable,
  Filter,
  Import,
  Publish,
  Theme,
  // Done,
} from "../../../common/Assets/Images";
import { Button } from "../../../common/UI/buttons/buttons";
import { routes } from "../../../common/Routes/routes";
import { applyFilterSettings } from "../../actions";
import {
  dropShippingFilters,
  dropShippingFiltersDesc,
  quickFilters,
  quickFiltersDesc,
} from "./settings";
import { Button as AntdButton } from "antd";
import { StyledList } from "../style";

// const avatarStyle = {
//   //   borderRadius: "1000px",
//   minHeight: "80px",
//   minWidth: "80px",
// };

const buttonStyle = {
  height: "44px",
  marginBottom: "10px",
  width: "156px",
};

// const avatarWrapperStyle = {
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// };

export const HomeIntroSteps = [
  {
    title: "Review Settings",
    name: "Add an import filter",
    img: Filter,
    desc: `Add an import filter into your settings to tweak how you could
        import reviews. Like for example, you could turn on the auto
        generation feature for names, to automatically generate and
        replace your reviews with names of people from the selected
        country.`,
    footer: (shopName, loading, setloading) => (
      <Row gutter={[8, 48]}>
        <Col>
          <Row>
            <p>Choose from our predefined options:</p>
          </Row>
          <Row gutter={16}>
            <Col>
              <Popconfirm
                title="Are you sure you want to apply the Quick filters ?"
                onConfirm={async () => {
                  setloading("quick");
                  await applyFilterSettings(shopName, quickFilters);
                  setloading("");
                }}
                okText="Apply"
              >
                <Tooltip
                  placement="bottom"
                  title={
                    <StyledList>
                      {quickFiltersDesc.map((desc, key) => (
                        <li key={key}>{desc}</li>
                      ))}
                    </StyledList>
                  }
                >
                  <AntdButton
                    type="default"
                    size="default"
                    loading={loading === "quick"}
                    style={{ ...buttonStyle }}
                  >
                    Quick Filters
                  </AntdButton>
                </Tooltip>
              </Popconfirm>
            </Col>
            <Col>
              <Popconfirm
                title="Are you sure you want to apply the Dropshipping filters ?"
                onConfirm={async () => {
                  setloading("dropshipping");
                  await applyFilterSettings(shopName, dropShippingFilters);
                  setloading("");
                }}
                okText="Apply"
              >
                <Tooltip
                  placement="bottom"
                  title={
                    <StyledList>
                      {dropShippingFiltersDesc.map((desc, key) => (
                        <li key={key}>{desc}</li>
                      ))}
                    </StyledList>
                  }
                >
                  <AntdButton
                    type="warning"
                    size="default"
                    style={{ ...buttonStyle }}
                    loading={loading === "dropshipping"}
                  >
                    Dropshipping Filters
                  </AntdButton>
                </Tooltip>
              </Popconfirm>
            </Col>
          </Row>
          <Row>
            <p>Or use custom options:</p>
          </Row>
          <Row>
            <Link to={routes.SETTINGS_GENERAL}>
              <AntdButton
                type="default"
                style={{ ...buttonStyle }}
                size="default"
              >
                Advanced
              </AntdButton>
            </Link>
          </Row>
        </Col>
      </Row>
    ),
  },
  {
    title: "Import Reviews",
    name: "Import reviews",
    img: Import,
    desc: `There are currently two options to import reviews to your store.
    You can either import from an aliexpress URL or your old reviews through a csv file.`,
    footer: () => (
      <a
        href="https://revyu-docs.notion.site/revyu-docs/Import-Manage-Reviews-b1a92411452e42e0bcb89c9dc542861a"
        target="_blank"
        rel="noreferrer"
      >
        Read more
      </a>
    ),
  },
  {
    title: "Publish Reviews",
    name: "Publish reviews",
    img: Publish,
    desc: `By default, the imported reviews will not be published. This is
    kept in that way so that you can assess the reviews before it
    goes in to your store. When you are done, select the reviews and
    then hit the publish all from actions menu to push them
    to your store.`,
    footer: () => <Link to={routes.REVIEWS_MANAGE}>Learn More</Link>,
  },
  {
    title: "Customize Theme",
    name: "Customize theme",
    img: Theme,
    desc: `Change theme settings to suit your website. You can either
    choose one from our preconfigured themes or customize them to
    suit your needs.`,
    footer: () => (
      <Col align="center">
        {/* <br />
        <h3 className="color-secondary">Customize</h3> */}
        <Row justify="space-between">
          <Link to={routes.WIDGET_REVIEW_CUSTOMIZE}>
            <Button type="secondary" size="default">
              Review Widget Theme
            </Button>
          </Link>
          <Link to={routes.WIDGET_POPUP_CUSTOMIZE}>
            <Button type="secondary" size="default">
              Popup Widget Theme
            </Button>
          </Link>
        </Row>
      </Col>
    ),
  },
  {
    title: "Setup AI",
    name: "Setup AI",
    img: AiSettings,
    desc: `Enable various AI services like generating and replying to reviews from given keywords, automatic date updation, automatic date sorting, 
    and plenty more that you would want on your website. AI credits are provided on a monthly basis for paid plan users.`,
    footer: () => (
      <Link to={routes.SETTINGS_GENERAL_AI}>
        <Button type="secondary" size="default">
          Enable AI Services
        </Button>
      </Link>
    ),
  },
  {
    title: "Enable App",
    name: "Enable App",
    popinfo: "Turn on this switch to enable the app",
    img: Enable,
    desc: `By default, Revyu will be disabled and won't show up on your store until you enable it. 
    You can enable it from the top right corner of the dashboard.`,
    footer: () => <></>,
  },
];

const initialState = {
  location: "product",
  fetchingProducts: true,
  products: null,
  countOfProducts: 0,
  activeCategory: "all",
  searchParams: "",
  reviews: [],
  fetchingReviews: true,
  aiEnablingId: -1, // id of ai enabling product to set loader
  savingProductDetails: false,
  productDetails: null,
  updatingReview: null,
};

export default initialState;

import { del, post } from "../common/remote";

export const addReviewWidget = (shopName, type, isThemeApp) =>
  post("/reviewWidget", { shopName, type, isThemeApp });

export const removeReviewWidget = (shopName, type, isThemeApp) =>
  del("/reviewWidget", { shopName, type, isThemeApp });

export const addRatingWidget = (shopName, type, isThemeApp) =>
  post("/ratingWidget", { shopName, type, isThemeApp });

export const removeRatingWidget = (shopName, type, isThemeApp) =>
  del("/ratingWidget", { shopName, type, isThemeApp });

export const togglePopup = (shopName, type, enabled, isThemeApp) =>
  post("/popupWidget", { shopName, type, enabled, isThemeApp });

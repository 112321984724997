import { Result } from "antd";
import React from "react";

const Error403 = () => {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page. "
      extra={<span>Please access the app from shopify admin apps panel</span>}
    />
  );
};

export default Error403;

import moment from "moment";
import { bannerImage, rrMailTemplates } from "../../../../common/constants";

const initialState = {
  active: false,
  preview: "lap",
  emailTiming: 1,
  emailType: "Day",
  after: "fulfilled",
  template: "A",
  subject: rrMailTemplates["A"].subject,
  previewText: rrMailTemplates["A"].previewText,
  message: rrMailTemplates["A"].message,
  buttonText: "Submit Review",
  buttonColor: "#FA8B0C",
  buttonTextColor: "#FFFFFF",
  displayStoreName: null,
  displayMail: null,
  footer: `© ${moment().year()} {Shop Name}. All Rights reserved. Powered by Revyu.`,
  buttonPicker: false,
  textPicker: false,
  image: [bannerImage],
  uploadable: [bannerImage],
  isSaving: false,
  isFetchingData: true,
  mailing: false,
};

export default initialState;

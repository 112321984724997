export const sorters = {
  AUTHOR: { label: "Author", value: "author" },
  CONTENT: { label: "Content", value: "content" },
  DATE: { label: "Date", value: "date" },
  RATING: { label: "Rating", value: "rating" },
};

export const filters = {
  APPROVED: { label: "Approved", value: "approved" },
  UNAPPROVED: { label: "UnApproved", value: "unapproved" },
  NOT_INCENTIVISED: { label: "Not Incentivised", value: "not_incentivised" },
  NOT_REPLIED: { label: "Not Replied", value: "not_replied" },
  AI_GEN: { label: "AI Generated", value: "ai_generated" },
};

import Styled from "styled-components";

const PricingCard = Styled.div`
  background: #fff;
  border-radius: 10px;
  padding: ${({ isHighlighted }) => (isHighlighted ? "55px 30px" : "30px")};
  transition: 0.3s ease-in-out height;
  border: 2px solid ${({ isHighlighted, theme }) =>
    isHighlighted ? theme[`success-color`] : "transparent"};
  display: flex;
  align-items: center;
  flex-direction: column;
  .rPlanCard-left {
    flex: 1;
    border-bottom: 1px solid #dddddd;
    border-right: none;
    padding-bottom: 25px;
    padding-right: 0px;
    width: 100%;
  }
  
  @media (min-width: 968px) {
    flex-direction: row;
    .rPlanCard-left {
      border-right: 1px solid #dddddd;
      border-bottom: none;
      padding-right: 25px;
      padding-bottom: 0px;
    }
  }
  
  .pricing-badge{
    height: 32px;
    padding: 6px 22.6px;
  }
  .credits-badge {
    color: #FA8B0C;
    font-weight: 700;
    font-size: 16px;
    margin: 30px 0;
  }
  .price-amount{
    font-size: 36px;
    margin-bottom: 10px;
    .currency{
      font-size: 16px;
      font-weight: 600;
      top: -12px;
      ${({ theme }) => (!theme.rtl ? "margin-left" : "margin-right")}: 2px;
      color: ${({ theme }) => theme["extra-light-color"]};
    }
    .pricing-validity{
      font-size: 13px;
      font-weight: 400;
      bottom: 0;
      ${({ theme }) => (!theme.rtl ? "left" : "right")}: -2px;
      color: ${({ theme }) => theme["light-color"]};
    }
  }
  .package-user-type{
    font-size: 13px;
    font-weight: 500;
    color: ${({ theme }) => theme["gray-color"]};
  }
  .pricing-title{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  button{
    padding: 0px 29.32px;
    height: 44px;
    border-radius: 6px;
    &.ant-btn-white{
      border: 1px solid #E3E6EF;
      span{
        color: #272b41;
      }
    }
  }
`;

const ListGroup = Styled.div`
  margin: 28px 0 15px;
  padding: 0 50px;
  padding-left: 25px;
  flex: 4;
  .list-single{
    display: flex;
    align-items: center;
    &:not(:last-child){
      margin-bottom: 12px;
    }
    .icon{
      display: inline-block;
      margin: ${({ theme }) =>
        theme.rtl ? "0px 0 -4px 10px" : "0px 10px -4px 0"};
    }
  }
`;

const Badge = Styled.span`
  display: inline-block;
  margin-bottom: 32px;
  padding: 5px 20px;
  border-radius: 16px;
  background: ${({ type, theme }) => theme[`${type}-color`]}10;
  color: ${({ type, theme }) => theme[`${type}-color`]};
  font-size: 13px;
  font-weight: 500;
`;

const PricingWrapper = Styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px 30px 20px;
`;

export { PricingCard, ListGroup, Badge, PricingWrapper };

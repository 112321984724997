import { PageHeaderStyle } from "./style";

const PageHeader = (props) => {
  const {
    title,
    subTitle,
    routes,
    buttons,
    ghost,
    bgColor,
    className,
    innerBgColor,
  } = props;
  return (
    <>
      <div
        style={{
          backgroundColor: bgColor || "#F4F5F7",
        }}
      >
        <PageHeaderStyle
          style={{
            backgroundColor: innerBgColor || "rgb(244, 245, 247)",
          }}
          // onBack={() => window.history.back()}
          className={className}
          title={title}
          subTitle={subTitle}
          breadcrumb={routes && { routes }}
          extra={buttons}
          ghost={ghost}
        />
      </div>
    </>
  );
};

export { PageHeader };

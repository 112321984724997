import { Rate } from "antd";
import { PlaceholderImage } from "../../common/Assets/Images";
import { textRefactor } from "../../common/Utils/textRefactor";
import { Popup } from "../../Theme/Popup/components/Display/styled";

const PopUpReviews = ({ location }) => {
  const params = new URLSearchParams(location.search);

  if (params) {
    let data = {
      author: "",
      imgUrl: "",
      star: 0,
      desc: "",
    };
    for (const param of params) {
      data[param[0]] = param[1];
    }
    let { imgUrl, star, author, desc, date } = data;

    imgUrl = imgUrl?.includes("firebasestorage")
      ? imgUrl?.split("images")[0] +
        "images" +
        imgUrl?.split("images")[1]?.replaceAll("/", "%2F")
      : imgUrl;

    return (
      <Popup
        {...{
          states: data,
          isRow: data.popupLayout === "row",
          preview: false,
        }}
      >
        <img
          src={imgUrl}
          alt="product"
          onError={({ target }) => (target.src = PlaceholderImage)}
        />
        <div className="popup-content">
          <div className="popup-head">
            <Rate
              style={{ color: data.ratingColor }}
              defaultValue={star}
              disabled
            />
            {data.pubDate === "true" && <time>{date}</time>}
          </div>
          <p className="popup-msg">"{textRefactor(desc, 65)}"</p>
          <p className="popup-auth">{textRefactor(author, 16)}</p>
        </div>
      </Popup>
    );
  }

  return null;
};

export default PopUpReviews;

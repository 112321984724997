import React from "react";
import { getTime } from "../../../../../../../../common/Utils/getTime";
import "./index.scss";

const ReviewDate = (props) => {
  const { templates, currentTheme, date } = props;
  const { Body, General } = templates[currentTheme];

  if (!Body?.date) return null;

  return (
    <span
      className="date"
      style={{
        color: General?.textSecondary,
      }}
    >
      {getTime(date).format(Body?.dateFormat ? Body?.dateFormat : "DD-MM-YYYY")}
    </span>
  );
};

export default ReviewDate;

import React from "react";
import FeatherIcon from "feather-icons-react";
import PropTypes from "prop-types";
import { ListStyle } from "./style";
import { Tooltip } from "antd";

const List = (props) => {
  const { item, isHighlighted } = props;

  return (
    <ListStyle className="list-single">
      <span className="icon">
        <FeatherIcon icon="check" size={14} />
      </span>
      {/* <span>{text}</span> */}
      <Tooltip
        title={
          item.desc ? (
            <p style={{ color: "black", margin: 0 }}>{item.desc}</p>
          ) : (
            ""
          )
        }
      >
        {isHighlighted ? <b>{item.title}</b> : <span>{item.title}</span>}
      </Tooltip>
      {/* <b>{text}</b> */}
    </ListStyle>
  );
};
List.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]),
};
export { List };

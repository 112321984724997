import { primaryDB } from "../common/firebase";
import { post } from "../common/remote";

const settingsRef = (shop) => primaryDB.ref(`${shop}/storeSettings`);

// settings
export const getStoreSettings = (shop) => {
  return settingsRef(shop).once("value");
};
export const postStoreSettings = (shopName, data) => {
  return post(`/settings/store`, { shopName, data });
  // return settingsRef(shopName).set(data);
};

// Store isActive
export const getStoreActive = (shop) => {
  return settingsRef(shop).child("active").once("value");
};
export const postStoreActive = (shop, isActive) => {
  return settingsRef(shop).child("active").set(isActive);
};

// Billing
export const getBillingDetails = (shop) => {
  return settingsRef(shop).child("billing").once("value");
};
export const postBillingDetails = (shopName, data) => {
  return post(`/settings/billing`, { shopName, data });
  // return settingsRef(shopName).child(`billing`).set(data);
};

// email settings
export const getEmailSettings = (shop) => {
  return settingsRef(shop).child(`email`).once("value");
};
export const postEmailSettings = (shopName, data) => {
  return post(`/settings/email`, { shopName, data });
  // return settingsRef(shopName).child(`email`).set(data);
};
export const postEmailActive = (shop, newState) => {
  return settingsRef(shop).child(`email/active`).set(newState);
};

// popup settings
export const getPopupSettings = (shop) => {
  return settingsRef(shop).child(`popup`).once("value");
};
export const postPopupSettings = (shopName, data) => {
  return post(`/settings/popup`, { shopName, data });
  // return settingsRef(shopName).child(`popup`).set(data);
};

// review settings
export const getReviewSettings = (shop) => {
  return settingsRef(shop).child(`review`).once("value");
};
export const postReviewSettings = (shopName, data) => {
  return post(`/settings/review`, { shopName, data });
  // return settingsRef(shopName).child(`review`).set(data);
};

// review type isActive
export const getReviewTypesActive = (shop) => {
  return settingsRef(shop).child(`review/types`).once("value");
};
export const postReviewTypesActive = (shop, isActiveData) => {
  return settingsRef(shop).child(`review/types`).set(isActiveData);
};

// review type isActive
export const getThemeAppSettings = (shop) => {
  return settingsRef(shop).child(`themeApp/themeAppSupport`).once("value");
};
export const postThemeAppSettings = (shop, value) => {
  return settingsRef(shop).child(`themeApp/themeAppSupport`).set(value);
};

// review coupons
export const getCouponSettings = (shop) => {
  return settingsRef(shop).child(`coupon`).once("value");
};
export const setCouponSettings = (shopName, value) => {
  return post(`/settings/coupons`, { shopName, data: value });
  // return settingsRef(shop).child(`coupon`).set(value);
};
export const createCoupon = (shop, toEmail) => {
  return post(`/store/discountCode`, { shop, toEmail });
};
export const createPriceRule = (data) => {
  return post(`/store/priceRule`, data);
};

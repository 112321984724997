export const popupThemes = {
  DEFAULT_THEME: {
    name: "Default Theme",
    styles: {
      backgroundColor: "#5F63F2",
      ratingColor: "#fadb2b",
      primaryColor: "#ffffff",
      secondaryColor: "#ffffff",
    },
  },

  WHITE_BIRD: {
    name: "White Bird",
    styles: {
      backgroundColor: "#ffffff",
      ratingColor: "#1e90ff",
      primaryColor: "#000000",
      secondaryColor: "#999999",
    },
  },

  FLAMINGO: {
    name: "Flamingo",
    styles: {
      backgroundColor: "#FFA500",
      ratingColor: "#8b4513",
      primaryColor: "#8b4513",
      secondaryColor: "#ffffff",
    },
  },

  TOMATO: {
    name: "Tomato",
    styles: {
      backgroundColor: "#ff6347",
      ratingColor: "#acacac",
      primaryColor: "#ffffff",
      secondaryColor: "#eeeeee",
    },
  },

  CANOPY: {
    name: "Canopy",
    styles: {
      backgroundColor: "#008000",
      ratingColor: "#90ee90",
      primaryColor: "#ffffff",
      secondaryColor: "#b0ffb0",
    },
  },

  GREEN_WEEDS: {
    name: "Green Weeds",
    styles: {
      backgroundColor: "#03b8a5",
      ratingColor: "#7ed321",
      primaryColor: "#fffcff",
      secondaryColor: "#e4e4e4",
    },
  },

  WILD_BUSH: {
    name: "Wild Bush",
    styles: {
      backgroundColor: "#00948a",
      ratingColor: "#85ff00",
      primaryColor: "#ffffff",
      secondaryColor: "#f0f0f0",
    },
  },

  ROSE_MILK: {
    name: "Rose Milk",
    styles: {
      backgroundColor: "#d8c4b9",
      ratingColor: "#ffffff",
      primaryColor: "#ffffff",
      secondaryColor: "#f5ebe0",
    },
  },
};

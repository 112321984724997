const routes = {
  INITIAL: "/",
  REVIEWS: "/reviews/manage/:id",
  BILLING: "/billing",
  ERROR403: "/403",
  ERROR404: "/404",
  INSTALLATION_CALLBACK: "/shopify/callback",
  DASHBOARD: "/dashboard",
  UPGRADE: "/upgrade",
  REVIEWS_MANAGE: "/reviews/manage",
  REVIEW_WIDGET: "/widgets/reviews",
  BILLINGCALLBACK: "/shopify/billing/callback",
  DISPLAY_REVIEWS_WIDGET: "/widget/:shopName/:productId",
  RATING_ON_PRODUCT_WIDGET: "/ratingWidget/:shop_id/:product_id",
  HOME_WIDGET: "/homeWidget/:shop_id",
  POPUP: "/popup",
  STAR_RATING: "/widgets/star-rating",

  // START: ONBOARDING
  ONBOARDING: "/onboarding",
  // END: ONBOARDING

  // START: SETTINGS
  SETTINGS_GENERAL: "/i/settings/general",
  SETTINGS_GENERAL_AUTOGEN: "/settings/general/auto-generate",
  SETTINGS_GENERAL_AUTOPUB: "/i/settings/general",
  SETTINGS_GENERAL_AI: "/i/settings/general/ai",
  SETTINGS_WIDGETS: "/settings/widgets",
  // END: SETTINGS

  // START: WIDGETS
  WIDGET_REVIEW_CUSTOMIZE: "/widgets/review/customize",
  WIDGET_POPUP_CUSTOMIZE: "/widgets/popup/customize",
  WIDGET_REVIEW_DISPLAY: "/widgets/review/display/:shopName/:productId",
  WIDGET_POPUP_DISPLAY: "/widgets/popup/display/",
  // END: WIDGETS

  // CUSTOMER ENGAGEMENT
  SETTINGS_EMAIL: "/user/email",
  SETTINGS_EMAIL_REVIEWREQ: "/user/email/review-request",
  COUPONS: "/user/coupons",
  // CUSTOMER ENGAGEMENT

  // START: INTEGRATIONS
  INTEGRATIONS: "/integrations",
  // END: INTEGRATIONS

  // START: COUPONS
  // END: COUPONS
};

// Change initial route if required
routes.DASHBOARD = routes.INITIAL;

export { routes };

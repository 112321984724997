import React, { useContext } from "react";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { AnalyticsContext } from "../../../../common/Context/AnalyticsContext";
import { MixpanelEvents } from "../../../../../common/constants/analytics/events";

const DropDownItem = ({
  externalLink = false,
  link,
  onClick = () => {},
  icon,
  label,
  className = "",
}) => {
  const { track } = useContext(AnalyticsContext);

  const LinkWrapper = ({ children }) =>
    externalLink ? (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ) : (
      <Link
        onClick={() => track(MixpanelEvents.GENERAL.ROUTE_TO(link, "Nav link"))}
        to={link}
      >
        {children}
      </Link>
    );

  return (
    <li className={className} onClick={onClick}>
      <LinkWrapper>
        <FeatherIcon icon={icon} /> {label}
      </LinkWrapper>
    </li>
  );
};

export default DropDownItem;

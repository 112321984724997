import "./index.scss";
import React from "react";
// import { Divider } from "antd";
import AuthorName from "../../components/AuthorName";
import Avatar from "../../components/Avatar";
import { ReviewContent } from "../../components/CardBody";
import Country from "../../components/Country";
import { PlainRating } from "../../components/RatingBadge";
import ReviewDate from "../../components/ReviewDate";
// import ReviewReply from "../../components/ReviewReply";
import { textRefactor } from "../../../../../../../../common/Utils/textRefactor";
import ReviewImages from "../../components/ReviewImages";

const ListLayout = (props) => {
  // const { reply } = props;
  const { setShowModal } = props;

  return (
    <>
      <ReviewImages {...props} />
      <div className="listBody" onClick={() => setShowModal(true)}>
        <div className="listHeader">
          <div className="listFooterR">
            <Avatar {...props} />
            <AuthorName {...props} />
          </div>
          <Country {...props} />
        </div>
        <div className="listFooter">
          <PlainRating {...props} />
          <ReviewDate {...props} />
        </div>
        <ReviewContent
          {...{ ...props, content: textRefactor(props?.content, 200) }}
        />

        {/* {reply && <Divider type="horizontal" style={{ margin: 5 }} />}
        <ReviewReply {...props} /> */}
      </div>
    </>
  );
};

export default ListLayout;

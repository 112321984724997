import React, { useEffect, useState } from "react";
import { stripShopName } from "../../../common/utils/shop";
import { useAuthStore } from "../../common/Stores";
// import { PrimaryLoadingIndicator } from "../../common/UI/LoadingIndicator";
import { useReviewData } from "../../Reviews/store";
import { usePopupSettingsData } from "../../Theme/Popup/store";
import { runPopUpGenerator } from "../PopUpReviews/actions";
import { useReviewWidget } from "./ReviewContainer/store";
import ReviewContainer from "./ReviewContainer";

// const ReviewContainer = React.lazy(() => import("./ReviewContainer"));

const DisplayReviews = ({ match }) => {
  const [, authActions] = useAuthStore();
  const [{ loader, reviews, widgetStatus }, widgetActions] = useReviewWidget();
  const [popupSettings, popupActions] = usePopupSettingsData();
  const [, { getProductDetails }] = useReviewData();
  const [popupRunning, setpopupRunning] = useState(false);
  const [reviewPopup, setReviewPopup] = useState(false);
  const [productId, setProductId] = useState(false);

  let searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    let storeFrontData = null;
    // See if the trigger to open the write review modal is present or not
    // This will be true when people come redirected from mail
    let reviewPopupTrigger = searchParams.get("review");
    reviewPopupTrigger === "true" &&
      setReviewPopup(true) &&
      widgetActions.setWriteReview(true);

    /**
     * if there are params in the url,
     * then conditions for the review widget to be
     * loaded on store front are triggered
     */
    if (match) {
      const { shopName, productId } = match.params;
      setProductId(productId);
      widgetActions.getWidgetData(shopName);

      storeFrontData = { shopName, productId };
      getProductDetails(shopName, productId);
      popupActions.loadPopupSettings(shopName);
      widgetActions.getDbTheme(shopName);
      widgetActions.getAutoPublishSettings(shopName);
      authActions.setShopName(stripShopName(shopName));
      widgetActions.setStoreFrontData(storeFrontData);
    }
    let el = document.getElementById("root").style;
    el.height = "auto";
    el.minHeight = "100%";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const pageType = searchParams.get("pageType");
    widgetActions.setPageType(pageType);
    // check if widgetData, widgetSettingsData & shopData is loaded
    if (
      match &&
      !loader && // if reviews are loaded
      !popupSettings.loading && // if popup settings are loaded
      pageType !== undefined // pageType should either be "productPage" or "storePage"
    ) {
      // after required settings are loaded
      // check if the req settings are activated and available
      if (
        reviews.length > 0 && // if there are more than 1 reviews
        !popupRunning // if popupGenerator hasn't started running
      ) {
        const { shopName } = match.params;
        setpopupRunning(true); // popup generator started running
        runPopUpGenerator(reviews, shopName, popupSettings);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader, reviews, popupSettings, popupRunning, widgetActions]);

  if (productId === "store" && !widgetStatus?.store) return null;

  if (productId !== "store" && !widgetStatus?.product) return null;

  return <ReviewContainer {...{ reviewPopup }} />;
};

export default DisplayReviews;

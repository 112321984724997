import moment from "moment";

export const SETTINGS = {
  THEME: {
    general: {
      quickTheme: [
        {
          key: "Default Theme",
          value: "DEFAULT_THEME",
        },
        {
          key: "Facebook Theme",
          value: "FB_THEME",
        },
        {
          key: "Youtube Theme",
          value: "YOUTUBE_THEME",
        },
        {
          key: "Twitter Theme",
          value: "TWITTER_THEME",
        },
        {
          key: "UFO Green",
          value: "UFO_GREEN",
        },
        {
          key: "Lotus Pink",
          value: "LOTUS_PINK",
        },
        {
          key: "Watermelon",
          value: "WATERMELON",
        },
        {
          key: "Steam Wood",
          value: "STEAM_WOOD",
        },
      ],
      fonts: [
        {
          key: "Poppins",
          value: "poppins",
        },
      ],
      ratingIcons: [
        { key: "Star", value: "star" },
        { key: "Heart", value: "heart" },
      ],
    },
    body: {
      customerName: [
        {
          key: "John Doe",
          value: "full",
        },
        {
          key: "John D",
          value: "first",
        },
        {
          key: "J. D",
          value: "short",
        },
        {
          key: "J***",
          value: "encoded",
        },
      ],
      loadMore_shape: [
        {
          key: "Square",
          value: "square",
        },
        {
          key: "Round",
          value: "round",
        },
      ],
      customerAvatar: [
        {
          key: "Round",
          value: "round",
        },
        {
          key: "Square",
          value: "square",
        },
      ],
      customerAvatarDisplay: [
        {
          key: "Image",
          value: "image",
        },
        {
          key: "Text",
          value: "text",
        },
      ],
      flag_shape: [
        {
          key: "Round",
          value: "round",
        },
        {
          key: "Rectangle",
          value: "rectangle",
        },
      ],
      list_layouts: [
        {
          key: "Grid",
          value: "grid",
        },
        {
          key: "List",
          value: "list",
        },
        {
          key: "Dynamic",
          value: "dynamic",
        },
        {
          key: "Zedge",
          value: "zedge",
        },
      ],
      text_alignment: [
        {
          key: "Left",
          value: "left",
        },
        {
          key: "Right",
          value: "right",
        },
        {
          key: "Center",
          value: "center",
        },
        {
          key: "Justify",
          value: "justify",
        },
      ],
      date_format: [
        {
          value: "DD-MM-YYYY",
          key: moment(Date.now()).format("DD-MM-YYYY"),
          // key: "03-12-2021",
        },
        {
          value: "MM-DD-YYYY",
          key: moment(Date.now()).format("MM-YY-YYYY"),
          // key: "12-03-2021",
        },
        {
          value: "YYYY-MM-DD",
          key: moment(Date.now()).format("YYYY-MM-DD"),
          // key: "2021-12-03",
        },
        {
          value: "MMM-DD-YYYY",
          key: moment(Date.now()).format("MMM-DD-YYYY"),
          // key: "Dec-03-2021",
        },
        {
          value: "YYYY-MMM-DD",
          key: moment(Date.now()).format("YYYY-MMM-DD"),
          // key: "2021-Dec-03",
        },
      ],
    },
    header: {
      header_layouts: [
        { key: "Standard", value: "standard" },
        { key: "Minimal", value: "minimal" },
      ],
      form_type: [
        // {
        //   key: "Toggle",
        //   value: "toggle",
        // },
        {
          key: "Popup",
          value: "popup",
        },
      ],
      avg_shape: [
        {
          key: "Round",
          value: "round",
        },
        {
          key: "Square",
          value: "square",
        },
      ],
      submit_shape: [
        {
          key: "Square",
          value: "square",
        },
        {
          key: "Round",
          value: "round",
        },
      ],

      review_shape: [
        {
          key: "Square",
          value: "square",
        },
        {
          key: "Round",
          value: "round",
        },
      ],
    },
  },
  // DEFAULT: {
  //   DEFAULT_THEME: {
  //     iconColor: "#fadb2b",
  //     subBtnColor: "#5c6ac4",
  //     writeReviewBtnC: "#5c6ac4",
  //     avgRatingColor: "#5c6ac4",
  //     verifiedColor: "#5c6ac4",
  //     textPrimary: "#000000",
  //     textSecondary: "#a3a3a3",
  //     reviewBg: "#ffffff",
  //     reviewsBg: "#ffffff",
  //     icon: "star",
  //     flag: true,
  //   },

  //   FB_THEME: {
  //     iconColor: "#3578e5",
  //     subBtnColor: "#3578e5",
  //     writeReviewBtnC: "#3578e5",
  //     avgRatingColor: "#3578e5",
  //     verifiedColor: "#3578e5",
  //     textPrimary: "#000000",
  //     textSecondary: "#a3a3a3",
  //     reviewBg: "#ffffff",
  //     reviewsBg: "#ffffff",
  //     flag: true,
  //   },

  //   TWITTER_THEME: {
  //     iconColor: "#1da0f2",
  //     subBtnColor: "#1da0f2",
  //     writeReviewBtnC: "#1da0f2",
  //     avgRatingColor: "#1da0f2",
  //     verifiedColor: "#1da0f2",
  //     reviewBg: "#38444d",
  //     reviewsBg: "#15202b",
  //     textPrimary: "#ffffff",
  //     textSecondary: "#8899a6",
  //     flag: true,
  //   },

  //   YOUTUBE_THEME: {
  //     iconColor: "#FF0000",
  //     subBtnColor: "#FF0000",
  //     writeReviewBtnC: "#FF0000",
  //     avgRatingColor: "#FF0000",
  //     verifiedColor: "#FF0000",
  //     reviewBg: "#282828",
  //     reviewsBg: "#121212",
  //     textPrimary: "#ffffff",
  //     textSecondary: "#8899a6",
  //     flag: true,
  //   },

  //   UFO_GREEN: {
  //     iconColor: "#2ED573",
  //     subBtnColor: "#2ED573",
  //     writeReviewBtnC: "#2ED573",
  //     avgRatingColor: "#2ED573",
  //     verifiedColor: "#2ED573",
  //     reviewBg: "#ffffff",
  //     reviewsBg: "#ffffff",
  //     icon: "star",
  //     flag: true,
  //     textPrimary: "#000000",
  //     textSecondary: "#949494",
  //   },
  //   LOTUS_PINK: {
  //     iconColor: "#F368E0",
  //     subBtnColor: "#F368E0",
  //     writeReviewBtnC: "#F368E0",
  //     avgRatingColor: "#F368E0",
  //     verifiedColor: "#F368E0",
  //     reviewBg: "#ffffff",
  //     reviewsBg: "#ffffff",
  //     textPrimary: "#000000",
  //     textSecondary: "#949494",
  //     loadMoreBtnColor: "#F368E0",
  //   },
  //   WATERMELON: {
  //     iconColor: "#FF4757",
  //     subBtnColor: "#FF4757",
  //     writeReviewBtnC: "#FF4757",
  //     avgRatingColor: "#FF4757",
  //     verifiedColor: "#FF4757",
  //     reviewBg: "#ffffff",
  //     reviewsBg: "#ffffff",
  //     icon: "star",
  //     flag: true,
  //     textPrimary: "#000000",
  //     textSecondary: "#949494",
  //   },
  // },
};

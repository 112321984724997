import { primaryDB } from "../common/firebase";
import { post } from "../common/remote";

export const getStoreCounters = (shopName) => {
  return primaryDB.ref(`${shopName}/storeSettings/counters`).once("value");
};

export const setStoreCounters = (shopName, data) => {
  return post(`/settings/counters`, { shopName, data });
  // return primaryDB.ref(`${shopName}/storeSettings/counters`).set(data);
};

export const getCredits = (shopName) => {
  return primaryDB
    .ref(`${shopName}/storeSettings/counters/credits`)
    .once("value");
};

export const setCredits = (shopName, data) => {
  return post(`/settings/credits`, { shopName, data });
  // return primaryDB.ref(`${shopName}/storeSettings/counters/credits`).set(data);
};

export const getThemePref = (shopName) => {
  return primaryDB.ref(`${shopName}/storeSettings/theme`).once("value");
};

export const isWidgetActive = (shopName) => {
  return primaryDB.ref(`${shopName}/storeSettings/active`).once("value");
};

export const isBrandActive = (shopName) => {
  return primaryDB.ref(`${shopName}/storeSettings/brandFlag`).once("value");
};

export const setStoreTheme = (shopName, newTheme) => {
  return primaryDB
    .ref(`${shopName}/storeSettings/theme/customTheme`)
    .set(newTheme);
};

export const setCurrentTheme = (shopName, currentTheme) => {
  return primaryDB
    .ref(`${shopName}/storeSettings/theme/current_theme`)
    .set(currentTheme);
};

export const getAllReviews = (shopName) => {
  return primaryDB.ref(`stores/${shopName}/reviews`).once("value");
};

export const getPopupSettings = (shopName) => {
  return primaryDB.ref(`${shopName}/storeSettings/popup`).once("value");
};

export const setPopupSettings = (shopName, data) => {
  return post(`/settings/popup`, { shopName, data });
  // return primaryDB.ref(`${shopName}/storeSettings/popup`).set(data);
};

export const getStoreIntroFlag = (shopName) => {
  return primaryDB.ref(`${shopName}/storeSettings/showIntro`).once("value");
};

export const setStoreIntroFlag = (shopName, showIntro) => {
  return primaryDB.ref(`${shopName}/storeSettings/showIntro`).set(showIntro);
};

export const setEmailData = (shopName, data) => {
  return post(`/store/email`, { shopName, data });
  // return primaryDB.ref(`${shopName}/email`).set(data);
};

export const getEmailData = (shopName) => {
  return primaryDB.ref(`${shopName}/email`).once("value");
};

export const setEmailState = (shopName, newState) => {
  return primaryDB.ref(`${shopName}/email/active`).set(newState);
};

export const getReviewWidgetData = (shopName) => {
  return primaryDB.ref(`${shopName}/storeSettings/review/types`).once("value");
};

export const setReviewWidgetData = (shopName, data) => {
  return post(`/settings/reviewWidget`, { shopName, data });
  // return primaryDB.ref(`${shopName}/storeSettings/review/types`).set(data);
};

export const getAiWaitlist = () => {
  return primaryDB.ref(`000_waitlist`).once("value");
};

export const setAiWaitlist = (newList) => {
  return primaryDB.ref(`000_waitlist`).set(newList);
};

export const setStoreSettings = (shopName, data) => {
  return post(`/settings/store`, { shopName, data });
  // return primaryDB.ref(`${shopName}/storeSettings`).set(data);
};

export const getStoreSettings = (shopName) => {
  return primaryDB.ref(`${shopName}/storeSettings`).once("value");
};

export const setReviewSettings = (shopName, data) => {
  return post(`/settings/review`, { shopName, data });
  // return primaryDB.ref(`${shopName}/storeSettings/review`).set(data);
};

export const getReviewSettings = (shopName) => {
  return primaryDB.ref(`${shopName}/storeSettings/review`).once("value");
};

export const setWidgetState = (shopName, type, state) => {
  return primaryDB
    .ref(`${shopName}/storeSettings/review/types/${type}`)
    .set(state);
};

export const getWidgetStates = (shopName) => {
  return primaryDB.ref(`${shopName}/storeSettings/review/types`).once("value");
};

export const changeBillingData = (shopName, data) => {
  return post(`/settings/subscription`, { shopName, data });
  // return primaryDB
  //   .ref(`${shopName}/storeSettings/billing/subscriptionType`)
  //   .set(data);
};

import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { MixpanelEvents } from "../../../../common/constants/analytics/events";
import { DoNowImageOB } from "../../../common/Assets/Images";
import { AnalyticsContext } from "../../../common/Context/AnalyticsContext";
import { routes } from "../../../common/Routes";
import DoNowSelect from "../Customize/common/DoNowSelect";
import ObBodyLayout from "../ObBodyLayout";
import "./index.scss";

const DoNowOB = ({ slider, onDone }) => {
  const [selected, setSelected] = useState("import");
  const history = useHistory();
  const { track } = useContext(AnalyticsContext);

  const pushRoutes = {
    import: routes.REVIEWS_MANAGE,
    migrate: routes.REVIEWS_MANAGE + "?import=true",
    discount: routes.COUPONS,
  };

  return (
    <ObBodyLayout
      title="Your store is now geared up with beautiful product reviews"
      image={<img src={DoNowImageOB} alt="onboardingImg" />}
      nextBtnAction={() => {
        onDone();
        track(MixpanelEvents.ONBOARDING.COMPLETE, {
          selectedRoute: selected,
        });
        history.push(pushRoutes[selected]);
      }}
    >
      <p className="doNowTag">What would you like to do now?</p>
      <div>
        <DoNowSelect
          onClick={() => setSelected("migrate")}
          isSelected={selected === "migrate"}
          title={"Migrate reviews"}
          content="Migrating from other apps made easy with revyu's import settings"
        />
        <DoNowSelect
          onClick={() => setSelected("import")}
          isSelected={selected === "import"}
          title={"Import new reviews"}
          content="Import new product reviews from AliExpress"
        />
        <DoNowSelect
          onClick={() => setSelected("discount")}
          isSelected={selected === "discount"}
          title={"Setup discount codes"}
          content="Offering your customers customizable reward and boost your engagement"
        />
      </div>
    </ObBodyLayout>
  );
};

export default DoNowOB;

import { PRO } from "./PRO";
import { FREE } from "./FREE";

export const EARLY_BIRD = {
  name: "Early Bird",
  price: 0.0,
  counters: {
    ...FREE.counters,
    ...PRO.counters,
  },
  permissions: {
    ...FREE.permissions,
    ...PRO.permissions,
  },
  features: [],
};

const DEFAULT_VALUES = {
  Body: {
    title: "Customer Reviews",
    alignment: "justify",
    avgRatingColor: "#5c6ac4",
    custAvatar: "round",
    custAvatarDisplay: "image",
    custName: "John Doe",
    date: true,
    dateFormat: "DD-MM-YYYY",
    flag: true,
    flagShape: "round",
    font: "poppins",
    formType: "popup",
    icon: "star",
    iconColor: "#fadb2b",
    headerLayout: "standard",
    listLayout: "grid",
    reviewBg: "#ffffff",
    reviewPic: true,
    reviewsBg: "#ffffff",
    subBtnColor: "#5c6ac4",
    subBtnShape: "round",
    textPrimary: "#000000",
    textSecondary: "#a3a3a3",
    verified: true,
    verifiedColor: "#5c6ac4",
    writeReviewBtnC: "#5c6ac4",
    writeReviewBtnS: "round",
    writeReviewBtnT: "Write a Review",
    loadMoreBtnText: "Load More",
    loadMoreBtnColor: "#5c6ac4",
    loadMoreBtnShape: "round",
    loadMoreBtnTextColor: "#ffffff",
    minimalistView: false,
  },
  General: {
    title: "Customer Reviews",
    avgRatingColor: "#5c6ac4",
    custAvatar: "round",
    custAvatarDisplay: "image",
    custName: "John Doe",
    date: true,
    flag: true,
    font: "poppins",
    formType: "popup",
    icon: "star",
    iconColor: "#fadb2b",
    headerLayout: "standard",
    listLayout: "grid",
    reviewBg: "#ffffff",
    reviewPic: true,
    reviewsBg: "#ffffff",
    subBtnColor: "#5c6ac4",
    subBtnShape: "round",
    textPrimary: "#000000",
    textSecondary: "#a3a3a3",
    verified: true,
    verifiedColor: "#5c6ac4",
    writeReviewBtnT: "Write a Review",
    writeReviewBtnC: "#5c6ac4",
    writeReviewBtnS: "round",
    minimalistView: false,
  },
  Header: {
    title: "Customer Reviews",
    avgRatingColor: "#5c6ac4",
    avgRatingShape: "square",
    custAvatar: "round",
    custAvatarDisplay: "image",
    custName: "John Doe",
    date: true,
    flag: true,
    formType: "popup",
    icon: "star",
    iconColor: "#fadb2b",
    headerLayout: "standard",
    listLayout: "grid",
    reviewBg: "#ffffff",
    reviewPic: true,
    reviewsBg: "#ffffff",
    subBtnColor: "#5c6ac4",
    subBtnShape: "round",
    subRevBtnTextColor: "#ffffff",
    textPrimary: "#000000",
    textSecondary: "#a3a3a3",
    verified: true,
    verifiedColor: "#5c6ac4",
    viewForm: false,
    writeReviewBtnT: "Write a Review",
    writeReviewBtnC: "#5c6ac4",
    writeReviewBtnS: "round",
    writeReviewBtnTextC: "#ffffff",
    writeReviewBtnVisibility: true,
    minimalistView: false,
  },
};

const defaultThemes = {
  DEFAULT_THEME: {
    Body: { ...DEFAULT_VALUES.Body },
    General: { ...DEFAULT_VALUES.General },
    Header: { ...DEFAULT_VALUES.Header },
  },
  FB_THEME: {
    Body: {
      ...DEFAULT_VALUES.Body,
      avgRatingColor: "#3578e5",
      iconColor: "#3578e5",
      subBtnColor: "#3578e5",
      verifiedColor: "#3578e5",
      writeReviewBtnC: "#3578e5",
      loadMoreBtnColor: "#3578e5",
    },
    General: {
      ...DEFAULT_VALUES.General,
      avgRatingColor: "#3578e5",
      iconColor: "#3578e5",
      reviewsBg: "#ffffff",
      subBtnColor: "#3578e5",
      textPrimary: "#000000",
      textSecondary: "#a3a3a3",
      verifiedColor: "#3578e5",
      writeReviewBtnC: "#3578e5",
    },
    Header: {
      ...DEFAULT_VALUES.Header,
      avgRatingColor: "#3578e5",
      iconColor: "#3578e5",
      reviewBg: "#ffffff",
      reviewsBg: "#ffffff",
      subBtnColor: "#3578e5",
      subRevBtnTextColor: "#ffffff",
      textPrimary: "#000000",
      textSecondary: "#a3a3a3",
      verifiedColor: "#3578e5",
      writeReviewBtnC: "#3578e5",
      writeReviewBtnTextC: "#ffffff",
    },
  },
  TWITTER_THEME: {
    Body: {
      ...DEFAULT_VALUES.Body,
      avgRatingColor: "#1da0f2",
      reviewBg: "#38444d",
      reviewsBg: "#15202b",
      subBtnColor: "#1da0f2",
      textPrimary: "#000000",
      textSecondary: "#8899a6",
      verifiedColor: "#1da0f2",
      writeReviewBtnC: "#1da0f2",
      iconColor: "#fadb2b",
      loadMoreBtnColor: "#1da0f2",
    },
    General: {
      ...DEFAULT_VALUES.General,
      avgRatingColor: "#1da0f2",
      iconColor: "#1da0f2",
      reviewBg: "#38444d",
      reviewsBg: "#15202b",
      subBtnColor: "#1da0f2",
      textPrimary: "#ffffff",
      textSecondary: "#8899a6",
      verifiedColor: "#1da0f2",
      writeReviewBtnC: "#1da0f2",
    },
    Header: {
      ...DEFAULT_VALUES.Header,
      avgRatingColor: "#1da0f2",
      iconColor: "#fadb2b",
      reviewBg: "#38444d",
      reviewsBg: "#15202b",
      subBtnColor: "#1da0f2",
      subRevBtnTextColor: "#ffffff",
      textPrimary: "#ffffff",
      textSecondary: "#8899a6",
      verifiedColor: "#1da0f2",
      writeReviewBtnC: "#1da0f2",
      writeReviewBtnTextC: "#ffffff",
    },
  },
  YOUTUBE_THEME: {
    Body: {
      ...DEFAULT_VALUES.Body,
      avgRatingColor: "#FF0000",
      iconColor: "#FF0000",
      reviewBg: "#282828",
      reviewsBg: "#121212",
      subBtnColor: "#FF0000",
      textPrimary: "#ffffff",
      textSecondary: "#8899a6",
      verifiedColor: "#FF0000",
      writeReviewBtnC: "#FF0000",
      loadMoreBtnColor: "#FF0000",
    },
    General: {
      ...DEFAULT_VALUES.General,
      avgRatingColor: "#FF0000",
      iconColor: "#FF0000",
      reviewBg: "#282828",
      reviewsBg: "#121212",
      subBtnColor: "#FF0000",
      textPrimary: "#ffffff",
      textSecondary: "#8899a6",
      verifiedColor: "#FF0000",
      writeReviewBtnC: "#FF0000",
    },
    Header: {
      ...DEFAULT_VALUES.Header,
      avgRatingColor: "#FF0000",
      iconColor: "#FF0000",
      reviewBg: "#282828",
      reviewsBg: "#121212",
      subBtnColor: "#FF0000",
      subRevBtnTextColor: "#ffffff",
      textPrimary: "#ffffff",
      textSecondary: "#8899a6",
      verifiedColor: "#FF0000",
      writeReviewBtnC: "#FF0000",
      writeReviewBtnTextC: "#ffffff",
    },
  },
  UFO_GREEN: {
    Body: {
      ...DEFAULT_VALUES.Body,
      iconColor: "#2ED573",
      subBtnColor: "#2ED573",
      writeReviewBtnC: "#2ED573",
      avgRatingColor: "#2ED573",
      verifiedColor: "#2ED573",
      reviewBg: "#ffffff",
      reviewsBg: "#ffffff",
      textPrimary: "#000000",
      textSecondary: "#949494",
      loadMoreBtnColor: "#2ED573",
    },
    General: {
      ...DEFAULT_VALUES.General,
      iconColor: "#2ED573",
      subBtnColor: "#2ED573",
      writeReviewBtnC: "#2ED573",
      avgRatingColor: "#2ED573",
      verifiedColor: "#2ED573",
      reviewBg: "#ffffff",
      reviewsBg: "#ffffff",
      icon: "star",
      flag: true,
      textPrimary: "#000000",
      textSecondary: "#949494",
    },
    Header: {
      ...DEFAULT_VALUES.Header,
      iconColor: "#2ED573",
      subBtnColor: "#2ED573",
      writeReviewBtnC: "#2ED573",
      avgRatingColor: "#2ED573",
      verifiedColor: "#2ED573",
      reviewBg: "#ffffff",
      reviewsBg: "#ffffff",
      icon: "star",
      flag: true,
      textPrimary: "#000000",
      textSecondary: "#949494",
    },
  },
  LOTUS_PINK: {
    Body: {
      ...DEFAULT_VALUES.Body,
      iconColor: "#F368E0",
      subBtnColor: "#F368E0",
      writeReviewBtnC: "#F368E0",
      avgRatingColor: "#F368E0",
      verifiedColor: "#F368E0",
      reviewBg: "#ffffff",
      reviewsBg: "#ffffff",
      textPrimary: "#000000",
      textSecondary: "#949494",
      loadMoreBtnColor: "#F368E0",
    },
    General: {
      ...DEFAULT_VALUES.General,
      iconColor: "#F368E0",
      subBtnColor: "#F368E0",
      writeReviewBtnC: "#F368E0",
      avgRatingColor: "#F368E0",
      verifiedColor: "#F368E0",
      reviewBg: "#ffffff",
      reviewsBg: "#ffffff",
      icon: "star",
      flag: true,
      textPrimary: "#000000",
      textSecondary: "#949494",
    },
    Header: {
      ...DEFAULT_VALUES.Header,
      iconColor: "#F368E0",
      subBtnColor: "#F368E0",
      writeReviewBtnC: "#F368E0",
      avgRatingColor: "#F368E0",
      verifiedColor: "#F368E0",
      reviewBg: "#ffffff",
      reviewsBg: "#ffffff",
      icon: "star",
      flag: true,
      textPrimary: "#000000",
      textSecondary: "#949494",
    },
  },
  WATERMELON: {
    Body: {
      ...DEFAULT_VALUES.Body,
      iconColor: "#FF4757",
      subBtnColor: "#FF4757",
      writeReviewBtnC: "#FF4757",
      avgRatingColor: "#FF4757",
      verifiedColor: "#FF4757",
      reviewBg: "#ffffff",
      reviewsBg: "#ffffff",
      textPrimary: "#000000",
      textSecondary: "#949494",
      loadMoreBtnColor: "#FF4757",
    },
    General: {
      ...DEFAULT_VALUES.General,
      iconColor: "#FF4757",
      subBtnColor: "#FF4757",
      writeReviewBtnC: "#FF4757",
      avgRatingColor: "#FF4757",
      verifiedColor: "#FF4757",
      reviewBg: "#ffffff",
      reviewsBg: "#ffffff",
      icon: "star",
      flag: true,
      textPrimary: "#000000",
      textSecondary: "#949494",
    },
    Header: {
      ...DEFAULT_VALUES.Header,
      iconColor: "#FF4757",
      subBtnColor: "#FF4757",
      writeReviewBtnC: "#FF4757",
      avgRatingColor: "#FF4757",
      verifiedColor: "#FF4757",
      reviewBg: "#ffffff",
      reviewsBg: "#ffffff",
      icon: "star",
      flag: true,
      textPrimary: "#000000",
      textSecondary: "#949494",
    },
  },
  STEAM_WOOD: {
    Body: {
      ...DEFAULT_VALUES.Body,
      listLayout: "zedge",
      loadMoreBtnColor: "#d4a373",
      loadMoreBtnShape: "square",
    },
    General: {
      ...DEFAULT_VALUES.General,
      iconColor: "#ccd5ae",
    },
    Header: {
      ...DEFAULT_VALUES.Header,
      avgRatingColor: "#ccd5ae",
      writeReviewBtnC: "#d4a373",
      writeReviewBtnS: "square",
    },
  },
};

export default defaultThemes;

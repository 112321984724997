import React from "react";

import { PlusOutlined } from "@ant-design/icons";
import { Image, Skeleton, Upload } from "antd";

const ImageStep = ({
  reviewFormData,
  previewOn,
  previewUrl,
  setpreviewOn,
  reviewFormActions,
  setpreviewUrl,
}) => {
  const uploadProps = {
    onChange: reviewFormActions.handleOnChangeFile,
    beforeUpload: reviewFormActions.handleBeforeUploadFile,
    onRemove: reviewFormActions.handleOnRemoveFile,
    onPreview: (file) => {
      setpreviewUrl(file.url ? file.url : file.thumbUrl);
      setpreviewOn(true);
    },
    listType: "picture-card",
    defaultFileList: reviewFormData.fileList,
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div className="upload-container">
      <Upload {...uploadProps}>
        {reviewFormData.fileList.length >= 5 ? null : uploadButton}
      </Upload>
      {previewOn && (
        <Image
          src={previewUrl}
          style={{ display: "none" }}
          alt="preview file"
          preview={{
            visible: previewOn,
            onVisibleChange: (state) => setpreviewOn(state),
          }}
          fallback={Skeleton.Image}
        />
      )}
    </div>
  );
};

export default ImageStep;

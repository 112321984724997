import moment from "moment";
import { isWidgetActive } from "../../../infrastructure/store";

export const createPopUp = (review, settings) => {
  const { displayName, content, date, photos, rating } = review;
  delete settings["changedSettings"];
  const settingstr = Object.keys(settings)
    .map((key) => `&${key}=${settings[key]}`)
    .join("")
    .replace(/#/gi, "%23");

  const url = `/popup?author=${displayName}&imgUrl=${
    photos[0]
  }&star=${rating}&desc=${content}&date=${moment(date).format(
    "DD/MM/YYYY"
  )}${settingstr}`;

  window.parent.postMessage(
    {
      type: "ai-create-review",
      url,
      settings: {
        popupEnabled: settings.popupEnabled,
        popupLayout: settings.popupLayout,
        position: settings.position,
        hideInMobile: settings.hideInMobile,
        loading: settings.loading,
      },
    },
    "*"
  );
};

export const runPopUpGenerator = async (reviews, shopName, settings) => {
  // filtering out reviews with photos
  let filteredReviews = reviews
    .filter((item) => item?.photos?.length)
    .filter((rev) => rev.content.length > 4);

  // no of popups to be created
  let length = filteredReviews.length > 4 ? 4 : filteredReviews.length;

  const isActive = await isWidgetActive(shopName);

  if (length >= 1 && isActive.val()) {
    // create first popup
    createPopUp(filteredReviews[0], settings);

    // starts from 1 since 0th one is already created above
    let count = 1;

    // creates new popup after the previous one is removed
    window.onmessage = (e) => {
      if (e?.data === "ai-close-review") {
        if (count < length) {
          createPopUp(filteredReviews[count], settings);
          count++;
        }
      }
    };
  }
};

import { useAuthData } from "./store";
import { Redirect } from "react-router";
import React, { useEffect } from "react";
import { routes } from "../common/Routes/routes";
import { PrimaryLoadingIndicator } from "../common/UI/LoadingIndicator";
import { isDev } from "../../common/utils";
import { defaultDevShop } from "../../common/constants";
import { getStorageItem } from "../../infrastructure/common/local";
import Login from "./Login";

export const Authenticator = (props) => {
  const { children } = props;

  const [authData, action] = useAuthData();

  const { shopData, isInstalling, billingDetails, isAuthenticating, loggedIn } =
    authData;

  useEffect(() => {
    const checkStore = async () => {
      if (isDev()) {
        action.getShopData(defaultDevShop);
      } else if (await getStorageItem("shop")) {
        //To try session persistance
        action.getShopData(await getStorageItem("shop"));
      } else {
        action.authenticate();
      }
    };

    const isLoggedIn = async () => {
      if (!isDev()) {
        action.setLoggedIn(true);
      } else if (await getStorageItem("loggedIn")) {
        action.setLoggedIn(true);
      } else {
        action.setLoggedIn(false);
      }
    };

    checkStore();
    isLoggedIn();
  }, [action]);

  if (isAuthenticating) {
    return (
      <PrimaryLoadingIndicator
        text="Convincing our AI not to turn evil..."
        isFullPage
      />
    );
  } else {
    if (shopData) {
      if (billingDetails?.subscriptionType) {
        if (loggedIn) {
          return children;
        } else {
          return <Login />;
        }
      } else {
        return <Redirect to={routes.BILLING} />;
      }
    } else if (isInstalling) {
      // Will redirect to shopify...
      return (
        <PrimaryLoadingIndicator text="Installing...Hold on!" isFullPage />
      );
    } else {
      return <Redirect to={routes.ERROR403} />;
    }
  }
};

import { createStore, createHook } from "react-sweet-state";

import actions from "./actions";
import initialState from "./initialState";

const Store = createStore({
  name: "AIREVIEWS-billing-" + window.location.origin,
  actions,
  initialState,
});

export const useBillingData = createHook(Store, {
  selector: (state) => state,
});

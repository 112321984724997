import "./index.scss";
import React from "react";
import AuthorName from "../../components/AuthorName";
import Avatar from "../../components/Avatar";
import { ReviewContent } from "../../components/CardBody";
import Country from "../../components/Country";
import { PlainRating } from "../../components/RatingBadge";
import ReviewDate from "../../components/ReviewDate";
import { textRefactor } from "../../../../../../../../common/Utils/textRefactor";
import ReviewImages from "../../components/ReviewImages";

const ZedgeLayout = (props) => {
  const { setShowModal } = props;

  return (
    <>
      <div className="zedgeBody" onClick={() => setShowModal(true)}>
        <div className="zedgeHeader">
          <Avatar {...props} />
          <div className="zedgeInfo">
            <AuthorName {...props} />
            <ReviewDate {...props} />
          </div>
          <Country {...props} />
        </div>
        <PlainRating {...props} />
        <ReviewContent
          {...{ ...props, content: textRefactor(props?.content, 200) }}
        />
      </div>
      <ReviewImages {...props} />
    </>
  );
};

export default ZedgeLayout;

import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PLANS } from "../../../common/constants";
import { MixpanelEvents } from "../../../common/constants/analytics/events";
import { useAuthData } from "../../Auth/store";
import { AnalyticsContext } from "../../common/Context/AnalyticsContext";
import { Button } from "../../common/UI/buttons/buttons";
import Heading from "../../common/UI/heading/heading";
import { List } from "../../common/UI/pricing/pricing";
import { useCreditsData } from "../../Credits/store";
import { useBillingData } from "../Store";
import { PricingCard, ListGroup, Badge } from "../style";
import { planWords } from "./constants";
import "./index.scss";

const PlanItem = ({ planType }) => {
  const { features, oldPrice, price, recurring } = PLANS[planType];
  const { userType, headingType, badgeType } = planWords[planType];

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  // const [maxFeats, setMaxFeats] = useState(7);
  const [authData, actions] = useAuthData();
  const [, billingActions] = useBillingData();
  const [, { setViewPurchaseModal }] = useCreditsData();
  const { track } = useContext(AnalyticsContext);

  useEffect(() => {
    billingActions?.checkWaitlist(authData?.shopName);
  }, [authData.shopName, billingActions]);

  const handleBilling = () => {
    setLoading(true);
    track(
      MixpanelEvents.BILLING.GET_STARTED(planType, {
        price,
        planType,
        recurring,
      })
    );

    billingActions.subscribe(
      authData.shopName,
      +price,
      actions,
      history,
      planType,
      setLoading,
      recurring
    );
  };

  const isCurrentPlan =
    authData.billingDetails?.subscriptionType === planType ? true : false;
  console.log(authData.billingDetails?.subscriptionType, planType);
  let splitStart = 0,
    featPerSection = 4;

  return (
    <Col>
      <PricingCard
        isHighlighted={planType === "life_time"}
        className="rPlanCard"
        style={{ marginBottom: 30 }}
      >
        <Col className="rPlanCard-left">
          <Col align="middle">
            <Col style={{ minHeight: 75 }} align="bottom" justify="center">
              <Badge className="pricing-badge" type={badgeType}>
                <p>{planType} PLAN</p>
              </Badge>
            </Col>
            {/* <Badge className="pricing-badge" type={badgeType}>
            {badgeText}
          </Badge> */}
            <Heading className={`price-amount ${headingType}`} as="h3">
              <sup className="currency">$ </sup>
              <strike>{oldPrice}</strike>
              {`${price}${!price ? ".0" : ""} `}
              {/* <sub className="pricing-validity">Per month</sub> */}
            </Heading>
            <span
              className="package-user-type"
              style={!userType ? { color: "white" } : {}}
            >
              {userType || "."}
            </span>
            <p className="rPlanCard-badge"></p>
            <p className="credits-badge">
              Credits remaining: {authData?.counters?.credits}
            </p>
          </Col>
          {isCurrentPlan ? (
            <Button
              size="large"
              type="warning"
              onClick={() => {
                track(MixpanelEvents.BILLING.CREDITS_MODAL("Opened"));
                return setViewPurchaseModal(true);
              }}
            >
              Buy Credits
            </Button>
          ) : (
            <Button
              size="default"
              type="warning"
              onClick={handleBilling}
              loading={loading}
              style={{ width: "100%" }}
              className={planType === "life_time" ? "animate" : ""}
            >
              Get Started
            </Button>
          )}
        </Col>

        <ListGroup>
          <Row justify="space-between">
            {Array(parseInt(features.length / featPerSection))
              .fill([])
              .map((_) => {
                splitStart += featPerSection;
                return features.slice(splitStart, splitStart + featPerSection);
              })
              .map((featArray) => (
                <Col style={{ marginBottom: 10 }}>
                  {featArray.map((item, key) => (
                    <List
                      isHighlighted={
                        (planType === "life_time" && [3, 4].includes(key)) ||
                        (planType === "smart" && [0, 5, 6].includes(key))
                      }
                      item={item}
                      key={key}
                    />
                  ))}
                </Col>
              ))}
          </Row>
          {/* {features.slice(0, maxFeats).map((item, key) => (
            <List
              isHighlighted={
                (planType === "life_time" && [3, 4].includes(key)) ||
                (planType === "smart" && [0, 5, 6].includes(key))
              }
              item={item}
              key={key}
            />
          ))} */}
          {/* <p
            className="rPlanCard-showMore color-primary"
            onClick={() => setMaxFeats((prev) => prev + 5)}
          >
            {maxFeats < features.length ? "Show more" : ""}
          </p> */}
        </ListGroup>
      </PricingCard>
    </Col>
  );
};

export default PlanItem;

import { Row, Form, Input, Button } from "antd";
import { useAuthData } from "./store";

const Login = () => {
  const [, { login }] = useAuthData();
  const [loginForm] = Form.useForm();

  return (
    <Row align="middle" justify="center" style={{ minHeight: "100vh" }}>
      <Form
        style={{ width: window.innerWidth <= 991 ? "80%" : "30%" }}
        layout="vertical"
        requiredMark={false}
        scrollToFirstError
        onFinish={(values) => login(values, loginForm.resetFields())}
      >
        <br />
        <h2>Login</h2>
        <Form.Item
          name="username"
          label="Username"
          rules={[{ required: true, message: "Username is required" }]}
        >
          <Input placeholder="Enter email" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: "Password is required" }]}
        >
          <Input.Password placeholder="Type password" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" size="large" type="primary">
            Login
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

export default Login;

import { message } from "antd";
// import config from "../../../common/config";
import { logError } from "../../../common/utils";
import {
  // getPaymentToken,
  processPayment,
  savePaymentData,
} from "../../../infrastructure/payments";
import { getCredits, setCredits } from "../../../infrastructure/store";
import { calculateAmount } from "./helpers";

const actions = {
  setViewPurchaseModal:
    (viewPurchaseModal) =>
    ({ setState }) => {
      setState({ viewPurchaseModal });
    },

  setIsPurchasing:
    (isPurchasing) =>
    ({ setState }) => {
      setState({ isPurchasing });
    },

  setPaymentStatus:
    (paymentStatus) =>
    ({ setState }) => {
      setState({ paymentStatus });
    },

  handleProcessPayment:
    (card, stripe, amount, credits, shopName, values, updateCreditsLocally) =>
    async ({ dispatch }) => {
      if (card && stripe) {
        dispatch(actions.setIsPurchasing(true));
        try {
          const tokenRes = await stripe.createToken(card);
          const data = {
            amount: calculateAmount(amount),
            currency: "usd",
            token: tokenRes.token,
            credits,
            shopName,
            shipping: {
              city: values?.city,
              state: values?.state,
              postalCode: values?.postalCode,
              country: values?.country,
            },
          };
          if (tokenRes.error) {
            message.error(tokenRes.error.message);
            dispatch(actions.setIsPurchasing(false));
          } else if (tokenRes.token) {
            const paymentRes = await processPayment(data);
            if (paymentRes.success) {
              await dispatch(
                actions.handlePaymentSuccess(
                  amount,
                  credits,
                  tokenRes.token.id,
                  shopName,
                  updateCreditsLocally
                )
              );
            } else {
              await dispatch(
                actions.handlePaymentFailure(
                  paymentRes.stripeMessage,
                  amount,
                  credits,
                  tokenRes.token.id,
                  shopName
                )
              );
            }
          } else {
            logError("No token in response", "@ handleProcessPayment");
            message.error("Couldn't initialize payment ! Try again later.");
          }
        } catch (error) {
          logError(error, "@ handleProcessPayment");
          message.error("Couldn't initialize payment ! Try again later.");
        } finally {
          dispatch(actions.setIsPurchasing(false));
        }
      } else {
        logError("Stripe/Card details not found", "@ handleProcessPayment");
        message.error("Couldn't initialize payment ! Try again later.");
      }
    },

  // Payment success handler
  handlePaymentSuccess:
    (amount, credits, paymentToken, shopName, updateCreditsLocally) =>
    async ({ dispatch }) => {
      try {
        // if success add credits to DB
        dispatch(actions.setPaymentStatus("Adding characters"));

        // fetching previous credits
        const _prevCredits = await getCredits(shopName);
        let prevCredits = 0;
        if (_prevCredits.exists()) prevCredits = _prevCredits.val();
        // re-re-checking prevCredits to avoid err
        prevCredits = Number.parseInt(prevCredits);
        if (isNaN(prevCredits)) prevCredits = 0;

        // setting credits
        await setCredits(shopName, prevCredits + credits);
        updateCreditsLocally(prevCredits + credits);
        await savePaymentData(shopName, {
          date: Date.now(),
          credits: credits || 0,
          amount: amount || 0,
          status: "success",
          token: paymentToken,
        });

        message.success(
          `Payment successfull ! ${credits} characters added to your account`
        );
        dispatch(actions.setViewPurchaseModal(false));
      } catch (error) {
        logError(error, "Payment success ! Error in adding characters");
      }
    },

  // payment failure handler
  handlePaymentFailure:
    (stripeMsg, amount, credits, paymentToken, shopName) => async () => {
      try {
        if (stripeMsg) message.error(stripeMsg);
        else message.error(`Payment failed !`);
        await savePaymentData(shopName, {
          date: Date.now(),
          credits: credits || 0,
          amount: amount || 0,
          status: "failed",
          token: paymentToken,
        });
      } catch (error) {
        logError(error, "Error in handling payment failure");
      }
    },
};

export default actions;

import React from "react";
import { Input, Select } from "antd";
import { countries } from "../../../../../../common/constants";
import {
  getCodeByCountry,
  getCountryByCode,
} from "../../../../../common/Utils/getCountries";
import { useReviewFormStore } from "../../../../../common/Stores";

const DetailsStep = ({ reviewFormActions, reviewFormData }) => {
  const [{ loadingData }] = useReviewFormStore();
  return (
    <>
      <Input
        placeholder="Your name"
        className="customInput"
        value={reviewFormData.displayName}
        onChange={({ target }) => {
          reviewFormActions.onDataChange("displayName", target.value);
        }}
      />
      <Input
        type="email"
        placeholder="Your email"
        className="customInput"
        value={reviewFormData.email}
        required={true}
        onChange={({ target }) => {
          reviewFormActions.onDataChange("email", target.value);
        }}
      />
      <Select
        className="customInput"
        showSearch={true}
        loading={loadingData}
        placeholder="Select your country"
        value={getCountryByCode(reviewFormData?.country)}
        onChange={(value) => {
          reviewFormActions.onDataChange("country", getCodeByCountry(value));
        }}
      >
        {countries?.map((item) => (
          <Select.Option key={item.val} value={item.val}>
            {item.val} - {item.key}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default DetailsStep;

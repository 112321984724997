let height;

export const sendPostMessage = (extra) => {
  const padding = extra || 38;
  if (height !== document.getElementById("root").offsetHeight) {
    height = document.getElementById("root").offsetHeight;
    window.parent.postMessage(
      {
        type: "ai-heightChange",
        frameHeight: height + padding,
      },
      "*"
    );
  }
  // let observer = new ResizeObserver((entries) => {
  //   let data = entries[0]?.contentBoxSize?.blockSize;
  //   let data1 = entries[0]?.contentBoxSize?.inlineSize;
  //   // let topHalf =
  //   //   document.getElementsByClassName("average-container")[0]?.scrollHeight;
  //   if (height !== document.getElementById("root").offsetHeight) {
  //     height = document.getElementById("root").offsetHeight;
  //     window.parent.postMessage(
  //       {
  //         type: "ai-heightChange",
  //         frameHeight: data + data1 / 2 - padding,
  //       },
  //       "*"
  //     );
  //   }
  // });

  // observer.observe(document.getElementsByClassName("cardsContainer")[0]);
};

import styled from "styled-components";

export const Popup = styled.div`
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  align-items: center;
  max-width: ${({ isRow }) => (isRow ? `500px` : `200px`)};
  height: ${({ isRow }) => (isRow ? `120px` : `370px`)};
  flex-direction: ${({ states }) => states.popupLayout};
  background-color: ${({ states }) => states.backgroundColor};
  padding: ${({ isRow }) => (isRow ? "15px 15px" : "0")};
  /* transition: all 0.2s ease-in-out; */
  ${({ preview }) => preview && `margin: auto; border: 1px solid #eeeeee;`}
  img {
    width: ${({ isRow }) => (isRow ? "95px" : "200px")};
    height: ${({ isRow }) => (isRow ? "95px" : "185px")};
    border-radius: ${({ isRow }) => (isRow ? "5px" : "0")};
    /* transition: none !important; */
  }
  .popup-img {
    min-height: ${({ isRow }) => (isRow ? "90px" : "100px")};
    min-width: ${({ isRow }) => (isRow ? "60px" : "100%")};
    text-align: center;
  }
  /* .ant-rate {
    > * {
      transition: color 0.1s ease-in-out !important;
    }
  } */
  .ant-rate-star {
    font-size: 16px;
  }
  p {
    margin: 0;
  }
  .popup-head {
    display: flex;
    align-items: center;
    flex-direction: ${({ states }) => states.popupLayout};
    justify-content: space-between;
    width: 100%;
  }
  time {
    ${({ isRow }) => !isRow && "padding-top: 10px"};
    color: ${({ states }) => states.secondaryColor};
  }
  .popup-content {
    /* width: 100%; */
    padding: ${({ isRow }) => (isRow ? "0 15px 0 30px" : "15px 20px")};
    ${({ isRow }) => !isRow && "text-align: center"};
  }
  .popup-msg {
    font-style: italic;
    font-weight: 500;
    padding: 10px 0;
    color: ${({ states }) => states.primaryColor};
  }
  .popup-auth {
    text-align: ${({ isRow }) => (isRow ? "right" : "center")};
    width: 100%;
    color: ${({ states }) => states.secondaryColor};
  }
  @media only screen and (max-width: 700px) {
    width: ${({ isRow, preview }) => preview && (isRow ? `350px` : `200px`)};
  }
`;

import moment from "moment";

const initialState = {
  key: "",
  displayName: "",
  email: "",
  content: "",
  rating: 0,
  date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
  country: "",
  byAi: false,
  approved: null,
  fileList: [],
  uploadable: [],
  isUploading: false,
  settings: {},
  tags: [],
  creatingReview: false,
  deleteImgUrls: [],
  credits: null,
  keywordGenEnabled: null,
  loadingData: true,
};

export default initialState;

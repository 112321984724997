import { SMART } from "./SMART";

export const LIFE_TIME_SMART = {
  name: "Life Time Smart",
  price: 59.99,
  oldPrice: 99.99,
  recurring: true,
  counters: {
    credits: 10000,
    reqEmails: 1000,
  },
  permissions: {
    ...SMART.permissions,
  },
  features: [
    "10000 AI characters/month",
    "Smart review sorting by AI",
    "Smart date updation by AI",
    "AI review writer",
    "AI review replier",
    "1000 Review email requests/month",
  ],
};

export const LIFE_TIME = {
  name: "Life Time",
  price: 59.99,
  oldPrice: 99.99,
  counters: {
    credits: 10000,
    reqEmails: 2000,
  },
  permissions: {
    ...SMART.permissions,
  },
  features: [
    "10000 AI characters/month",
    "Smart review sorting by AI",
    "Smart date updation by AI",
    "AI review writer",
    "AI review replier",
    "1000 Review email requests/month",
  ],
};

import "./index.scss";
import React, { useState } from "react";
import { useReviewWidget } from "../../../store";
import ListLayout from "./layouts/List";
import DynamicLayout from "./layouts/Dynamic";
import GridLayout from "./layouts/Grid";
import ZedgeLayout from "./layouts/Zedge";
import ReviewCardModal from "./components/ReviewCardModal";

const ReviewCard = ({ item }) => {
  const [widgetState] = useReviewWidget();
  const { currentTheme, templates } = widgetState;
  const [showModal, setShowModal] = useState(false);

  const { Body } = templates[currentTheme];

  const childProps = {
    setShowModal,
    templates: templates,
    currentTheme: currentTheme,
    ...{ ...item },
  };
  return (
    <>
      <div
        className={`reviewCard reviewCard-${Body?.listLayout ?? ""}`}
        style={{
          backgroundColor: Body?.reviewBg,
        }}
        // onClick={() => setShowModal(true)}
      >
        {Body?.listLayout === "dynamic" ? (
          <DynamicLayout {...childProps} />
        ) : Body?.listLayout === "grid" ? (
          <GridLayout {...childProps} />
        ) : Body?.listLayout === "list" ? (
          <ListLayout {...childProps} />
        ) : Body?.listLayout === "zedge" ? (
          <ZedgeLayout {...childProps} />
        ) : null}
      </div>
      <ReviewCardModal
        childProps={childProps}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );

  // if (Body?.layout === "dynamic") return <DynamicLayout {...childProps} />;
};

export default ReviewCard;

import LogRocket from "logrocket";
import logError from "../../../common/utils/logError";
import {
  checkStoreStatus,
  getAccessToken,
  getStoreDetails,
  getStoreState,
  setStoreState,
} from "../../../infrastructure/shopify";

import redirectToShopifyInstallation from "./helpers/redirectToShopifyInstallation";
import { stripShopName } from "../../../common/utils/shop";
import { getToken } from "./helpers/installationHelper";
import { setStoreIntroFlag } from "../../../infrastructure/store";
import { message } from "antd";
import { setStorageItem } from "../../../infrastructure/common/local";
import { PLANS, credentials } from "../../../common/constants";

const actions = {
  setIntroStep:
    (introStep) =>
    ({ setState }) => {
      setState({ introStep });
    },

  setShowIntroSteps:
    (showIntroSteps) =>
    ({ setState }) => {
      setState({ showIntroSteps });
    },

  setShopData:
    (shopData) =>
    ({ setState }) => {
      setState({ shopData });
    },

  setRedirect:
    () =>
    ({ setState }) => {
      setState({ redirect: true });
    },

  setIsInstalling:
    (isInstalling) =>
    ({ setState }) => {
      setState({ isInstalling });
    },

  setCounters:
    (counters) =>
    ({ setState }) => {
      setState({ counters });
    },

  setShopName:
    (shopName) =>
    ({ setState }) => {
      setState({ shopName });
    },

  setInstallationParams:
    (installationParams) =>
    ({ setState }) => {
      setState({ installationParams });
    },

  setBillingDetails:
    (billingDetails) =>
    ({ setState }) => {
      setState({
        billingDetails,
        permissions: PLANS[billingDetails?.subscriptionType].permissions,
      });
    },

  setLoggedIn:
    (loggedIn) =>
    ({ setState }) => {
      setState({ loggedIn });
    },

  login:
    (values, reset) =>
    async ({ dispatch }) => {
      try {
        const { username, password } = values;
        if (Object.keys(credentials).includes(username)) {
          if (credentials[username] === password) {
            await setStorageItem("loggedIn", true);
            dispatch(actions.setLoggedIn(true));
            reset && reset();
          } else {
            message.error("Bad credentials");
          }
        } else {
          message.error("Bad credentials");
        }
      } catch (error) {
        logError(error);
      }
    },

  setIsActive:
    (isActive) =>
    ({ setState, getState }) => {
      const { shopName } = getState();
      try {
        setStoreState(shopName, isActive);
        setState({ isActive });
      } catch (error) {
        logError("changing store state", error);
        throw new Error(error);
      }
    },

  getIsActive:
    () =>
    async ({ setState, getState }) => {
      const { shopName } = getState();
      const isActive = await getStoreState(shopName);
      setState({ isActive: isActive.val() });
    },

  install:
    (props) =>
    async ({ setState, dispatch }) => {
      try {
        var path = props.location.search;
        const urlParams = new URLSearchParams(path);
        let params = {
          code: null,
          hmac: null,
          host: null,
          shop: null,
          state: null,
          timestamp: null,
          tokenUpdate: false,
        };

        Object.keys(params).map((item) => {
          params[item] = urlParams.get(item);
          return null;
        });
        const shopName = stripShopName(params["shop"]);
        let tokenPresent = false;
        setStorageItem("shop", shopName); //To try session persistance
        dispatch(actions.setShopName(shopName));
        //call get token fn after setting params
        dispatch(actions.setInstallationParams(params));
        if (params["tokenUpdate"]) {
          tokenPresent = await getAccessToken(params);
        } else {
          tokenPresent = await getToken(params, shopName);
        }
        if (tokenPresent) {
          const shopDetails = await getStoreDetails(shopName);

          if (shopDetails?.exists()) {
            dispatch(actions.setShopData(shopDetails));
          } else {
            logError("Some Error in installing");
          }
        }
      } catch (error) {
        logError(error, "Installing");
      } finally {
        setState({ isInstalling: false });
      }
    },

  authenticate:
    () =>
    async ({ setState, dispatch, getState }) => {
      try {
        let params,
          shopUrl,
          shopName = null;
        const state = getState();

        // This part is done to see if the shopName exists on localstate when you apply for free plan
        if (!state.shopName) {
          params = new URLSearchParams(window.location.search);
          shopUrl = params.get("shop");
          shopName = shopUrl?.split(".myshopify.com")[0];

          if (!shopName) throw new Error("No Shop Name"); // Had to throw error becuase the api is not giving any response...
          dispatch(actions.setShopName(shopName));
          setStorageItem("shop", shopName); //To try session persistance
        }

        const shop = shopName === null ? state.shopName : shopName;

        dispatch(actions.getShopData(shop));
      } catch (e) {
        logError(e, "authenticate");
        setState({ isAuthenticating: false });
      }
    },

  getShopData:
    (shop) =>
    async ({ setState, dispatch }) => {
      setState({ isAuthenticating: true });
      try {
        // single super api call for fetching
        // storeExits, storeDetails and storeSettings
        const res = await checkStoreStatus({ shop });
        const { exists, tokenUpdateredirect } = res;

        if (!tokenUpdateredirect) {
          redirectToShopifyInstallation(shop, "?tokenUpdate=true");
        }

        // if store exists
        if (exists) {
          const {
            data: { storeDetails, storeSettings },
          } = res;
          // if storeDetails is present
          if (storeDetails) {
            dispatch(actions.setShopName(shop));
            dispatch(actions.setShopData(storeDetails));

            const {
              billing: _billingVal,
              counters: _countersVal,
              showIntro: _showIntroVal,
              themeApp: _themeAppVal,
              active: _activeVal,
              aiEnabled: _aiEnabled,
            } = storeSettings;

            if (_billingVal) dispatch(actions.setBillingDetails(_billingVal));
            if (_activeVal) setState({ isActive: _activeVal });
            if (_themeAppVal)
              setState({ isThemeApp: _themeAppVal?.themeAppSupport || false });
            if (_showIntroVal) {
              dispatch(actions.setShowIntroSteps(true));
              dispatch(actions.setIntroStep(0));
            }
            const positiveOrZero = (val) => (val ? (val > 0 ? val : 0) : 0);
            const storeCounters = {
              credits: positiveOrZero(_countersVal.credits),
              reqEmails: positiveOrZero(_countersVal.reqEmails),
            };
            dispatch(actions.setCounters(storeCounters));

            LogRocket.identify(shop, {
              name: shop,
              email: storeDetails?.email,
            });

            setState({
              isAuthenticating: false,
              aiEnabled: _aiEnabled ?? false,
            });
          } else {
            // if shop data is not present, redirect to shopify installation
            setState({ isInstalling: true });
            redirectToShopifyInstallation(shop);
          }
        } else {
          // if the store is not installed go to installation.
          setState({ isInstalling: true });
          redirectToShopifyInstallation(shop);
        }
      } catch (error) {
        logError(error, "authenticate()");
        setState({ isAuthenticating: false });
        message.error(error.toString());
      }
    },

  restartIntro:
    (setloadingRefresh) =>
    async ({ getState, dispatch }) => {
      setloadingRefresh(true);
      const { shopName } = getState();
      try {
        dispatch(actions.setIntroStep(0));
        await setStoreIntroFlag(shopName, true);
        dispatch(actions.setShowIntroSteps(true));
      } catch (error) {
        logError(error, "restartIntro()");
      } finally {
        setloadingRefresh(false);
      }
    },

  completeIntro:
    () =>
    async ({ getState, dispatch }) => {
      const { shopName } = getState();
      try {
        await setStoreIntroFlag(shopName, false);
        dispatch(actions.setShowIntroSteps(false));
      } catch (error) {
        logError(error, "completeIntro()");
      }
    },

  resetCredits:
    (aiCredits) =>
    ({ setState, getState }) => {
      setState({
        counters: {
          ...getState().counters,
          credits: aiCredits > 0 ? aiCredits : 0,
        },
      });
    },
};

export default actions;

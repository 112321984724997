import { createStore, createHook } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";

const Store = createStore({
  name: "AIREVIEWS-popupsettings-" + window.location.origin,
  initialState,
  actions,
});

export const usePopupSettingsData = createHook(Store, {
  selector: (state) => state,
});

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import LogRocket from "logrocket";
import config from "./common/config";
import { routes } from "./presentation/common/Routes";

// LogRocket.init(config.LOGROCKET_APP_ID);
if (
  [routes.POPUP, "/widget", "/homeWidget"].some((route) =>
    window.location.pathname.startsWith(route)
  )
) {
  console.log(`Initiating ${config.LOGROCKET_WIDGET_ID.split("/")?.[1]}`);
  LogRocket.init(config.LOGROCKET_WIDGET_ID);
} else {
  console.log(`Initiating ${config.LOGROCKET_DASH_ID.split("/")?.[1]}`);
  LogRocket.init(config.LOGROCKET_DASH_ID);
}

Bugsnag.start({
  apiKey: config.BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

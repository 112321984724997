import {
  getReviewSettings,
  setReviewSettings,
} from "../../infrastructure/store";

export const applyFilterSettings = async (shopName, newReviewSettings) => {
  const reviewSettingsData = await getReviewSettings(shopName);
  let prevReviewSettings = {};
  if (reviewSettingsData.exists())
    prevReviewSettings = reviewSettingsData.val();
  await setReviewSettings(shopName, {
    ...prevReviewSettings,
    ...newReviewSettings,
  });
};

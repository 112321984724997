import { useContext, useEffect } from "react";
import { useAuthData } from "../Auth/store";
// import { saveToDb } from "./helpers/helper";
import { Redirect } from "react-router";
import { PrimaryLoadingIndicator } from "../common/UI/LoadingIndicator";
import { useBillingData } from "./Store";
import { MixpanelEvents } from "../../common/constants/analytics/events";
import { AnalyticsContext } from "../common/Context/AnalyticsContext";

const BillingCallback = ({ location }) => {
  const [, authActions] = useAuthData();
  const [billingData, billingActions] = useBillingData();
  const { track } = useContext(AnalyticsContext);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const newId = params.get("charge_id");
    const type = params.get("type");

    if (newId) {
      track(MixpanelEvents.BILLING.BILLING_COMPLETE(type, newId));
      billingActions.saveToDb(newId, type, authActions);
    }
  }, [billingActions, location.search, authActions, track]);

  if (billingData.redirect) {
    return <Redirect to="/" />;
  }

  return (
    <PrimaryLoadingIndicator
      text="Hold on! We are finishing things up..Drink some water."
      isFullPage
    />
  );
};

export default BillingCallback;

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import config from "../../common/config";

import PurchaseModal from "./PurchaseModal";

const Credits = () => {
  const stripe = loadStripe(config.STRIPE_API_KEY);

  return (
    <Elements stripe={stripe}>
      <PurchaseModal />
    </Elements>
  );
};

export default Credits;

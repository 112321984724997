import React from "react";
import { Link } from "react-router-dom";
import { LogoIconOrange, LogoTextBlack } from "../../common/Assets/Logo";
import { routes } from "../../common/Routes";
import "./index.scss";

const OnboardingLayout = ({ children }) => {
  return (
    <section className="onboardingLayout">
      <header className="onboardingHeader">
        <Link to={routes.INITIAL}>
          <img src={LogoIconOrange} alt="logo_icon" />
          <img src={LogoTextBlack} alt="logo_text" />
        </Link>
      </header>
      {children}
    </section>
  );
};

export default OnboardingLayout;

import React from "react";
import "./index.scss";
import { WelcomeImageOB } from "../../../common/Assets/Images";
import ObBodyLayout from "../ObBodyLayout";

const WelcomeOB = ({ slider }) => {
  return (
    <ObBodyLayout
      image={<img src={WelcomeImageOB} alt="onboardingImg" />}
      title="Welcome to revyu!"
      nextBtnText="Let's get started"
      nextBtnAction={() => slider?.current?.next()}
    >
      <div className="obWelcome">
        <p>
          Stunning social proof that drives brand growth using AI. Join us and
          make your customers happier than ever!
        </p>
      </div>
    </ObBodyLayout>
  );
};

export default WelcomeOB;

import { primaryDB } from "../common/firebase";
import { del, get, post } from "../common/remote";

export const getAccessToken = (params) => {
  return get("/shopify/getAccessToken", params);
};

export const injectCode = (params) => {
  return get("/injectCode", params);
};

export const checkStoreStatus = (params) => {
  return get("/checkStore", params);
};

export const getSubscription = (params) => {
  return get(`/subscription`, params);
};

export const deleteSubscription = (params) => {
  return del(`/subscription`, params);
};

export const updateBilling = (params) => {
  return post(`/billing`, params);
};

export const oneTimeBilling = (params) => {
  return post("/oneTimeBilling", params);
};

export const getStoreDetails = (shopName) => {
  return primaryDB.ref(`${shopName}/storeDetails`).once("value");
};

export const getBillingDetails = (shopName) => {
  return primaryDB.ref(`${shopName}/storeSettings/billing`).once("value");
};

export const setStoreState = (shopName, newState) => {
  return primaryDB.ref(`${shopName}/storeSettings/active`).set(newState);
};

export const getStoreState = (shopName) => {
  return primaryDB.ref(`${shopName}/storeSettings/active`).once("value");
};

export const postBillingDetails = (shop) => {
  return primaryDB.ref(`${shop}/storeSettings/billing`);
};

export const setAccessToken = (shop, token) => {
  return primaryDB.ref(`${shop}/storeSettings/token`).set(token);
};

import { logError } from "../../../../common/utils";
import { uploadStorageFile } from "../../../../infrastructure/storage";
import {
  getEmailData,
  setEmailData,
  setEmailState,
} from "../../../../infrastructure/store";
import { message as AntMessage } from "antd";
import initialState from "./initialState";
import { sendReviewRequestMail } from "../../../../infrastructure/mail";
import { bannerImage } from "../../../../common/constants";

const actions = {
  alterState:
    (key, value) =>
    ({ setState }) => {
      setState({ [key]: value });
    },

  sendEmail:
    (shopData, shopName) =>
    async ({ setState }) => {
      try {
        setState({ mailing: true });
        const res = await sendReviewRequestMail({
          ...shopData,
          name: shopName,
          productName: "Test Product Name",
        });
        if (res) {
          AntMessage.success(res.message);
        }
      } catch (error) {
        AntMessage.error(
          "Email could not be sent. Try saving your changes and send again."
        );
        logError("error @ sendEmail() action: ", error);
      } finally {
        setState({ mailing: false });
      }
    },

  getEmailData:
    (shopName, shopData) =>
    async ({ setState }) => {
      try {
        const snapshot = await getEmailData(shopName);
        if (snapshot.exists) {
          setState({
            ...initialState,
            displayStoreName: shopName,
            displayMail: shopData?.email,
            ...snapshot.val(),
            uploadable: snapshot.val()?.image || [bannerImage], //Set banner image if no image from db
          });
        } else {
          logError("Review Request Email Data does not exist in DB");
        }
      } catch (error) {
        logError(error, "getEmailData()");
      } finally {
        setState({ isFetchingData: false });
      }
    },

  setEditEnabled:
    (active, shopName) =>
    async ({ setState }) => {
      setState({ emailCheck: true });
      try {
        await setEmailState(shopName, active);
        setState({ active });
      } catch (err) {
        logError("error at setEditEnabled", err);
      } finally {
        setState({ emailCheck: false });
      }
    },

  setUploadable:
    (uploadable) =>
    ({ setState }) => {
      setState({ uploadable });
    },

  validateEmailData:
    () =>
    ({ getState }) => {
      const emailDataKeys = {
        after: "Email After",
        buttonColor: "Button Color",
        buttonText: "Button Text",
        buttonTextColor: "Button Text Color",
        displayMail: "Display Mail",
        displayStoreName: "Display Store Name",
        emailTiming: "Email Timing",
        emailType: "Email Type",
        footer: "Email Footer",
        subject: "Email Subject",
        previewText: "Preview Text",
        message: "Email Message",
      };

      // checking if every value of keys in emailDataKeys are not
      // undefined/null/empty in store
      if (
        Object.keys(emailDataKeys).every((key) => {
          if (
            getState()[key] === undefined ||
            getState()[key] === null ||
            getState()[key] === ""
          ) {
            AntMessage.error(`${emailDataKeys[key]} cannot be left empty !`);
            return false;
          }
          return true;
        })
      ) {
        return true;
      }
      return false;
    },

  saveEmailData:
    (shopName) =>
    async ({ getState, setState, dispatch }) => {
      setState({ isSaving: true });
      const {
        active,
        after,
        buttonColor,
        buttonText,
        buttonTextColor,
        displayMail,
        displayStoreName,
        emailTiming,
        emailType,
        footer,
        image,
        subject,
        previewText,
        message,
        uploadable,
      } = getState();
      try {
        if (dispatch(actions.validateEmailData())) {
          let imgUrl = [];
          if (!uploadable?.[0].src) {
            imgUrl = await uploadStorageFile(
              uploadable[0]?.uid,
              uploadable[0]?.image,
              shopName,
              "reviewReqEmail"
            );
          } else {
            // This else condition works if the user does not change the initial image, so we already have the src and the image name
            imgUrl = uploadable?.[0]?.src;
          }
          let emailData = {
            active,
            after,
            buttonColor,
            buttonText,
            buttonTextColor,
            displayMail,
            displayStoreName,
            emailTiming,
            emailType,
            footer,
            image: [{ src: imgUrl, name: image[0]?.name }],
            subject,
            previewText,
            message,
          };
          console.log({ emailData });
          await setEmailData(shopName, emailData);
          AntMessage.success("Email Data is saved.");
        }
      } catch (error) {
        logError(error, "saveEmailData");
        AntMessage.error("Some error in saving email data");
      } finally {
        setState({ isSaving: false });
      }
    },
};

export default actions;

import React from "react";
import { convertToNameFormat } from "../../../../../helpers/helper";
import "./index.scss";

const AuthorName = (props) => {
  const { displayName, templates, currentTheme } = props;
  const { General, Body } = templates[currentTheme];
  return (
    <div
      className="name"
      style={{
        color: General?.textPrimary,
      }}
    >
      {convertToNameFormat(Body?.custName, displayName)}
    </div>
  );
};

export default AuthorName;

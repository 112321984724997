import { countries } from "./countryNames";

export const settingsConst = {
  filter: {
    rating: {
      defaultValue: 3,
      options: [
        { key: 5, val: "5 stars" },
        { key: 4, val: "4 stars and up" },
        { key: 3, val: "3 stars and up" },
        { key: 2, val: "2 stars and up" },
        { key: 1, val: "1 stars and up" },
      ],
      title: "Rating",
      desc: "Only reviews with this rating setting will be imported",
    },
    content: {
      defaultValue: "both",
      options: [
        { key: "both", val: "Mixed" },
        { key: "image", val: "Reviews with images only" },
        { key: "text", val: "Reviews with text only" },
      ],
      title: "Content",
      desc: "Only reviews with this content setting will be imported",
    },
    countries: {
      defaultValue: "All",
      options: countries,
      title: "Country",
      desc: "Only reviews with this country setting will be imported. You can import reviews that were posted from specific country by choosing it from below",
    },
  },
  auto: {
    name: {
      withSwitch: true,
      isActive: false,
      defaultValue: "US",
      options: countries,
      title: "Name generation",
      desc: "Names on the reviews will be auto generated and overwritten from the country selected below",
    },
    gender: {
      withSwitch: true,
      isActive: false,
      defaultValue: "MX",
      options: [
        { key: "MX", val: "Mixed" },
        { key: "M", val: "Male Only" },
        { key: "F", val: "Female Only" },
      ],
      title: "Gender",
      desc: "Gender proportion of the auto generated names",
    },
    country: {
      withSwitch: true,
      isActive: false,
      defaultValue: "US",
      options: countries,
      title: "Country generation",
      desc: "Country displayed on the review can be overwritten by the below country",
    },
  },
  ai: {
    date: {
      title: "Automatic date updation",
      desc: "The AI algorithm changes the dates of the top few reviews to more recent dates with the top most review being the previous day.",
      type: "switch",
      active: false,
    },
    sorting: {
      title: "Review refresh",
      desc: "The AI algorithm shuffle sorts the reviews to appear fresh and new everyday",
      type: "switch",
      active: false,
    },
    autoUpdate: {
      title: "Automatic review updation",
      desc: "New reviews will be added based on the existing ones by our AI",
      type: "switch",
      active: false,
    },
    keyGen: {
      title: "Generate reviews from keywords",
      desc: "The AI generates reviews using keywords and the star rating selected by the customer.",
      type: "switch",
      active: false,
    },
  },
  autoPublish: {
    criteria: {
      defaultValue: "5",
      options: [
        { key: "all", val: "Publish all automatically" },
        { key: "5", val: "Reviews with 5 stars" },
        { key: "4", val: "Reviews with 4 stars and up" },
        { key: "3", val: "Reviews with 3 stars and up" },
        { key: "no", val: "Don't publish automatically" },
      ],
      title: "Auto Publish",
      desc: "Auto-publishing reviews will be based on this setting.",
    },
  },
};

import axios from "axios";
import config from "../../common/config";
// import { primaryDB } from "../common/firebase";
import { post } from "../common/remote";

export const savePaymentData = (shopName, paymentId, paymentData) => {
  // return primaryDB.ref(`${shopName}/payments/${paymentId}`).set(paymentData);
  return post(`/store/payment`, { shopName, paymentId, data: paymentData });
};

export const getPaymentToken = (data) => {
  return post(`/getPaymentToken`, data);
};

export const processPayment = (data) => {
  return post(`/processPayment`, data);
};

export const convertCurrency = async (fromCurrency, toCurrency) => {
  const query = `${fromCurrency}_${toCurrency}&compact=ultra&apiKey=${config.CURRENCY_CONVERTER_API_KEY}`;
  const res = await axios.get(
    `https://free.currconv.com/api/v7/convert?q=${query}`
  );
  return res?.data[`${fromCurrency}_${toCurrency}`];
};

import initialState from "./initialState";

import {
  getReviewAutoPublishSettings,
  getReviewFilterSettings,
  saveMultipleCSV,
  saveSingleCSV,
} from "../../../../infrastructure/reviews";
import { Alert, message, Modal } from "antd";
import { logError } from "../../../../common/utils";
import {
  checkImportLimitCount,
  getMultipleProductReviewsCount,
  parseFileData,
} from "./helpers";

const actions = {
  setCSVFileList:
    (csvFileList) =>
    ({ setState }) => {
      setState({ csvFileList });
    },

  setImportingCsv:
    (importingCsv) =>
    ({ setState }) => {
      setState({ importingCsv });
    },

  handleAddCSVFile:
    (file) =>
    ({ getState, dispatch }) => {
      const prevData = getState();
      dispatch(actions.setCSVFileList([...prevData.csvFileList, file]));
    },

  handleBeforeUploadCSVFile:
    (file, shopName, permissions, closeModal, productId, productName) =>
    ({ dispatch }) => {
      if (!["application/vnd.ms-excel", "text/csv"].includes(file?.type)) {
        dispatch(actions.setError(`${file.name} is not a csv file`));
      } else {
        dispatch(actions.handleAddCSVFile(file));
        // productId will be null for multiple product review import
        dispatch(
          actions.handleSaveCsvFile(
            file,
            shopName,
            permissions,
            closeModal,
            productId,
            productName
          )
        );
        return false;
      }
    },

  handleRemoveCSVFile:
    (file) =>
    ({ getState, dispatch }) => {
      const prevData = getState();
      const index = prevData.csvFileList.indexOf(file);
      const newFileList = prevData.csvFileList.slice();
      newFileList.splice(index, 1);
      dispatch(actions.setCSVFileList(newFileList));
    },

  handleSaveCsvFile:
    (file, shopName, permissions, closeModal, productId, productName) =>
    async ({ getState, dispatch }) => {
      try {
        dispatch(actions.setImportingCsv(true));
        await dispatch(actions.setSettings());
        const { settings } = getState();

        parseFileData(
          file,
          (msg) => dispatch(actions.setError(msg)),
          settings,
          (newReviews) => {
            // productId will be null for multiple product review import
            if (Object.keys(newReviews)[0] === "PRODUCT_ID") {
              return dispatch(
                actions.setError(
                  "Please replace 'PRODUCT_ID' with an existing product id from your store."
                )
              );
            }

            if (Object.keys(newReviews).length > 0) {
              let confirmTitle = `Are you sure you want to import ${
                productId
                  ? newReviews.length
                  : getMultipleProductReviewsCount(newReviews)
              } reviews to ${
                productId
                  ? "the product " + productName + "?"
                  : Object.keys(newReviews).length + " products"
              }`;

              let confirmContent = checkImportLimitCount(
                newReviews,
                productId,
                permissions
              ) ? (
                ""
              ) : (
                <Alert
                  type="error"
                  showIcon
                  message={
                    "Your current plan allows only maximum 100 reviews import per product! The remaining reviews will be discarded."
                  }
                />
              );

              Modal.confirm({
                title: confirmTitle,
                content: confirmContent,
                okText: "Import",
                centered: true,
                onOk: async () => {
                  // productId will be null for multiple product review import
                  if (productId)
                    await dispatch(
                      actions.saveSingleProductReviews(
                        newReviews,
                        shopName,
                        permissions,
                        productId
                      )
                    );
                  else
                    await dispatch(
                      actions.saveMultipleProductReviews(
                        newReviews,
                        shopName,
                        permissions
                      )
                    );
                  dispatch(actions.setImportingCsv(false));
                  closeModal && closeModal();
                },
                onCancel: () => {
                  dispatch(actions.setError("Cancelled importing reviews!"));
                  dispatch(actions.setImportingCsv(false));
                },
              });
            } else {
              dispatch(
                actions.setError("No reviews to import after applying filters")
              );
            }
          },
          productId
        );
      } catch (error) {
        dispatch(
          actions.setError("Some error occured! Please try again later.")
        );
        logError(error, "@ handleSaveCsvFile()");
      }
    },

  setSettings:
    (shopName) =>
    async ({ setState }) => {
      let autoPub, filters;

      const _autoPub = await getReviewAutoPublishSettings(shopName);
      if (_autoPub.exists()) autoPub = _autoPub.val();

      const _filters = await getReviewFilterSettings(shopName);
      if (_filters.exists()) filters = _filters.val();

      setState({
        settings: {
          autoPub: autoPub?.criteria,
          contentFilter: filters?.content,
          countryFilter: filters?.countries,
          ratingFilter: filters?.rating,
        },
      });
    },

  saveSingleProductReviews:
    (newReviews = [], shopName, permissions, productId) =>
    async () => {
      try {
        const body = {
          newReviews,
          id: productId,
          shopName,
          permissions,
        };
        const response = await saveSingleCSV(body);
        if (response?.success) {
          message.success(
            "Review import in progress. We'll notify you with a mail once we're done !"
          );
          return true;
        } else {
          message.error(response?.message);
          return false;
        }
      } catch (error) {
        logError(error, "saveSingleProductReviews() in CSV import");
        message.error("Error in saving reviews");
        return false;
      }
    },

  saveMultipleProductReviews:
    (newReviews = [], shopName, permissions) =>
    async ({ dispatch }) => {
      try {
        let body = {
          newReviews: { ...newReviews },
          shopName,
          permissions,
        };
        const response = await saveMultipleCSV(body);
        if (response.success === true) {
          dispatch(actions.resetStore());
          message.success(
            "Review import in progress. We'll notify you with a mail once we're done !"
          );
        } else {
          message.error(response?.message);
        }
      } catch (error) {
        logError(error, "saveMultipleProductReviews() in CSV import");
        message.error("Error in importing reviews");
      } finally {
        dispatch(actions.resetStore());
      }
    },

  setError:
    (errMsg) =>
    ({ dispatch }) => {
      dispatch(actions.resetStore());
      message.error(errMsg);
    },

  resetStore:
    () =>
    ({ setState }) => {
      setState({ ...initialState });
    },
};

export default actions;

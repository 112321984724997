import { message as AntMessage } from "antd";
import { logError } from "../../../../common/utils";
import { sendCouponMail } from "../../../../infrastructure/mail";
import {
  getCouponSettings,
  setCouponSettings,
} from "../../../../infrastructure/settings";
import { uploadStorageFile } from "../../../../infrastructure/storage";

const actions = {
  setFormData:
    (key, value) =>
    ({ setState, getState }) => {
      const { formData } = getState();
      setState({ formData: { ...formData, [key]: value } });
    },

  setPreviewDevice:
    (previewDevice) =>
    ({ setState }) => {
      setState({ previewDevice });
    },

  getInitialCouponData:
    (shopName, shopData) =>
    async ({ setState, getState }) => {
      setState({ loading: true });
      const { formData } = getState();
      try {
        const snapshot = await getCouponSettings(shopName);
        if (snapshot.exists()) {
          setState({
            formData: {
              ...formData,
              displayStoreName: shopName,
              displayMail: shopData?.email,
              ...snapshot.val(),
            },
          });
        } else {
          logError("Coupon Email Data does not exist in DB");
        }
      } catch (error) {
        logError(error, "getInitialCouponData()");
      } finally {
        setState({ loading: false });
      }
    },

  sendEmail:
    (shopData, shopName) =>
    async ({ setState }) => {
      try {
        setState({ mailing: true });
        const res = await sendCouponMail({
          ...shopData,
          name: shopName,
          displayName: "Test Customer Name",
          email: shopData?.email,
          isTest: true,
        });
        if (res) {
          AntMessage.success(res.message);
        }
      } catch (error) {
        AntMessage.error(
          "Email could not be sent. Try saving your changes and send again."
        );
        logError("error @ sendEmail() action: ", error);
      } finally {
        setState({ mailing: false });
      }
    },

  saveCouponSettings:
    (shopName) =>
    async ({ setState, getState }) => {
      setState({ saving: true });
      try {
        let state = getState();
        let { formData } = state;
        let imgUrl = "";
        if (formData?.uploadable?.[0]) {
          if (!formData?.uploadable?.[0]?.src) {
            imgUrl = await uploadStorageFile(
              formData?.uploadable[0]?.uid,
              formData?.uploadable[0]?.originFileObj,
              shopName,
              "couponMail"
            );
          } else {
            // This else condition works if the user does not change the initial image, so we already have the src and the image name
            imgUrl = formData?.uploadable?.[0]?.src;
          }
          formData.image = [
            {
              src: imgUrl,
              name: state?.formData?.image[0]?.name,
            },
          ];
        } else {
          formData.image = [""];
        }
        delete formData["uploadable"];
        await setCouponSettings(shopName, formData);
        // await createPriceRule({
        //   shop: shopName,
        //   discountPrice: formData.discount,
        // });
        AntMessage.success("Coupon settings saved.");
      } catch (error) {
        logError(error, "saveCouponSettings()");
        AntMessage.error("Some error in saving coupon settings");
      } finally {
        setState({ saving: false });
      }
    },
};

export default actions;

import React from "react";

const AverageRating = (props) => {
  const { templates, currentTheme, reviews } = props;
  const { General, Header } = templates[currentTheme];

  const total = reviews.length;
  let totalRating = reviews.reduce((acc, item) => acc + item.rating, 0);
  let avgRating = (totalRating / total).toFixed(1);
  //falsey check
  if (isNaN(avgRating)) {
    avgRating = 0;
  }

  return (
    <div className="rating-row">
      <div
        className="avgRatingBox"
        style={{
          backgroundColor: Header?.avgRatingColor,
          borderRadius: `${
            Header?.avgRatingShape === "square" ? "4px" : "50%"
          }`,
        }}
      >
        <span
          style={{
            fontSize: 32,
            fontWeight: 600,
            color: "white",
            margin: 0,
            padding: 0,
          }}
        >
          {avgRating}
        </span>
        <span
          style={{
            fontSize: 12,
            fontWeight: 600,
            color: "white",
            margin: 0,
            padding: 0,
          }}
        >
          {/* out of 5 */}
        </span>
      </div>
      <span
        style={{
          fontSize: 16,
          marginLeft: 18,
          color: General?.textPrimary,
        }}
      >
        Based on {total || 0} reviews
      </span>
    </div>
  );
};
export default AverageRating;

import { message } from "antd";
import moment from "moment";
import { Chance } from "chance";
import { logError } from "../../../../common/utils";
import { isEmpty, resizeFile, shouldApprove } from "./helpers";
import initialState from "./initialState";
import publicIP from "public-ip";
import { badWordsFilter } from "../../Utils/filters";
import { getLocation } from "../../../../infrastructure/location/getLocation";
import { getKeywordsReview } from "../../../../infrastructure/ai/reviews";
import { uploadStorageFile } from "../../../../infrastructure/storage";
import { getCredits } from "../../../../infrastructure/store";
import {
  getReviewAutoGenSettings,
  getReviewAutoPublishSettings,
  getReviewKeyGenSettings,
} from "../../../../infrastructure/reviews";
import { productAiEnabled } from "../../../../infrastructure/products";
import { validateEmail } from "../../Utils/validators";

const chance = new Chance();

const actions = {
  setDeleteImgUrls:
    (deleteImgUrls) =>
    ({ setState }) => {
      setState({ deleteImgUrls });
    },

  setCredits:
    (credits) =>
    ({ setState }) => {
      setState({ credits });
    },

  setKeywordGenEnabled:
    (keywordGenEnabled) =>
    ({ setState }) => {
      setState({ keywordGenEnabled });
    },

  setSettings:
    (shopName, productId, country) =>
    async ({ getState, setState, dispatch }) => {
      setState({ loadingData: true });
      const _credits = await getCredits(shopName);

      if (_credits.exists()) dispatch(actions.setCredits(_credits.val()));
      else dispatch(actions.setCredits(0));

      const _keywordGen = await getReviewKeyGenSettings(shopName);
      if (_keywordGen.exists()) {
        const productKeyWordEnable = await productAiEnabled(
          shopName,
          productId
        );
        // Check for the product individually also
        if (productKeyWordEnable.exists() && productKeyWordEnable.val()) {
          dispatch(actions.setKeywordGenEnabled(_keywordGen.val()));
        }
      } else dispatch(actions.setKeywordGenEnabled(false));

      let autoPub, autoGen;
      const _autoPub = await getReviewAutoPublishSettings(shopName);

      if (_autoPub.exists()) autoPub = _autoPub.val();

      const _autoGen = await getReviewAutoGenSettings(shopName);
      if (_autoGen.exists()) autoGen = _autoGen.val();
      setState({
        settings: {
          autoPub: autoPub?.criteria,
          autoCountry: autoGen?.country?.active
            ? autoGen?.country?.value
            : null,
          autoGender: autoGen?.gender?.active ? autoGen?.gender?.value : null,
          autoName: autoGen?.name?.active ? autoGen?.name?.value : null,
        },
      });
      // generate a country if empty
      if (isEmpty(getState().country))
        if (autoGen?.country?.active) {
          dispatch(actions.onDataChange("country", autoGen?.country?.value));
        } else if (country) {
          dispatch(actions.onDataChange("country", country));
        }
      setState({ loadingData: false });
    },

  setReviewFormData:
    (review, shopName, country, fetchSettings = true) =>
    async ({ setState, getState, dispatch }) => {
      // photos is stored as {url: "", uid: ""} struct
      // for easy managing
      let photos = [];
      if (!isEmpty(review?.photos)) {
        photos = review?.photos?.map((item, i) => ({
          url: item,
          uid: i,
        }));
      }
      setState({ ...review, fileList: photos, uploadable: photos });

      // fetching settings to auto generate data
      fetchSettings &&
        (await dispatch(
          actions.setSettings(shopName, review?.productId, country)
        ));

      // generate a displayName if empty
      if (isEmpty(getState().displayName))
        dispatch(actions.generateDisplayName());

      // resolve invalid date
      if (moment(getState()?.date).format() === "Invalid date")
        dispatch(
          actions.onDataChange(
            "date",
            moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
          )
        );

      if (isEmpty(getState().email)) dispatch(actions.generateEmail());
    },

  onDataChange:
    (type, data) =>
    ({ setState, getState }) => {
      let newData = data;
      if (type === "tags") {
        let prevTags = getState().tags;
        if (data.length > prevTags.length) {
          newData[newData.length - 1] = badWordsFilter(
            newData[newData.length - 1]
          );
        }
      }
      setState({ [type]: newData });
    },

  setIsUploading:
    (isUploading) =>
    ({ setState }) => {
      setState({ isUploading });
    },

  generateReviewBasedOnKeywords:
    (productDetails, shopName) =>
    async ({ getState, setState }) => {
      setState({ creatingReview: true });
      try {
        if (!isEmpty(getState().email)) {
          const { rating, tags, email } = getState();
          const response = await getKeywordsReview({
            ...productDetails,
            tags,
            rating,
            shopName,
            email,
          });
          if (response.success) {
            setState({ content: response?.data?.review?.content });
          } else if (response?.message === "Insufficient characters") {
            message.error("Insufficient characters !");
          } else throw new Error("Could not get review");
        } else {
          message.warning("Provide an email id.");
        }
      } catch (error) {
        logError(error, " @ generateReviewBasedOnKeywords()");
      } finally {
        setState({ creatingReview: false });
      }
    },

  generateDisplayName:
    () =>
    ({ getState, dispatch }) => {
      let name,
        gender = "";
      const settings = getState()?.settings;
      if (settings?.autoGender && settings?.autoGender === "M") gender = "male";
      else if (settings?.autoGender && settings?.autoGender === "F")
        gender = "female";
      try {
        if (gender !== "")
          // if gender is male/female
          name = chance.name({
            nationality: settings?.autoName
              ? settings?.autoName.toLowerCase()
              : "en",
            gender: gender,
          });
        // if gender is MX
        else
          name = chance.name({
            nationality: settings?.autoName
              ? settings?.autoName.toLowerCase()
              : "en",
          });
      } catch (error) {
        // sometimes nationality throws error,
        // in that case name is generated without nationality
        // if gender is male/female
        if (gender !== "") name = chance.name({ gender: gender });
        else name = chance.name();
      }
      dispatch(actions.onDataChange("displayName", name));
    },

  generateEmail:
    () =>
    ({ dispatch }) => {
      try {
        const email = chance.email({ domain: "gmail.com" });
        dispatch(actions.onDataChange("email", email));
      } catch (error) {
        logError(error, " @ generateEmail");
      }
    },

  handleBeforeUploadFile:
    (file) =>
    async ({ getState, dispatch }) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
        return true;
      }
      const isLt4M = file.size / 1024 / 1024 < 4;
      const isLt500KB = file.size / 1024 / 1024 < 0.5;
      if (!isLt4M) {
        message.error("Image must smaller than 4MB!");
        return true;
      }

      // compressing file
      try {
        let newFile;
        if (isLt500KB) {
          newFile = { ...{ image: file, uid: file.uid } };
        } else {
          const image = await resizeFile(file);
          newFile = { ...{ image, uid: file.uid } };
        }
        const newArr = [...getState().uploadable, newFile];
        dispatch(actions.onDataChange("uploadable", newArr));
        return !(isJpgOrPng && isLt4M);
      } catch (err) {
        logError(err);
      }
    },

  handleOnChangeFile:
    ({ fileList: newFileList }) =>
    ({ dispatch }) => {
      dispatch(actions.onDataChange("fileList", newFileList));

      // if (newFileList.length !== 0) {
      //   newFileList[newFileList.length - 1].url = URL.createObjectURL(
      //     newFileList[newFileList.length - 1]?.originFileObj
      //   );
      //   dispatch(actions.onDataChange("fileList", newFileList));
      // } else {
      //   dispatch(actions.onDataChange("fileList", newFileList));
      // }
    },

  handleOnRemoveFile:
    (file) =>
    ({ setState, getState }) => {
      // const { uploadable, deleteImgUrls } = getState();
      const { uploadable, fileList } = getState();

      // Remove from file List
      const indexInFileList = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(indexInFileList, 1);

      // Remove from uploadable list
      const index = uploadable.indexOf(file);
      const newUploadable = uploadable.slice();
      newUploadable.splice(index, 1);
      setState({ uploadable: newUploadable, fileList: newFileList });

      // // checking if the image is from firebase storage
      // if (!isEmpty(file.url) && isEmpty(file.image)) {
      //   // if yes, save the url to delete while saving review
      //   dispatch(actions.setDeleteImgUrls([...deleteImgUrls, file?.url]));
      // }
    },

  // determines whether the review should be auto published
  // uploades the review images
  // generate a review object with review form data and uploaded image urls,
  getReviewFormData:
    (shopName, productId) =>
    async ({ getState, dispatch }) => {
      const storeState = getState();
      if (!isEmpty(getState().content)) {
        if (!isEmpty(getState().email)) {
          if (validateEmail(storeState.email)) {
            // START: applying autopub settings
            let { settings, byAi, approved } = getState();
            if (settings.autoPub)
              approved =
                approved !== null // approved is null, if it's a new review
                  ? approved // if not null, restoring the old 'approved' value
                  : !byAi && // else calculating approved
                    shouldApprove(settings?.autoPub, getState().rating);
            // END: autopub settings

            // START: uploading review images
            const imgUploadPromises = [];
            let photos = [];
            getState().uploadable.forEach((file) => {
              if (!isEmpty(file.image)) {
                // if there are new images to upload
                imgUploadPromises.push(
                  uploadStorageFile(file?.uid, file?.image, shopName, productId)
                );
              } else if (!isEmpty(file.url)) {
                // if there are previously uploaded images
                photos.push(file?.url);
              }
            });

            dispatch(actions.setIsUploading(true));
            try {
              await Promise.all(imgUploadPromises).then((fileURLS) => {
                //Once all the promises are resolved, you will get the urls in a array.
                photos = [...photos, ...fileURLS];
              });
            } catch (error) {
              message.error(error.toString());
              logError(error, "getReviewFormData() image upload");
            } finally {
              dispatch(actions.setIsUploading(false));
            }
            // END: uploading review images

            // START: deleting removed images from Storage
            // const imgDeletePromises = [];
            // // checking if there are any images to be deleted
            // if (getState().deleteImgUrls?.length > 0) {
            //   try {
            //     getState().deleteImgUrls?.map((url) =>
            //       imgDeletePromises.push(deleteStorageFile(url))
            //     );
            //     await Promise.all(imgDeletePromises);
            //     dispatch(actions.setDeleteImgUrls([]));
            //   } catch (error) {
            //     logError(error, "getReviewFormData() deleting removed images");
            //   }
            // }
            // END: deleted removed images from storage

            // // generate a displayName if empty
            // if (isEmpty(getState().displayName))
            //   dispatch(actions.generateDisplayName());

            return {
              key: getState().key || String(Math.random()).split(".")[1],
              displayName: getState().displayName ?? "Anonymous", //Give name as anonymous if no name is provided
              email:
                getState().email ||
                `${String(getState().displayName)
                  .toLowerCase()
                  .replace(" ", "")}@gmail.com`,
              content: getState().content,
              approved: approved,
              rating: getState().rating || 5,
              date:
                getState().date ||
                moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
              photos: photos,
              byAi: byAi,
              country: getState().country || settings.autoCountry,
            };
          } else {
            throw new Error("Please enter a valid email");
          }
        } else {
          throw new Error("Please enter your email");
        }
      } else {
        throw new Error("Please provide a feedback");
      }
    },

  resetState:
    () =>
    ({ setState }) => {
      setState({ ...initialState }); //reset the state
    },

  getCountry:
    () =>
    async ({ dispatch }) => {
      try {
        const ip = await publicIP.v4();
        const response = await getLocation({ ip });
        if (response?.success) {
          dispatch(actions.onDataChange("country", response?.data));
        } else {
          logError("Could not get country details", " @getCountry");
        }
      } catch (error) {
        logError(error, " @ getCountry");
        // message.error(error.toString());
      }
    },
};

export default actions;

import React, { lazy, Suspense } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Authenticator } from "./presentation/Auth/Authenticator";
import { routes } from "./presentation/common/Routes";
import { theme } from "./presentation/common/Theme/themeVariables";
import { PrimaryLoadingIndicator } from "./presentation/common/UI";

//import static css to override antd
import "./index.less";
import "./presentation/common/Style/style.css";
import "./presentation/common/Style/style.scss";

// Layout
import { AppLayout } from "./presentation/Layouts";

// Widget Imports
import Error403 from "./presentation/Error/403";
import Error404 from "./presentation/Error/404";
import RatingOnProduct from "./presentation/Widgets/RatingOnProduct";
import PopUpReviews from "./presentation/Widgets/PopUpReviews";
import HomeReviews from "./presentation/Widgets/HomeReviews";

//component imports
// Outside the suspense fallback
import InstallationCallback from "./presentation/Auth/InstallationCallback";
import BillingCallback from "./presentation/Billing/BillingCallback";
import DisplayReviews from "./presentation/Widgets/DisplayReviews";
import { Billing } from "./presentation/Billing";
import Onboarding from "./presentation/Onboarding";
import AnalyticsContextProvider from "./presentation/common/Context/AnalyticsContext";
// Outside suspense

// Inside Suspense
const Home = React.lazy(() => import("./presentation/Home"));

const Manage = React.lazy(() => import("./presentation/Reviews/Manage"));
const GeneralSettings = React.lazy(() =>
  import("./presentation/Settings/General")
);

const ProductReviews = React.lazy(() =>
  import("./presentation/Reviews/Manage/ProductReviews")
);
const Upgrade = React.lazy(() =>
  import("./presentation/Billing").then((module) => ({
    default: module.Upgrade,
  }))
);

const EmailSettings = React.lazy(() =>
  import("./presentation/UserEngagement/ReviewRequest")
);
const EmailSettingsReviewReq = React.lazy(() =>
  import("./presentation/UserEngagement/ReviewRequest")
);

const CustomizeReviewWidget = React.lazy(() =>
  import("./presentation/Settings/Theme").then((module) => ({
    default: module.ThemeSettings,
  }))
);
const CustomizePopupWidget = React.lazy(() =>
  import("./presentation/Theme/Popup").then((module) => ({
    default: module.PopupCustomization,
  }))
);

const WidgetSettings = React.lazy(() =>
  import("./presentation/Settings/Widgets")
);
// Inside Suspense

const App = () => {
  const SuspenseFallback = (
    <PrimaryLoadingIndicator
      text="Loading...Take a deep breath..."
      isFullPage
    />
  );

  return (
    <ThemeProvider
      theme={{ ...theme, rtl: false, topMenu: false, darkMode: false }}
    >
      <Router>
        <AnalyticsContextProvider>
          <Switch>
            <Route exact path={routes.ERROR403} component={Error403} />
            <Route exact path={routes.ERROR404} component={Error404} />
            <Route
              exact
              path={routes.INSTALLATION_CALLBACK}
              component={InstallationCallback}
            />
            <Route exact path={routes.BILLING} component={Billing} />
            <Route
              exact
              path={routes.BILLINGCALLBACK}
              component={BillingCallback}
            />
            <Route
              exact
              path={routes.DISPLAY_REVIEWS_WIDGET}
              component={DisplayReviews}
            />
            <Route exact path={routes.POPUP} component={PopUpReviews} />
            <Route
              exact
              path={routes.RATING_ON_PRODUCT_WIDGET}
              component={RatingOnProduct}
            />
            <Route exact path={routes.HOME_WIDGET} component={HomeReviews} />
            <Route exact path={routes.ONBOARDING} component={Onboarding} />

            <Route>
              <Authenticator>
                <AppLayout>
                  <Suspense fallback={SuspenseFallback}>
                    <Switch>
                      <Route exact path={routes.DASHBOARD} component={Home} />
                      <Route exact path={routes.UPGRADE} component={Upgrade} />
                      <Route
                        exact
                        path={routes.INTEGRATIONS}
                        component={lazy(() =>
                          import("./presentation/Integrations")
                        )}
                      />
                      <Route
                        exact
                        path={routes.COUPONS}
                        component={lazy(() =>
                          import("./presentation/UserEngagement/Coupons")
                        )}
                      />
                      <Route
                        exact
                        path={routes.REVIEWS_MANAGE}
                        component={Manage}
                      />
                      <Route
                        exact
                        path={routes.REVIEWS}
                        component={ProductReviews}
                      />
                      <Route
                        path={routes.SETTINGS_GENERAL}
                        component={GeneralSettings}
                      />
                      <Route
                        exact
                        path={routes.SETTINGS_WIDGETS}
                        component={WidgetSettings}
                      />
                      <Route
                        exact
                        path={routes.SETTINGS_EMAIL}
                        component={EmailSettings}
                      />
                      <Route
                        exact
                        path={routes.SETTINGS_EMAIL_REVIEWREQ}
                        component={EmailSettingsReviewReq}
                      />
                      <Route
                        exact
                        path={routes.WIDGET_REVIEW_CUSTOMIZE}
                        component={CustomizeReviewWidget}
                      />
                      <Route
                        exact
                        path={routes.WIDGET_POPUP_CUSTOMIZE}
                        component={CustomizePopupWidget}
                      />
                      <Route
                        exact
                        path="*"
                        render={() => <Redirect to={routes.ERROR404} />}
                      />
                    </Switch>
                  </Suspense>
                </AppLayout>
              </Authenticator>
            </Route>
          </Switch>
        </AnalyticsContextProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;

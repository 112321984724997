import { Rate, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { stripShopName } from "../../../common/utils/shop";
import { getAverage } from "../../../infrastructure/products";
import { isWidgetActive } from "../../../infrastructure/store";

const RatingOnProduct = ({ match }) => {
  const [average, setAverage] = useState(null);
  const [shopActive, setShopActive] = useState(null);

  useEffect(() => {
    if (match) {
      checkStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  const checkStore = async () => {
    let { shop_id, product_id } = match.params;
    let shop = stripShopName(shop_id);

    const isShopActive = await isWidgetActive(shop);

    // Check with .exits() and not .val() since the .val() is either true or false
    if (isShopActive.exists()) {
      setShopActive(isShopActive.val());
      getRating(shop, product_id);
    }
  };

  const getRating = async (shop, product_id) => {
    const averageData = await getAverage(shop, product_id);
    if (averageData.exists()) {
      setAverage(averageData.val());
    }
  };

  if (shopActive === null) {
    return (
      <Skeleton.Button active={true} size="small" style={{ width: 200 }} />
    );
  }

  // check active here
  if (!shopActive) {
    return null;
  }

  return (
    <div>
      <Rate
        disabled
        allowHalf
        value={parseFloat(average?.avgRating) || 0}
        style={{ marginRight: 10 }}
      />
      {`(${average?.total || 0})`}
    </div>
  );
};

export default RatingOnProduct;

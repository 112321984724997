// export all common utils from here
import logError from "./logError";

const isDev = () => {
  const devEnv = ["development", "local"];
  return devEnv.includes(getEnv());
};

const getEnv = () => {
  const location = window.location.hostname;
  //separate conditions for local host
  if (location === "localhost") {
    return "local";
  } else {
    //subdomain based env selection
    let subDomain = location.split(".")[0];
    switch (subDomain) {
      case "app":
        return "production";
      case "staging":
        return "staging";
      case "dev":
        return "development";
      default:
        return "development";
    }
  }
};

export { logError, isDev, getEnv };

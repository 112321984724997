export const storeSettings = {
  review: {
    ai: {
      autoUpdate: false,
      date: false,
      sorting: false,
      keyGen: true,
    },
    autoPublish: {
      criteria: "4",
    },
    autoGen: {
      country: {
        active: false,
        value: "US",
      },
      gender: {
        active: false,
        value: "MX",
      },
      name: {
        active: false,
        value: "US",
      },
    },
    filter: {
      content: "both",
      countries: "All",
      rating: 3,
    },
    types: {
      home: false,
      product: true,
      store: false,
      productRating: true,
    },
  },
  theme: {
    current_theme: "DEFAULT_THEME",
  },
};

import React from "react";
import "./index.scss";
import ReactCountryFlag from "react-country-flag";

const Country = (props) => {
  const { templates, currentTheme, country } = props;

  const { Body } = templates[currentTheme];

  if (!Body?.flag) return null;

  return (
    <div className={`flag flag-${Body?.flagShape}`}>
      <ReactCountryFlag svg countryCode={country} />
    </div>
  );
};

export default Country;

import { couponMailVariants } from "../variants/couponMailVariants";

export const couponMailTemplates = {
  A: {
    name: "Grateful",
    subject: "Thank You for Your Review!",
    previewText:
      "As a token of our gratitude, we're sharing a coupon for your next purchase.",
    message: `Dear ${couponMailVariants["Customer Name"]},

Thank you so much for adding a review and a photo of the product you recently purchased from our store. Your feedback is invaluable to us and helps us improve and provide the best possible shopping experience for all of our customers.

As a token of our gratitude, we wanted to share a special coupon with you that can be used on your next purchase from our store. Simply enter the coupon code ${couponMailVariants["Coupon Code"]} at checkout to redeem your discount.

Thank you again for your support and for choosing our store. We hope to see you again soon.

Best regards,
${couponMailVariants["Shop Name"]}`,
  },
  B: {
    name: "Casual",
    subject: " Thanks for the Review!",
    previewText:
      " As a thank you, we're giving you a coupon to use on your next purchase.",
    message: `Hey ${couponMailVariants["Customer Name"]},

Just wanted to say thanks for adding a review and a photo of the product you recently bought from us. Your feedback helps us out a lot and allows us to improve and provide the best possible shopping experience for our customers.

To show our appreciation, we're giving you a coupon to use on your next purchase from our store. Simply enter the code ${couponMailVariants["Coupon Code"]} at checkout to redeem your discount.

Thanks again, and we hope to see you again soon.

Best regards,
${couponMailVariants["Shop Name"]}`,
  },
  C: {
    name: "Professional",
    subject: "Customer Appreciation Coupon",
    previewText:
      "Thank you for your review and photo - as a token of our appreciation, we're sharing a coupon for your next purchase.",
    message: `Work in progressDear ${couponMailVariants["Customer Name"]},

Thank you for taking the time to leave a review and add a photo of the product you recently purchased from our store. Your feedback is valuable to us and helps us improve and provide the best possible shopping experience for all of our customers.

As a token of our appreciation, we would like to share a special coupon with you that can be used on your next purchase from our store. Simply enter the code ${couponMailVariants["Coupon Code"]} at checkout to redeem your discount.

Thank you again for your support and for choosing our store. We hope to see you again soon.

Best regards,
${couponMailVariants["Shop Name"]}`,
  },
  D: {
    name: "Urgent",
    subject: "Don't Miss Out on This Coupon!",
    previewText:
      "Thank you for your review - as a token of our appreciation, we're sharing a coupon for your next purchase. Hurry, this offer won't last long!",
    message: `Hi ${couponMailVariants["Customer Name"]},

Thank you so much for adding a review and a photo of the product you recently purchased from our store. Your feedback is incredibly important to us and helps us improve and provide the best possible shopping experience for all of our customers.

As a token of our appreciation, we wanted to share a special coupon with you that can be used on your next purchase from our store. Simply enter the code ${couponMailVariants["Coupon Code"]} at checkout to redeem your discount. Hurry, this offer won't last long!

Thanks again for your support and for choosing our store. We hope to see you again soon.

Best regards,
${couponMailVariants["Shop Name"]}`,
  },
};

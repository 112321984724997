import { sendPostMessage } from "../../../../common/Utils/postMessage";

export const triggerPostMessage = () => {
  let count = 0;
  let interval = setInterval(() => {
    if (count <= 2) {
      sendPostMessage();
      count = count + 1;
    } else {
      clearInterval(interval);
    }
  }, 1000);
};

export const convertToNameFormat = (format, name = "") => {
  let formattedName = "";
  let splitted = name.split(" ");
  if (name !== "" || name !== null || name !== undefined)
    switch (format) {
      // John Doe -> John Doe
      case "full":
        formattedName = name;
        break;

      // John Doe -> John D
      case "first":
        formattedName = splitted[0] + " ";
        splitted.shift();
        splitted.forEach((item) => (formattedName += item[0] + " "));
        break;

      // John Doe -> J D
      case "short":
        splitted.forEach((item) => (formattedName += item[0] + " "));
        break;

      // John Doe -> J***
      case "encoded":
        formattedName = splitted[0][0] + "*".repeat(splitted[0].length - 1);
        break;

      // John Doe -> J*** D**
      case "fullencoded":
        splitted.forEach(
          (item) =>
            (formattedName += item[0] + "*".repeat(item.length - 1) + " ")
        );
        break;

      default:
        formattedName = name;
        break;
    }
  return formattedName;
};

import Resizer from "react-image-file-resizer";

export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      720,
      720,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export const shouldApprove = (criteria, rating) => {
  switch (criteria) {
    case "all":
      return true;
    case "no":
      return false;
    case "5":
    case "4":
    case "3":
      return rating >= +criteria;
    default:
      return false;
  }
};

export const isEmpty = (item = "") => {
  return item === "" || item === undefined || item === null;
};

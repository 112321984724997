import { message, Modal } from "antd";
import logError from "../../../common/utils/logError";
import { sendCouponMail } from "../../../infrastructure/mail";
import {
  extractProducts,
  getAiProducts,
  getProductDetails,
  getProducts,
  setAiEnabled,
  setAiProducts,
  setProductDetails,
} from "../../../infrastructure/products";
import { readReviews, updateReviewById } from "../../../infrastructure/reviews";

const actions = {
  setProducts:
    (products) =>
    ({ setState }) => {
      setState({ products });
    },

  setLocation:
    (location) =>
    ({ setState }) => {
      setState({ location });
    },

  setAiEnablingId:
    (aiEnablingId) =>
    ({ setState }) => {
      setState({ aiEnablingId });
    },

  setProductDetails:
    (productDetails) =>
    ({ setState }) => {
      setState({ productDetails });
    },

  saveProductDetails:
    (shopName, data, item, setViewProductModal) =>
    async ({ setState, dispatch }) => {
      setState({ savingProductDetails: true });
      try {
        await setProductDetails(shopName, item?.id, data);
        let newProduct = { ...item, details: data };
        dispatch(actions.updateProduct(item?.id, newProduct));
        message.success("Product details saved");
      } catch (error) {
        logError(error, " @ saveProdutDetails()");
        message.error(error.toString());
      } finally {
        setState({ savingProductDetails: false });
        setViewProductModal(false);
      }
    },

  getProductDetails:
    (shopName, productId) =>
    async ({ dispatch }) => {
      try {
        const productDetails = await getProductDetails(shopName, productId);
        if (productDetails.exists())
          dispatch(actions.setProductDetails(productDetails.val()));
      } catch (error) {
        logError(error, " @ saveProdutDetails()");
      }
    },

  enableAiForProduct:
    (shopName, id, isEnabled) =>
    async ({ dispatch, getState }) => {
      try {
        // setting loading for ai switch
        dispatch(actions.setAiEnablingId(id));
        // Get AiProducts array from 000_Settings and save the new array
        let aiProducts = await getAiProducts(shopName);
        let _aiProducts = [];
        if (aiProducts.exists()) {
          _aiProducts = aiProducts.val();
          let productIndex = _aiProducts.indexOf(id);
          if (productIndex > -1) {
            // Remove an item from the list only if we are disabling the switch
            !isEnabled && _aiProducts.splice(productIndex, 1);
          } else {
            // Add an item from the list only if we are enabling the switch
            isEnabled && _aiProducts.push(id);
          }
        } else {
          // If the products array is not present in 000_Settings conditionally create a new array to store
          _aiProducts = isEnabled ? [id] : [];
        }
        await setAiProducts(shopName, _aiProducts);
        // saving ai settings of products to db
        await setAiEnabled(shopName, id, isEnabled);
        // updating products
        let existingProducts = getState().products;
        // Update the product by adding the new aiEnabled value to the object
        let updatedProducts = existingProducts.map((product) => {
          if (product.id === id) {
            return { ...product, aiEnabled: isEnabled };
          } else {
            return product;
          }
        });
        dispatch(
          actions.setProducts(JSON.parse(JSON.stringify(updatedProducts)))
        );
        // disabling loading for ai switch
        dispatch(actions.setAiEnablingId(-1));
      } catch (error) {
        logError(error, ": at enableAiForProduct()");
      }
    },

  updateProduct:
    (id, newProduct) =>
    ({ getState, dispatch }) => {
      const stateProducts = getState().products;
      // Find Index of the product that has to be updated
      const index = stateProducts.findIndex((product) => product.id === id);
      // Change the product details
      stateProducts[index] = { ...stateProducts[index], ...newProduct };
      dispatch(actions.setProducts(JSON.parse(JSON.stringify(stateProducts))));
    },

  setReviewData:
    (reviews) =>
    ({ setState }) => {
      setState({ reviews });
    },

  updateReview:
    (review, key) =>
    ({ setState, getState }) => {
      let newReviews = getState().reviews;
      //update review on the specified index
      if (key !== -1) {
        let reviewIndex = newReviews.findIndex((rev) => rev.key === key);
        newReviews[reviewIndex] = { ...review };
      }
      //using JSON parse and stringify to force rerender UI
      setState({ reviews: JSON.parse(JSON.stringify(newReviews)) });
    },

  deleteReview:
    (key) =>
    ({ setState, getState }) => {
      let { reviews } = getState();

      // Key field is getting removed after the line below, no idea why!
      let newReviews = reviews.filter((review) => review.key !== key);

      // ! Without this, the switch is not getting re rendered
      // TODO Might have to find a better way to solve it.
      setState({ reviews: [] });
      setState({ reviews: JSON.parse(JSON.stringify(newReviews)) });
      message.success("Review deleted !");
    },

  setFetchingLoader:
    (value) =>
    ({ setState }) => {
      setState({ fetchingReviews: value });
    },

  bulkUpdate:
    (newReviews) =>
    ({ setState }) => {
      setState({
        reviews: JSON.parse(JSON.stringify(newReviews)),
        fetchingReviews: false,
      });
    },

  changeSearch:
    (value) =>
    ({ setState }) => {
      setState({
        searchParams: value,
      });
    },

  changeCategory:
    (value) =>
    ({ setState }) => {
      setState({
        activeCategory: value,
      });
    },

  getReviews:
    (shop, id) =>
    async ({ setState, dispatch }) => {
      let reviews = [];
      try {
        reviews = await readReviews(shop, id);
        dispatch(actions.setReviewData(reviews));
        setState({ fetchingReviews: false });
      } catch (error) {
        logError(error, "fetching reviews");
      } finally {
        setState({ fetchingReviews: false });
      }
    },

  getProducts:
    (shopName) =>
    async ({ setState, dispatch }) => {
      let newProducts = [];
      let countOfProducts = 0;
      try {
        const products = await getProducts(shopName);
        if (products.exists()) {
          products.forEach((product) => {
            newProducts.push(product.val());
            if (product.val().average?.total > 0) countOfProducts++;
          });
          dispatch(actions.setProducts(newProducts));
          setState({
            countOfProducts,
          });
        } else {
          message.error("Error fetching products");
        }
      } catch (error) {
        logError(error, "fetching products");
      } finally {
        setState({ fetchingProducts: false });
      }
    },

  scrapeProducts:
    (shopName, setLoading) =>
    async ({ dispatch }) => {
      setLoading(true);
      try {
        const response = await extractProducts({ shop: shopName });
        if (response && response.products) {
          dispatch(
            actions.setProducts(
              // Take Object.values to get all the values of the object recieved as response.
              JSON.parse(JSON.stringify(Object.values(response?.products)))
            )
          );
          message.success("Products Added.");
        } else {
          throw new Error("Error Fetching products");
        }
      } catch (error) {
        message.error(error.toString());
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },

  onSendCoupon:
    (shopData, shopName, productId, reviewId) =>
    async ({ setState, getState, dispatch }) => {
      Modal.confirm({
        centered: true,
        title: "Gift a coupon",
        content:
          "On confirming you are sending a coupon to the author of the review as a token of gratitude which can be used in their next purchase from their store.",
        okText: "Send Coupon",
        onOk: async () => {
          setState({ updatingReview: reviewId });
          try {
            let { reviews } = getState();
            let reviewIndex = reviews.findIndex((rev) => rev.key === reviewId);

            const currentReview = reviews[reviewIndex];
            reviews[reviewIndex] = { ...currentReview, incentivised: true };

            await Promise.all([
              sendCouponMail({
                ...shopData,
                name: shopName,
                displayName: currentReview?.displayName,
                email: currentReview?.email,
                isTest: false,
              }),
              updateReviewById(shopName, productId, reviewId, {
                ...currentReview,
                incentivised: true,
              }),
            ]);

            dispatch(actions.setReviewData(reviews));
            message.success("Coupon sent!");
          } catch (error) {
            console.log(error, "onSendCoupon()");
            message.error("Some error in sending coupon!");
          } finally {
            setState({ updatingReview: null });
          }
        },
      });
    },
};

export default actions;

import { createStore, createHook } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";
import { sweetStoreName } from "../../Utils/sweetStoreName";

const Store = createStore({
  name: sweetStoreName("Integrations"),
  initialState,
  actions,
});

export const useIntegrationStore = createHook(Store, {
  selector: (state) => state,
});

import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import React from "react";
import "./index.scss";

const ReviewReply = (props) => {
  const { reply } = props;
  if (!reply || !reply.length) return null;
  return (
    <div className="content__footer">
      <Avatar
        shape="circle"
        size={"default"}
        style={{
          backgroundColor: "#87d068",
          width: "36px",
          height: "36px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        icon={<UserOutlined />}
      />
      <div className="content__footer_text">
        <h3>Store owners reply:</h3>
        <p>{reply?.[0]?.content}</p>
      </div>
    </div>
  );
};
export default ReviewReply;

import { FREE } from "./FREE";

export const PRO = {
  name: "Pro",
  price: 9.99,
  recurring: true,
  counters: {
    credits: 8000,
    reqEmails: 800,
  },
  permissions: {
    ...FREE.permissions,
    unlimitedReviewImport: true,
    popup: true,
    name: true,
    gender: true,
    country: true,
    reviewReqMail: true,
  },
  features: [
    { title: "8000 Credits / month", desc: "" },
    { title: "200 Review Request emails / month", desc: "" },
    { title: "ꝏ Reviews per import", desc: "" },
    { title: "Popup Reviews", desc: "" },
    { title: "Auto generate while importing reviews", desc: "" },
    { title: "Priority Support", desc: "" },
  ],
};

import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { routes } from "../common/Routes";
import { PrimaryLoadingIndicator } from "../common/UI/LoadingIndicator";
import { useAuthData } from "./store";

// This component is used for shopify installation callback.
const InstallationCallback = (props) => {
  const [authData, action] = useAuthData();
  const { isInstalling } = authData;
  const urlParams = new URLSearchParams(props?.location?.search);
  const tokenUpdate = urlParams.get("tokenUpdate");

  useEffect(() => {
    if (tokenUpdate) action.setIsInstalling(true);
  }, [action, tokenUpdate]);

  useEffect(() => {
    action.install(props);
  }, [action, props]);

  if (isInstalling) {
    return (
      <PrimaryLoadingIndicator text="Loading...This might take a while..Breath in....Breath out..." />
    );
  } else {
    return tokenUpdate ? (
      <Redirect to={routes.DASHBOARD} />
    ) : (
      <Redirect to={routes.BILLING} />
    );
  }
};

export default InstallationCallback;

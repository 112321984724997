import React from "react";

import { HeartFilled, StarFilled } from "@ant-design/icons";
import { Rate } from "antd";

const RatingStep = ({ Header, General, reviewFormData, reviewFormActions }) => {
  return (
    <div className="rating-section">
      <span style={{ color: Header.textSecondary }}>Your Rating: </span>
      <Rate
        allowClear={false}
        defaultValue="5"
        character={General.icon === "heart" ? <HeartFilled /> : <StarFilled />}
        style={{
          color: General.iconColor,
          marginLeft: "5px",
        }}
        value={reviewFormData.rating}
        onChange={(value) => {
          reviewFormActions.onDataChange("rating", value);
        }}
      />
    </div>
  );
};

export default RatingStep;

import styled from "styled-components";

export const CardWrapper = styled.figure`
  background: #fff;
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  img {
    max-width: 48%;
    height: 90%;
    max-height: 216px;
    align-self: center;
  }
  figcaption {
    h2 {
      margin: 18px 0 10px 0;
      font-size: 18px;
      font-weight: 600;
    }
    p {
      line-height: 1.79;
      color: ${({ theme }) => theme["gray-color"]};
    }
    a {
      color: ${({ theme }) => theme["primary-color"]};
      font-size: 15px;
      font-weight: 600;
    }
  }
`;

export const StyledList = styled.ul`
  li {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    ::before {
      content: "•";
      font-size: 30px;
      padding-right: 10px;
      color: #5f63f2;
      line-height: 0;
    }
  }
`;

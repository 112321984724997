import React, { useEffect, useState } from "react";
import { stripShopName } from "../../../common/utils/shop";
import { getAllReviews, isWidgetActive } from "../../../infrastructure/store";
import { PrimaryLoadingIndicator } from "../../common/UI/LoadingIndicator";
import { useReviewWidget } from "../DisplayReviews/ReviewContainer/store";
import ReviewList from "./ReviewList";

const HomeReviews = ({ match }) => {
  const [shopActive, setShopActive] = useState(null);
  const [reviewData, setReviewData] = useState(null);
  const [, widgetActions] = useReviewWidget();

  useEffect(() => {
    if (match) {
      checkStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  const checkStore = async () => {
    let { shop_id } = match.params;
    let shop = stripShopName(shop_id);

    const isShopActive = await isWidgetActive(shop);

    if (isShopActive.val()) {
      widgetActions.getDbTheme(shop);
      widgetActions.getSettings({ shopName: shop });
      let homeReviews = [];
      setShopActive(isShopActive.val());
      const reviews = await getAllReviews(shop);
      const map = new Map(Object.entries(reviews.val()));
      //   Take 2 reviews from every products in the store.
      map.forEach((doc) => {
        homeReviews.push(...doc.slice(0, 2));
      });
      setReviewData(homeReviews);
    }
  };

  if (shopActive === null) {
    return <PrimaryLoadingIndicator />;
  }

  // check active here
  if (!shopActive) {
    return null;
  }

  return <ReviewList reviews={reviewData} />;
};

export default HomeReviews;

import { Tooltip } from "antd";
import React from "react";
import { MixpanelEvents } from "../../../../common/constants/analytics/events";
import { FeatherIcon } from "../../Assets/Icons";
import FloatingButton from "../FloatingButton";
import "./index.scss";

const DevicePreviewButton = ({
  selectedDevice,
  setSelectedDevice = () => {},
  track,
}) => {
  return (
    <FloatingButton className="devicePreviewBtn">
      <Tooltip title="Laptop/Desktop" placement="left">
        <div
          className={`deviceIcon deviceIcon-${
            selectedDevice === "lap" ? "active" : "inactive"
          }`}
          onClick={() => {
            track(
              MixpanelEvents.USER_ENGAGEMENT.TOGGLE_DEVICE("Laptop", "coupon")
            );
            return setSelectedDevice("lap");
          }}
        >
          <FeatherIcon icon="monitor" />
        </div>
      </Tooltip>
      <Tooltip title="Mobile" placement="left">
        <div
          className={`deviceIcon deviceIcon-${
            selectedDevice === "mobile" ? "active" : "inactive"
          }`}
          onClick={() => {
            track(
              MixpanelEvents.USER_ENGAGEMENT.TOGGLE_DEVICE("Mobile", "coupon")
            );
            return setSelectedDevice("mobile");
          }}
        >
          <FeatherIcon icon="smartphone" />
        </div>
      </Tooltip>
    </FloatingButton>
  );
};

export default DevicePreviewButton;

import { logError } from "../../../../common/utils";
import {
  getGenerateIntegration,
  setGenerateIntegration,
} from "../../../../infrastructure/integrations";

const actions = {
  setGenerate:
    (generate) =>
    ({ setState }) =>
      setState({ generate }),

  setLoading:
    (loading) =>
    ({ setState }) =>
      setState({ loading }),

  handleGetIntegrations:
    (shopName) =>
    async ({ dispatch }) => {
      dispatch(actions.setLoading(true));
      try {
        const _generateShot = await getGenerateIntegration(shopName);
        if (_generateShot.exists()) {
          dispatch(actions.setGenerate(_generateShot.val()));
        }
      } catch (error) {
        logError(error, "handleGetIntegrations()");
      } finally {
        dispatch(actions.setLoading(false));
      }
    },

  handleSetIntegrations:
    (shopName, newValue) =>
    async ({ dispatch }) => {
      dispatch(actions.setLoading(true));
      try {
        await setGenerateIntegration(shopName, newValue);
        dispatch(actions.setGenerate(newValue));
      } catch (error) {
        logError(error, "handleSetIntegrations()");
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
};

export default actions;

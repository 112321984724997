import { createStore, createHook } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";
import config from "../../../../common/config";

const Store = createStore({
  name: `Revyu-${config.ENV}-reviewrequest`,
  initialState,
  actions,
});

export const useReviewRequestStore = createHook(Store, {
  selector: (state) => state,
});

import React from "react";
import { Divider, Modal } from "antd";
import "./index.scss";
import { getTime } from "../../../../../../../../common/Utils/getTime";
import { PlainRating } from "../RatingBadge";
import ReviewReply from "../ReviewReply";
import ReviewImages from "../ReviewImages";

const ReviewCardModal = ({ childProps, setShowModal, showModal }) => {
  const { displayName, content, date, reply, templates, currentTheme } =
    childProps;

  const { Body } = templates[currentTheme];

  return (
    <Modal
      open={showModal}
      title="Review"
      footer={null}
      width={500}
      onCancel={() => setShowModal(false)}
      className="customCardModal"
      maskStyle={{ display: "none" }}
      centered
    >
      <div className="modalHolder">
        <div className="content">
          <div className="content__header">
            <div>
              <h2>{displayName}</h2>
              <PlainRating {...childProps} />
            </div>
            <p>
              {getTime(date).format(
                Body?.dateFormat ? Body?.dateFormat : "DD-MM-YYYY"
              )}
            </p>
          </div>
          <div className="content__review">
            <p>{content}</p>
          </div>
          {reply && <Divider type="horizontal" />}
          <ReviewReply {...childProps} />
          <Divider type="horizontal" />
        </div>
        <div className="preview">
          <p>Review Images</p>
          <ReviewImages {...{ ...childProps, displayAll: true }} />
        </div>
      </div>
    </Modal>
  );
};

export default ReviewCardModal;

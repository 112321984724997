import { message } from "antd";
import { calculateAvg } from "../../../../../../common/utils/shop";
import { sendNewReviewMail } from "../../../../../../infrastructure/mail";
import { setAverage } from "../../../../../../infrastructure/products";
import { writeReviews } from "../../../../../../infrastructure/reviews";

export const saveReview = async (
  newReview,
  prevReviews,
  storeFrontData,
  setLoading,
  widgetActions
) => {
  newReview = {
    ...storeFrontData,
    ...{ ...newReview, userReview: true },
  };
  await writeReviews(
    storeFrontData.shopName,
    storeFrontData.productId,
    prevReviews || [],
    [newReview]
  );
  // Calculate Average on adding a new review
  const newAverage = calculateAvg([...(prevReviews || []), newReview]);
  await setAverage(
    storeFrontData.shopName,
    storeFrontData.productId,
    newAverage
  );

  // Save the review to the local state only if the review is approved.
  newReview?.approved && widgetActions.addReview(newReview);

  message.success(
    `${
      newReview?.approved
        ? "Review has been added successfully!"
        : "Review added and it is waiting for approval."
    }`
  );
  setLoading(false);

  await sendNewReviewMail({ ...newReview });
};

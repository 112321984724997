export const countries = [
  { key: "AF", val: "Afghanistan" },
  { key: "AX", val: "\u00c5land Islands" },
  { key: "AL", val: "Albania" },
  { key: "DZ", val: "Algeria" },
  { key: "AS", val: "American Samoa" },
  { key: "AD", val: "Andorra" },
  { key: "AO", val: "Angola" },
  { key: "AI", val: "Anguilla" },
  { key: "AQ", val: "Antarctica" },
  { key: "AG", val: "Antigua and Barbuda" },
  { key: "AR", val: "Argentina" },
  { key: "AM", val: "Armenia" },
  { key: "AW", val: "Aruba" },
  { key: "AU", val: "Australia" },
  { key: "AT", val: "Austria" },
  { key: "AZ", val: "Azerbaijan" },
  { key: "BS", val: "Bahamas" },
  { key: "BH", val: "Bahrain" },
  { key: "BD", val: "Bangladesh" },
  { key: "BB", val: "Barbados" },
  { key: "BY", val: "Belarus" },
  { key: "BE", val: "Belgium" },
  { key: "BZ", val: "Belize" },
  { key: "BJ", val: "Benin" },
  { key: "BM", val: "Bermuda" },
  { key: "BT", val: "Bhutan" },
  { key: "BO", val: "Bolivia, Plurinational State of" },
  { key: "BQ", val: "Bonaire, Sint Eustatius and Saba" },
  { key: "BA", val: "Bosnia and Herzegovina" },
  { key: "BW", val: "Botswana" },
  { key: "BV", val: "Bouvet Island" },
  { key: "BR", val: "Brazil" },
  { key: "IO", val: "British Indian Ocean Territory" },
  { key: "BN", val: "Brunei Darussalam" },
  { key: "BG", val: "Bulgaria" },
  { key: "BF", val: "Burkina Faso" },
  { key: "BI", val: "Burundi" },
  { key: "KH", val: "Cambodia" },
  { key: "CM", val: "Cameroon" },
  { key: "CA", val: "Canada" },
  { key: "CV", val: "Cape Verde" },
  { key: "KY", val: "Cayman Islands" },
  { key: "CF", val: "Central African Republic" },
  { key: "TD", val: "Chad" },
  { key: "CL", val: "Chile" },
  { key: "CN", val: "China" },
  { key: "CX", val: "Christmas Island" },
  { key: "CC", val: "Cocos (Keeling) Islands" },
  { key: "CO", val: "Colombia" },
  { key: "KM", val: "Comoros" },
  { key: "CG", val: "Congo" },
  { key: "CD", val: "Congo, the Democratic Republic of the" },
  { key: "CK", val: "Cook Islands" },
  { key: "CR", val: "Costa Rica" },
  { key: "CI", val: "C\u00f4te d'Ivoire" },
  { key: "HR", val: "Croatia" },
  { key: "CU", val: "Cuba" },
  { key: "CW", val: "Cura\u00e7ao" },
  { key: "CY", val: "Cyprus" },
  { key: "CZ", val: "Czech Republic" },
  { key: "DK", val: "Denmark" },
  { key: "DJ", val: "Djibouti" },
  { key: "DM", val: "Dominica" },
  { key: "DO", val: "Dominican Republic" },
  { key: "EC", val: "Ecuador" },
  { key: "EG", val: "Egypt" },
  { key: "SV", val: "El Salvador" },
  { key: "GQ", val: "Equatorial Guinea" },
  { key: "ER", val: "Eritrea" },
  { key: "EE", val: "Estonia" },
  { key: "ET", val: "Ethiopia" },
  { key: "FK", val: "Falkland Islands (Malvinas)" },
  { key: "FO", val: "Faroe Islands" },
  { key: "FJ", val: "Fiji" },
  { key: "FI", val: "Finland" },
  { key: "FR", val: "France" },
  { key: "GF", val: "French Guiana" },
  { key: "PF", val: "French Polynesia" },
  { key: "TF", val: "French Southern Territories" },
  { key: "GA", val: "Gabon" },
  { key: "GM", val: "Gambia" },
  { key: "GE", val: "Georgia" },
  { key: "DE", val: "Germany" },
  { key: "GH", val: "Ghana" },
  { key: "GI", val: "Gibraltar" },
  { key: "GR", val: "Greece" },
  { key: "GL", val: "Greenland" },
  { key: "GD", val: "Grenada" },
  { key: "GP", val: "Guadeloupe" },
  { key: "GU", val: "Guam" },
  { key: "GT", val: "Guatemala" },
  { key: "GG", val: "Guernsey" },
  { key: "GN", val: "Guinea" },
  { key: "GW", val: "Guinea-Bissau" },
  { key: "GY", val: "Guyana" },
  { key: "HT", val: "Haiti" },
  { key: "HM", val: "Heard Island and McDonald Islands" },
  { key: "VA", val: "Holy See (Vatican City State)" },
  { key: "HN", val: "Honduras" },
  { key: "HK", val: "Hong Kong" },
  { key: "HU", val: "Hungary" },
  { key: "IS", val: "Iceland" },
  { key: "IN", val: "India" },
  { key: "ID", val: "Indonesia" },
  { key: "IR", val: "Iran, Islamic Republic of" },
  { key: "IQ", val: "Iraq" },
  { key: "IE", val: "Ireland" },
  { key: "IM", val: "Isle of Man" },
  { key: "IL", val: "Israel" },
  { key: "IT", val: "Italy" },
  { key: "JM", val: "Jamaica" },
  { key: "JP", val: "Japan" },
  { key: "JE", val: "Jersey" },
  { key: "JO", val: "Jordan" },
  { key: "KZ", val: "Kazakhstan" },
  { key: "KE", val: "Kenya" },
  { key: "KI", val: "Kiribati" },
  { key: "KP", val: "Korea, Democratic People's Republic of" },
  { key: "KR", val: "Korea, Republic of" },
  { key: "KW", val: "Kuwait" },
  { key: "KG", val: "Kyrgyzstan" },
  { key: "LA", val: "Lao People's Democratic Republic" },
  { key: "LV", val: "Latvia" },
  { key: "LB", val: "Lebanon" },
  { key: "LS", val: "Lesotho" },
  { key: "LR", val: "Liberia" },
  { key: "LY", val: "Libya" },
  { key: "LI", val: "Liechtenstein" },
  { key: "LT", val: "Lithuania" },
  { key: "LU", val: "Luxembourg" },
  { key: "MO", val: "Macao" },
  { key: "MK", val: "Macedonia, the Former Yugoslav Republic of" },
  { key: "MG", val: "Madagascar" },
  { key: "MW", val: "Malawi" },
  { key: "MY", val: "Malaysia" },
  { key: "MV", val: "Maldives" },
  { key: "ML", val: "Mali" },
  { key: "MT", val: "Malta" },
  { key: "MH", val: "Marshall Islands" },
  { key: "MQ", val: "Martinique" },
  { key: "MR", val: "Mauritania" },
  { key: "MU", val: "Mauritius" },
  { key: "YT", val: "Mayotte" },
  { key: "MX", val: "Mexico" },
  { key: "FM", val: "Micronesia, Federated States of" },
  { key: "MD", val: "Moldova, Republic of" },
  { key: "MC", val: "Monaco" },
  { key: "MN", val: "Mongolia" },
  { key: "ME", val: "Montenegro" },
  { key: "MS", val: "Montserrat" },
  { key: "MA", val: "Morocco" },
  { key: "MZ", val: "Mozambique" },
  { key: "MM", val: "Myanmar" },
  { key: "NA", val: "Namibia" },
  { key: "NR", val: "Nauru" },
  { key: "NP", val: "Nepal" },
  { key: "NL", val: "Netherlands" },
  { key: "NC", val: "New Caledonia" },
  { key: "NZ", val: "New Zealand" },
  { key: "NI", val: "Nicaragua" },
  { key: "NE", val: "Niger" },
  { key: "NG", val: "Nigeria" },
  { key: "NU", val: "Niue" },
  { key: "NF", val: "Norfolk Island" },
  { key: "MP", val: "Northern Mariana Islands" },
  { key: "NO", val: "Norway" },
  { key: "OM", val: "Oman" },
  { key: "PK", val: "Pakistan" },
  { key: "PW", val: "Palau" },
  { key: "PS", val: "Palestine, State of" },
  { key: "PA", val: "Panama" },
  { key: "PG", val: "Papua New Guinea" },
  { key: "PY", val: "Paraguay" },
  { key: "PE", val: "Peru" },
  { key: "PH", val: "Philippines" },
  { key: "PN", val: "Pitcairn" },
  { key: "PL", val: "Poland" },
  { key: "PT", val: "Portugal" },
  { key: "PR", val: "Puerto Rico" },
  { key: "QA", val: "Qatar" },
  { key: "RE", val: "R\u00e9union" },
  { key: "RO", val: "Romania" },
  { key: "RU", val: "Russian Federation" },
  { key: "RW", val: "Rwanda" },
  { key: "BL", val: "Saint Barth\u00e9lemy" },
  { key: "SH", val: "Saint Helena, Ascension and Tristan da Cunha" },
  { key: "KN", val: "Saint Kitts and Nevis" },
  { key: "LC", val: "Saint Lucia" },
  { key: "MF", val: "Saint Martin (French part)" },
  { key: "PM", val: "Saint Pierre and Miquelon" },
  { key: "VC", val: "Saint Vincent and the Grenadines" },
  { key: "WS", val: "Samoa" },
  { key: "SM", val: "San Marino" },
  { key: "ST", val: "Sao Tome and Principe" },
  { key: "SA", val: "Saudi Arabia" },
  { key: "SN", val: "Senegal" },
  { key: "RS", val: "Serbia" },
  { key: "SC", val: "Seychelles" },
  { key: "SL", val: "Sierra Leone" },
  { key: "SG", val: "Singapore" },
  { key: "SX", val: "Sint Maarten (Dutch part)" },
  { key: "SK", val: "Slovakia" },
  { key: "SI", val: "Slovenia" },
  { key: "SB", val: "Solomon Islands" },
  { key: "SO", val: "Somalia" },
  { key: "ZA", val: "South Africa" },
  { key: "GS", val: "South Georgia and the South Sandwich Islands" },
  { key: "SS", val: "South Sudan" },
  { key: "ES", val: "Spain" },
  { key: "LK", val: "Sri Lanka" },
  { key: "SD", val: "Sudan" },
  { key: "SR", val: "Suriname" },
  { key: "SJ", val: "Svalbard and Jan Mayen" },
  { key: "SZ", val: "Swaziland" },
  { key: "SE", val: "Sweden" },
  { key: "CH", val: "Switzerland" },
  { key: "SY", val: "Syrian Arab Republic" },
  { key: "TW", val: "Taiwan, Province of China" },
  { key: "TJ", val: "Tajikistan" },
  { key: "TZ", val: "Tanzania, United Republic of" },
  { key: "TH", val: "Thailand" },
  { key: "TL", val: "Timor-Leste" },
  { key: "TG", val: "Togo" },
  { key: "TK", val: "Tokelau" },
  { key: "TO", val: "Tonga" },
  { key: "TT", val: "Trinidad and Tobago" },
  { key: "TN", val: "Tunisia" },
  { key: "TR", val: "Turkey" },
  { key: "TM", val: "Turkmenistan" },
  { key: "TC", val: "Turks and Caicos Islands" },
  { key: "TV", val: "Tuvalu" },
  { key: "UG", val: "Uganda" },
  { key: "UA", val: "Ukraine" },
  { key: "AE", val: "United Arab Emirates" },
  { key: "GB", val: "United Kingdom" },
  { key: "US", val: "United States" },
  { key: "UM", val: "United States Minor Outlying Islands" },
  { key: "UY", val: "Uruguay" },
  { key: "UZ", val: "Uzbekistan" },
  { key: "VU", val: "Vanuatu" },
  { key: "VE", val: "Venezuela, Bolivarian Republic of" },
  { key: "VN", val: "Viet Nam" },
  { key: "VG", val: "Virgin Islands, British" },
  { key: "VI", val: "Virgin Islands, U.S." },
  { key: "WF", val: "Wallis and Futuna" },
  { key: "EH", val: "Western Sahara" },
  { key: "YE", val: "Yemen" },
  { key: "ZM", val: "Zambia" },
  { key: "ZW", val: "Zimbabwe" },
];

import { PRO } from "./PRO";
import { EARLY_BIRD } from "./EARLY_BIRD";
import { FREE } from "./FREE";
import { FREEMIUM } from "./FREEMIUM";
import { LIFE_TIME, LIFE_TIME_SMART } from "./LIFE_TIME";
import { PG_SMART_A, PG_SMART_B, PG_SMART_C } from "./PITCHGROUND";
import { SMART } from "./SMART";
import { LEGEND } from "./LEGEND";

export const PLANS = {
  FREE: FREE,
  PRO: PRO,
  SMART: SMART,
  LEGEND: LEGEND,
  EARLY_BIRD: EARLY_BIRD,
  LIFE_TIME_SMART: LIFE_TIME_SMART,
  LIFE_TIME: LIFE_TIME,
  FREEMIUM: FREEMIUM,
  PG_SMART_A: PG_SMART_A,
  PG_SMART_B: PG_SMART_B,
  PG_SMART_C: PG_SMART_C,
};

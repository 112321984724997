const ReviewTypes = {
  USER_WROTE: "user/wrote",
  USER_GEN: "user/gen",
  OWNER_WROTE: "user/owner",
  AI_GEN: "ai/gen",
  IMPORT_ALIEXPRESS: "import/ali",
  IMPORT_AMAZON: "import/amazon",
  IMPORT_CSV: "import/csv",
};

const ReviewTypeColors = {
  [ReviewTypes.USER_GEN]: "blue",
  [ReviewTypes.USER_WROTE]: "blue",
  [ReviewTypes.OWNER_WROTE]: "blue",
  [ReviewTypes.AI_GEN]: "orange",
  [ReviewTypes.IMPORT_ALIEXPRESS]: "green",
  [ReviewTypes.IMPORT_AMAZON]: "green",
  [ReviewTypes.IMPORT_CSV]: "green",
  // [undefined]: "gray",
};

const ReviewTypeTags = {
  [ReviewTypes.USER_GEN]: "User Review",
  [ReviewTypes.USER_WROTE]: "User Review",
  [ReviewTypes.OWNER_WROTE]: "User Review",
  [ReviewTypes.AI_GEN]: "AI Generated",
  [ReviewTypes.IMPORT_ALIEXPRESS]: "Imported",
  [ReviewTypes.IMPORT_AMAZON]: "Imported",
  [ReviewTypes.IMPORT_CSV]: "Imported",
  // [undefined]: "Unknown",
};

export { ReviewTypes, ReviewTypeColors, ReviewTypeTags };

export const FREE = {
  name: "Free",
  price: 0.0,
  recurring: true,
  counters: {
    credits: 4000, // Credits for generating content with AI
    reqEmails: 200, // Emails requesting for a review after a product is purchased
  },
  permissions: {
    unlimitedReviewImport: true,
    popup: true, // Popup reviews
    sorting: true, // Review refresh
    date: true, // Automatic date updation
    autoUpdate: true, // Automatic review updation
    reviewReply: true, // Automatic review reply
    importLimit: 100, // Import limit for reviews
    name: true, // autoGenName
    gender: true, // autoGenGender
    country: true, // autoGenCountry
    generateReview: true, //To generate reviews using Ai
    reviewReqMail: true, //To generate review request email
    writeReviewJurassic: true, //To generate review using Jurassic AI
    keyGen: true, // generate reviews from keywords
  },
  features: [
    { title: "4000 AI Credits", desc: "" },
    { title: "AI Review Generator", desc: "" },
    { title: "AI Review Replier", desc: "" },
    { title: "Auto Date Updation using AI", desc: "" },
    { title: "Auto Review Sorting using AI", desc: "" },
    { title: "Auto generate while importing reviews", desc: "" },
    { title: "Review Generation from keywords", desc: "" },
    { title: "200 Review Request emails / month", desc: "" },
    { title: "Widget Theme Customization", desc: "" },
    { title: "Rich SEO Snippets", desc: "" },
    { title: "Review import filters", desc: "" },
    { title: "Auto publish", desc: "" },
    { title: "Bulk date updater", desc: "" },
    { title: "Bulk country updater", desc: "" },
    { title: "Store Reviews", desc: "" },
    { title: "Popup Reviews", desc: "" },
    { title: "Photo Reviews", desc: "" },
    { title: "Priority Support", desc: "" },
    { title: "ꝏ Reviews per import", desc: "" },
  ],
};

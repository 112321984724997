import { PRO } from "./PRO";
import { FREE } from "./FREE";

export const SMART = {
  name: "Smart",
  price: 14.99,
  recurring: true,
  counters: {
    credits: 15000,
    reqEmails: 2000,
  },
  permissions: {
    ...FREE.permissions,
    ...PRO.permissions,
    sorting: true,
    date: true,
    autoUpdate: true,
    reviewReply: true,
    generateReview: true,
    writeReviewJurassic: true,
    keyGen: true,
  },
  features: [
    { title: "15000 AI Credits / month", desc: "" },
    { title: "1000 Review Request Mails / month", desc: "" },
    { title: "ꝏ Reviews per import", desc: "" },
    { title: "Auto Date Updation using AI", desc: "" },
    { title: "Auto Review Sorting using AI", desc: "" },
    { title: "Review Generation from keywords", desc: "" },
  ],
};

import { alphabeticalSort, numericalSort, timeSort } from "../sorters";
import { filters, sorters } from "./constants";

export const filterReviews = (reviews = [], filter) => {
  let filteredReviews = reviews;
  switch (filter) {
    case filters.APPROVED.value:
      filteredReviews = filteredReviews.filter(
        (data) => data.approved === true
      );
      break;
    case filters.UNAPPROVED.value:
      filteredReviews = filteredReviews.filter(
        (data) => data.approved !== true
      );
      break;
    case filters.NOT_REPLIED.value:
      filteredReviews = filteredReviews.filter(
        (data) => data.reply === undefined || data?.reply?.length === 0
      );
      break;
    case filters.AI_GEN.value:
      filteredReviews = filteredReviews.filter((data) => data.byAi === true);
      break;
    default:
      break;
  }
  return filteredReviews;
};

export const sortReviews = (reviews = [], sorter) => {
  let sortedReviews = reviews;
  switch (sorter) {
    case sorters.AUTHOR.value:
      sortedReviews = sortedReviews.sort((a, b) =>
        alphabeticalSort(a?.displayName, b?.displayName)
      );
      break;
    case sorters.CONTENT.value:
      sortedReviews = sortedReviews.sort((a, b) =>
        alphabeticalSort(a?.content, b?.content)
      );
      break;
    case sorters.DATE.value:
      sortedReviews = sortedReviews.sort((a, b) => -timeSort(a?.date, b?.date));
      break;
    case sorters.RATING.value:
      sortedReviews = sortedReviews.sort(
        (a, b) => -numericalSort(a?.rating, b?.rating)
      );
      break;
    default:
      sortedReviews = sortedReviews.sort((a, b) => -timeSort(a?.date, b?.date));
      break;
  }
  return sortedReviews;
};

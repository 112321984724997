import React from "react";
import "./index.scss";

const DoNowSelect = ({ title, content, isSelected, onClick }) => {
  return (
    <div
      className={`doNowSelect doNowSelect-${
        isSelected ? "selected" : "notSelected"
      }`}
      onClick={onClick}
    >
      <h2>{title}</h2>
      <p>{content}</p>
    </div>
  );
};

export default DoNowSelect;

import defaultThemes from "./constants/defaultThemes";

const initialState = {
  storeFrontData: null,
  loader: true,
  reviews: [],
  currentTheme: "DEFAULT_THEME",
  templates: JSON.parse(JSON.stringify(defaultThemes)),
  widgetActive: false,
  changes: {},
  autoPublish: null,
  writeReview: false,
  pageType: "productPage",
  aiEnabled: null,
  billing: null,
  widgetStatus: null,
  brandFlag: true,
};

export default initialState;

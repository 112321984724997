const initialState = {
  redirect: false,
  billingState: false, //* This is to check if coming for the first time after billing or not.
  joinedWaitlist: null,
  waitList: null,
  applyingCoupon: false,
  couponCode: null,
  couponType: "pitchground",
};

export default initialState;

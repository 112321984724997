import { createStore, createHook } from "react-sweet-state";
import initialState from "./initialState";
import actions from "./actions";

const Store = createStore({
  name: "AIREVIEWS-credits-" + window.location.origin,
  initialState,
  actions,
});

export const useCreditsData = createHook(Store, { selector: (state) => state });

import React from "react";
import "./index.scss";
import { HeartFilled, StarFilled } from "@ant-design/icons";
import { Progress, Rate } from "antd";

const AverageStars = (props) => {
  const { templates, currentTheme, reviews } = props;
  const { General, Header } = templates[currentTheme];

  const totalReviews = reviews.length;

  //get review count for specific rating
  const getCount = (num) => {
    return reviews.filter((obj) => obj.rating === num).length;
  };

  const stars = [5, 4, 3, 2, 1];

  const starValues = stars.map((i) => {
    const number = getCount(i);

    return {
      defaultValue: i,
      percent: totalReviews ? (number / totalReviews) * 100 : 0,
      number,
    };
  });

  const Rating = ({ defaultValue, percent, number }) => (
    <div className="rating-item">
      <Rate
        style={{ color: General?.iconColor, fontSize: 16 }}
        defaultValue={defaultValue}
        disabled
        character={General?.icon === "heart" ? <HeartFilled /> : <StarFilled />}
      />
      <Progress
        percent={percent}
        className="customProgressBar"
        showInfo={false}
        strokeLinecap="square"
        strokeColor={Header?.avgRatingColor}
      />
      <span
        style={{
          marginLeft: 8,
          color: `${number ? Header?.avgRatingColor : General?.textSecondary}`,
        }}
      >
        ({number})
      </span>
    </div>
  );

  return (
    <div className="ratingContMain">
      {starValues.map((args, i) => (
        <Rating {...args} key={i} />
      ))}
    </div>
  );
};

export default AverageStars;

import { logError } from "../../common/utils";
import { primaryStorage } from "../common/firebase";

const validProtocols = ["gs:", "https:"];
const validHostnames = ["primaryStorage.googleapis.com"];

export const uploadStorageFile = async (uid, file, shopName, productId) => {
  return new Promise(async (resolve, reject) => {
    const uploadTask = primaryStorage
      .ref(`images/${shopName}/${productId}/${uid}`)
      .put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => logError(error, "uploadFile()"),
      async () => {
        const imageURL = await uploadTask.snapshot.ref.getDownloadURL();
        resolve(imageURL);
      }
    );
  });
};

export const deleteStorageFile = async (url) => {
  const { hostname, protocol } = new URL(url);
  if (validProtocols.includes(protocol)) {
    // If the protocol is 'gs:' or if it is firebase hostname, continue deleting
    if (protocol === validProtocols[0] || validHostnames.includes(hostname)) {
      const fileRef = primaryStorage.refFromURL(url);
      return fileRef.delete();
    }
  }
};

export const deleteStorageFolder = async (shopName, productId) => {
  const folderRef = primaryStorage.ref(`images/${shopName}/${productId}`);
  const files = await folderRef.listAll();
  const deletePromises = [];
  files.items.forEach((file) => deletePromises.push(file.delete()));
  return Promise.all(deletePromises);
};

export const KeyWords = {
  5: [
    "Great Product",
    "Super Fast Delivery",
    "Great packaging",
    "Awesome Product",
    "Terrific Product",
    "Must buy",
    "Loved it",
  ],
  4: [
    "Good Product",
    "Fast Delivery",
    "Satisfied with the product",
    "Worth the money",
    "Nice product",
    "Valuable product",
  ],
  3: [
    "Average Product",
    "On time delivery",
    "Liked the product",
    "Low quality",
    "Not bad",
    "Improper packaging",
    "Decent product",
  ],
  2: [
    "Bad Product",
    "Slow Delivery",
    "Bad packaging",
    "Not satisfied",
    "Pointless product",
    "Over priced",
  ],
  1: [
    "Worst Product",
    "Damaged product",
    "Disappointment",
    "Waste of money",
    "Poor quality",
  ],
};

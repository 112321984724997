import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";

import config from "../../../common/config";

const { FIREBASE_CONFIG, PRIMARY_DB_URL, SECONDARY_DB_URL } = config;

const primaryApp = firebase.initializeApp(
  { ...FIREBASE_CONFIG, databaseURL: PRIMARY_DB_URL },
  "primaryApp"
);

const secondaryApp = firebase.initializeApp(
  { ...FIREBASE_CONFIG, databaseURL: SECONDARY_DB_URL },
  "secondaryApp"
);

export const primaryDB = firebase.database(primaryApp);
export const secondaryDB = firebase.database(secondaryApp);

export const primaryStorage = firebase.storage(primaryApp);

import logError from "../../../../../common/utils/logError";
import {
  getReviewAutoPublishSettings,
  readReviews,
} from "../../../../../infrastructure/reviews";
import {
  getReviewWidgetData,
  getThemePref,
  isBrandActive,
  isWidgetActive,
  setCurrentTheme,
  setStoreTheme,
} from "../../../../../infrastructure/store";
import { message } from "antd";
import initialState from "./initialState";
import defaultThemes from "./constants/defaultThemes";
import { defaultReviews } from "./constants/defaultReviews";
import { productAiEnabled } from "../../../../../infrastructure/products";
import { getBillingDetails } from "../../../../../infrastructure/shopify";

const actions = {
  setDemoData:
    (reset = false) =>
    ({ setState }) => {
      if (reset) setState({ reviews: [] });
      else setState({ reviews: defaultReviews });
    },

  setPageType:
    (pageType) =>
    ({ setState }) => {
      setState({ pageType });
    },

  getBillingPlan:
    ({ shopName }) =>
    async ({ setState }) => {
      try {
        const _billing = await getBillingDetails(shopName);
        if (_billing.exists()) {
          setState({ billing: _billing.val().subscriptionType });
        }
      } catch (error) {
        logError(error, "@ getBillingPlan");
      }
    },

  checkAiEnabled:
    ({ shopName, productId }) =>
    async ({ setState }) => {
      try {
        const _aiEnabled = await productAiEnabled(shopName, productId);
        if (_aiEnabled.exists()) {
          setState({ aiEnabled: _aiEnabled.val() });
        }
      } catch (error) {
        logError(error, "@ checkAiEnabled");
      }
    },

  setStoreFrontData:
    (storeFrontData) =>
    async ({ setState, dispatch }) => {
      setState({ storeFrontData, loader: true });
      try {
        //fetch reviews from db
        const _getReviews = dispatch(actions.getReviews(storeFrontData));
        // fetch aiEnabled details
        const _checkAi = dispatch(actions.checkAiEnabled(storeFrontData));
        // fetch billing details
        const _getBilling = dispatch(actions.getBillingPlan(storeFrontData));
        //fetch store settings
        const _getSettings = dispatch(actions.getSettings(storeFrontData));
        //fetch widget active on store status
        const _getWidgetStatus = dispatch(
          actions.getWidgetStatus(storeFrontData)
        );
        // fetch brand flag settings
        const _brandFlag = dispatch(actions.getBrandFlagStatus(storeFrontData));

        //resolve all promises and set loader to false
        await Promise.all([
          _getReviews,
          _getSettings,
          _getWidgetStatus,
          _getBilling,
          _checkAi,
          _brandFlag,
        ]);
      } catch (error) {
        logError(error, "widget data set failed");
      } finally {
        setState({ loader: false });
      }
    },

  getAutoPublishSettings:
    (shopName) =>
    async ({ setState }) => {
      try {
        const autoPublish = await getReviewAutoPublishSettings(shopName);
        if (autoPublish.exists()) {
          setState({ autoPublish: autoPublish.val() });
        } else {
          logError("autoPublish value not found", "getAutoPublishSettings()");
        }
      } catch (error) {
        logError("error", "getAutoPublishSettings()");
      }
    },

  addReview:
    (newReview) =>
    ({ getState, setState }) => {
      setState({
        reviews: [JSON.parse(JSON.stringify(newReview)), ...getState().reviews],
      });
    },

  getReviews:
    ({ shopName, productId }) =>
    async ({ setState }) => {
      try {
        const reviews = await readReviews(shopName, productId);
        if (reviews) {
          //only show reviews that are approved
          const approvedReviews = reviews?.filter((item) => item.approved);
          //reversing data to show latest first
          setState({ reviews: approvedReviews });
        } else {
          setState({ reviews: [] });
        }
      } catch (error) {
        logError(error, "error fetching reviews");
      }
    },

  getSettings:
    ({ shopName }) =>
    async ({ setState }) => {
      try {
        const resData = await getThemePref(shopName);

        const themeSettings = resData.val();
        if (themeSettings) {
          //set current theme from db. omititng custom theme settings for now
          setState({ currentTheme: themeSettings.current_theme });
        }
      } catch (error) {
        logError(error, "error fetching theme settings");
      }
    },

  getWidgetStatus:
    ({ shopName }) =>
    async ({ setState }) => {
      try {
        const resData = await isWidgetActive(shopName);
        const widgetActive = resData.val();
        setState({ widgetActive });
      } catch (error) {
        logError(error, "error fetching theme settings");
      }
    },

  getBrandFlagStatus:
    ({ shopName }) =>
    async ({ setState }) => {
      try {
        const resData = await isBrandActive(shopName);
        const brandFlag = !resData.exists() ? true : resData.val();
        setState({ brandFlag });
      } catch (error) {
        logError(error, "error fetching brand flag settings");
      }
    },

  getDbTheme:
    (shopName) =>
    async ({ dispatch, getState, setState }) => {
      setState({ loader: true });
      const { templates } = getState();
      try {
        let theme = await getThemePref(shopName);

        if (theme?.exists && theme?.val()?.customTheme) {
          let { customTheme } = theme.val();
          let newTemplate = templates;

          // eslint-disable-next-line array-callback-return
          Object.entries(customTheme).map((data) => {
            // eslint-disable-next-line array-callback-return
            Object.entries(data[1]).map((doc) => {
              newTemplate[data[0]][doc[0]] = {
                ...newTemplate[data[0]][doc[0]],
                ...doc[1],
              };
            });
          });

          dispatch(
            actions.setTemplate(JSON.parse(JSON.stringify(newTemplate)))
          );
          setState({ changes: customTheme });
        }
      } catch (error) {
        logError(error, "While getting theme from DB");
      } finally {
        setState({ loader: false });
      }
    },

  setTemplate:
    (templates) =>
    ({ setState }) => {
      setState({ templates });
    },

  setCurrentTheme:
    (currentTheme) =>
    ({ setState }) => {
      setState({ currentTheme });
    },

  manipulateData:
    (DefaultTheme, Type, key, value) =>
    ({ getState, setState }) => {
      const { templates, changes } = getState();
      let newTemplate = { ...templates };

      // To change the value on the store front
      newTemplate[DefaultTheme][Type][key] = value;

      // To get the changed value to store in db
      let newChanges = changes;

      if (newChanges[DefaultTheme]) {
        if (newChanges[DefaultTheme][Type]) {
          if (newChanges[DefaultTheme][Type][key]) {
            newChanges[DefaultTheme][Type][key] = value;
          } else {
            newChanges[DefaultTheme][Type] = {
              ...newChanges[DefaultTheme][Type],
              ...{ [key]: value },
            };
          }
        } else {
          newChanges[DefaultTheme] = {
            ...newChanges[DefaultTheme],
            ...{ [Type]: { [key]: value } },
          };
        }
      } else {
        newChanges = {
          ...{ [DefaultTheme]: { [Type]: { [key]: value } } },
        };
      }

      setState({ templates: newTemplate, changes: newChanges });
    },

  saveStoreTheme:
    (shopName) =>
    async ({ getState }) => {
      const { changes, currentTheme } = getState();
      const isVerfiedFields = [];
      Object.keys(changes).forEach((theme) =>
        Object.keys(changes[theme]).forEach((position) =>
          Object.keys(changes[theme][position]).forEach((settings) => {
            const value = changes[theme][position][settings];
            isVerfiedFields.push(
              value === "" || value === null || value === undefined
                ? false
                : true
            );
          })
        )
      );
      if (isVerfiedFields.every((i) => i)) {
        await setStoreTheme(shopName, changes);
        await setCurrentTheme(shopName, currentTheme);
        message.success("New theme saved.");
      } else {
        message.error("Fields cannot be left empty!");
      }
    },

  resetTheme:
    () =>
    ({ setState, dispatch }) => {
      let newState = {
        ...initialState,
        templates: JSON.parse(JSON.stringify(defaultThemes)),
        loader: false,
        changes: {},
      };
      setState({
        ...newState,
      });
      dispatch(actions.setDemoData());
      message.success("Theme is reset!");
    },

  setWriteReview:
    (writeReview) =>
    ({ setState }) => {
      setState({ writeReview });
    },

  getWidgetData:
    (shopName) =>
    async ({ setState }) => {
      try {
        const _rawWidgetData = await getReviewWidgetData(shopName);
        if (_rawWidgetData.exists()) {
          setState({
            widgetStatus: { ..._rawWidgetData.val() },
          });
        } else {
          setState({
            widgetStatus: {
              home: false,
              store: false,
              product: false,
            },
          });
          logError("Review widget data is not present in the db");
        }
      } catch (error) {
        logError(error, " at getWidgetData()");
      } finally {
        setState({ loading: false });
      }
    },
};

export default actions;

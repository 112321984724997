// export all icons from here
import rightIcon from "./right.svg";
import leftIcon from "./left.svg";
import user_engagement from "./user_engagement.svg";
import FeatherIcons from "feather-icons-react";

const FeatherIcon = ({
  icon = "square",
  className = "",
  size = 18,
  style = {},
  onClick = () => {},
}) => {
  const customIcons = {
    custom_user_engagement: user_engagement,
    custom_right_icon: rightIcon,
    custom_left_icon: leftIcon,
  };

  if (Object.keys(customIcons).includes(icon)) {
    return (
      <img
        style={{ height: "15px", marginRight: "10px" }}
        src={customIcons[icon]}
        alt={icon}
      />
    );
  }

  return (
    <FeatherIcons
      icon={icon}
      size={size}
      className={className}
      style={style}
      onClick={onClick}
    />
  );
};

export { leftIcon, rightIcon, FeatherIcon };

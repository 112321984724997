import React, { useEffect } from "react";
import "./index.scss";

const BlurLoader = ({ tip = "", loading }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <div
      className={`revyu-blur-loader revyu-blur-loader-${
        loading ? "loading" : "not-loading"
      }`}
    >
      <div className="revyu-blur"></div>
      {/* <Spin size="large" /> */}
      <span class="loader"></span>
      <p>{tip}</p>
    </div>
  );
};

export default BlurLoader;

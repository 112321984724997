import { couponMailVariants, rrMailVariants } from "../../../common/constants";

export const validateEmail = (email = "") => {
  const validEmailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return email.match(validEmailRegex);
};

export const validateVariants = (newValue = "", variantType = "") => {
  let finalValue = "",
    isInsideVariant = false,
    currVariant = "",
    variants = [];

  if (variantType === "rrMail") variants = Object.values(rrMailVariants);
  else if (variantType === "couponMail")
    variants = Object.values(couponMailVariants);

  newValue.split("").forEach((_, i) => {
    let newChar = newValue[i];
    if (newChar === "{") {
      isInsideVariant = true;
      currVariant = "";
    } else if (newChar === "}") {
      if (variants.includes(`{${currVariant}}`)) {
        finalValue += `{${currVariant}}`;
        isInsideVariant = false;
        currVariant = "";
      } else {
        isInsideVariant = false;
        currVariant = "";
      }
    } else {
      if (variants.includes(`{${currVariant}}`)) {
        isInsideVariant = false;
        currVariant = "";
      } else if (!isInsideVariant) {
        finalValue += newChar;
      } else {
        currVariant += newChar;
      }
    }
  });
  return finalValue;
};

import { secondaryDB } from "../common/firebase";
import { post } from "../common/remote";

const couponsDataRef = secondaryDB.ref(`000_coupons`);

export const getCoupon = (type, code) =>
  couponsDataRef.child(`${type}/${code}`).once("value");
export const useCoupon = (type, code, data) =>
  post(`/store/coupons`, { type, code, data });
// couponsDataRef.child(`${code}`).set(data);

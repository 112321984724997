// import sizeof from 'object-sizeof'
import { createStore, createHook, defaults } from "react-sweet-state";
import { sweetStoreName } from "../../common/Utils/sweetStoreName";

// import trackEvent from 'lib/trackEvent'

import actions from "./actions";
import initialState from "./initialState";

defaults.devtools = true;

const Store = createStore({
  name: sweetStoreName("Auth"),
  initialState,
  actions,
});

// const logger = (storeState) => (next) => (arg) => {
//   const result = next(arg)
//   trackEvent(
//     trackEvent.modules.redux,
//     trackEvent.events.redux_action,
//     {
//       name: storeState?.key,
//       action_name: storeState?.mutator?.actionName,
//       payload_size: sizeof(arg),
//     },
//     true
//   )
//   return result
// }

// defaults.middlewares.add(logger)

export const useAuthData = createHook(Store, {
  selector: (state) => state,
});

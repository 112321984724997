import "./index.scss";
import React, { useContext } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Col, Form, Input, InputNumber, Modal, Row } from "antd";
import { useCreditsData } from "./store";
import { useAuthData } from "../Auth/store";
import { useState } from "react";
import { creditManual } from "../../common/constants/credits";
import { getCountryByCode } from "../common/Utils/getCountries";
import { AnalyticsContext } from "../common/Context/AnalyticsContext";
import { MixpanelEvents } from "../../common/constants/analytics/events";

const PurchaseModal = () => {
  const [
    { viewPurchaseModal, isPurchasing },
    { setViewPurchaseModal, handleProcessPayment },
  ] = useCreditsData();
  const [{ shopData }, { resetCredits }] = useAuthData();

  const { track } = useContext(AnalyticsContext);

  const [amount, setamount] = useState(
    (creditManual.minCredits * creditManual.costPerCredit).toFixed(2)
  );
  const [purchaseCredits, setpurchaseCredits] = useState(
    creditManual.minCredits
  );

  const stripe = useStripe();
  const elements = useElements();

  const handleSetpurchaseCredits = (credits) => {
    setpurchaseCredits(credits);
    setamount((credits * creditManual.costPerCredit).toFixed(2));
  };

  return (
    <Modal
      className="purchaseModal"
      title="Purchase Credits"
      open={viewPurchaseModal}
      footer={null}
      onCancel={() => {
        track(MixpanelEvents.BILLING.CREDITS_MODAL("Closed"));
        return setViewPurchaseModal(false);
      }}
      centered
      maskClosable={false}
    >
      <Form
        className="purchaseCredits"
        layout="vertical"
        requiredMark={false}
        onFinish={(values) => {
          track(
            MixpanelEvents.BILLING.CREDITS_PURCHASE((purchaseCredits, amount), {
              amount,
              purchaseCredits,
            })
          );
          return handleProcessPayment(
            elements.getElement(CardElement),
            stripe,
            amount,
            purchaseCredits,
            shopData?.name,
            { ...values, country: shopData?.country },
            resetCredits
          );
        }}
      >
        <div className="purchaseCredits-amntWrap">
          <div className="purchaseCredits-amnt">
            <p>Amount Payable</p>
            <h3>
              $ <span>{amount}</span>
            </h3>
          </div>
        </div>
        <Form.Item
          validateStatus={
            purchaseCredits === null ||
            purchaseCredits < creditManual.minCredits
              ? "error"
              : "success"
          }
          help={
            (purchaseCredits === null ||
              purchaseCredits < creditManual.minCredits) &&
            `Minimum ${creditManual.minCredits} credits`
          }
          label="Enter no of credits"
          initialValue={purchaseCredits}
          rules={[
            {
              required: true,
              message: `You need to purchase minimum ${creditManual.minCredits} credits`,
            },
          ]}
        >
          <InputNumber
            disabled={isPurchasing}
            value={purchaseCredits}
            style={{ width: "100%" }}
            step={10}
            min={creditManual.minCredits}
            onChange={(value) =>
              handleSetpurchaseCredits(value === null ? 0 : value.toFixed(0))
            }
          />
        </Form.Item>

        <Row gutter={25}>
          <Col xs={24} lg={12}>
            <Form.Item label="City" name="city">
              <Input placeholder="Enter your City" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Postal Code" name="postalCode">
              <Input placeholder="Enter your Postal Code" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={25}>
          <Col xs={24} lg={12}>
            <Form.Item label="State" name="state">
              <Input placeholder="Enter your State" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Country">
              <Input
                disabled
                value={getCountryByCode(shopData?.country)}
                placeholder="Enter your Country"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Card details">
          <CardElement className="stripeCardInput" />
        </Form.Item>

        <br />
        <div className="purchaseCredits-submitWrap">
          <Form.Item className="purchaseCredits-submit">
            <Button
              loading={isPurchasing}
              type="primary"
              htmlType="submit"
              size="large"
            >
              Purchase Characters
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default PurchaseModal;

import { Input, message, Select } from "antd";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { MixpanelEvents } from "../../../common/constants/analytics/events";
import { Coupons } from "../../../common/constants/coupons";
import { useAuthData } from "../../Auth/store";
import { AppsumoLogo, PitchgroundLogo } from "../../common/Assets/Images";
import { AnalyticsContext } from "../../common/Context/AnalyticsContext";
import { Button } from "../../common/UI/buttons/buttons";
import { useBillingData } from "../Store";
import "./index.scss";

const CouponBox = ({ typeName, logo }) => {
  const [{ shopName }, authActions] = useAuthData();
  const [
    { couponCode, couponType, applyingCoupon },
    { applyCoupon, setCouponCode, setCouponType },
  ] = useBillingData();
  const history = useHistory();
  const { track } = useContext(AnalyticsContext);

  const handleApplyCoupon = (shopName, authActions, history) => {
    track(MixpanelEvents.BILLING.TP_COUPON(couponType, couponCode), {
      couponData: {
        type: couponType,
        code: couponCode,
      },
    });
    return applyCoupon(shopName, authActions, history);
  };

  return (
    <div className="couponBox">
      <div className="couponContent">
        <div className="couponForm">
          <div className="couponHeader">
            <h1>Redeem your {Coupons[couponType]?.label} coupons here!</h1>
            <p>
              If you have purchased our liftetime package through{" "}
              {Coupons[couponType]?.label}, then please redeem your code below.
            </p>
          </div>
          <div className="couponInput">
            <Select
              className="couponTypeInput"
              placeholder="Select the coupon type"
              size="large"
              onChange={(val) => setCouponType(val)}
              value={couponType}
              disabled={applyingCoupon}
            >
              <Select.Option value="pitchground">PitchGround</Select.Option>
              <Select.Option value="appsumo">AppSumo</Select.Option>
            </Select>
            <Input
              className="couponCodeInput"
              placeholder={`Enter ${Coupons[couponType].label} Coupon Code here`}
              onChange={(e) => setCouponCode(e.target.value)}
              value={couponCode}
              disabled={applyingCoupon}
            />
          </div>
          <Button
            className={`applyBtn`}
            size="large"
            type="warning"
            onClick={() => {
              couponCode
                ? handleApplyCoupon(shopName, authActions, history)
                : message.error("We can't do anything without a Coupon code");
            }}
            loading={applyingCoupon}
          >
            Apply Coupon
          </Button>
        </div>
        <img
          className="couponLogo"
          src={couponType === "appsumo" ? AppsumoLogo : PitchgroundLogo}
          alt={Coupons[couponType].label}
        />
      </div>
    </div>
  );
};

export default CouponBox;

import React, { useState, useEffect } from "react";
import sizeMe from "react-sizeme";
import StackGrid from "react-stack-grid";
import "./index.scss";
import { sendPostMessage } from "../../../../../common/Utils/postMessage";
import { useReviewWidget } from "../../store";
import { timeSort } from "../../../../../common/Utils/sorters";
// import ReviewCardList from "./ReviewCard/layouts/List";
// import ReviewCard from "./ReviewCard/layouts/Grid";
import ReviewCard from "./ReviewCard";

const Index = (props) => {
  const [more, setMore] = useState(8);
  //by default 100% width for mobile devices
  const [columnWidth, setColumnWidth] = useState("100%");
  const [widgetState] = useReviewWidget();
  const { reviews, currentTheme, templates } = widgetState;

  const {
    size: { width },
  } = props;

  useEffect(() => {
    //width range for tablets
    if (width > 460 && width < 760) {
      setColumnWidth(238);
    }
    //widht range for laptops and monitors
    if (width > 760 || width === 0) {
      setColumnWidth(238);
    }
  }, [width]); //triggered on width change

  const LoadMoreButtonStyles = {
    backgroundColor: templates[currentTheme]["Body"]?.loadMoreBtnColor,
    color: templates[currentTheme]["Body"]?.loadMoreBtnTextColor,
  };
  const loadMoreButttonText = templates[currentTheme]["Body"]?.loadMoreBtnText;

  const onLoadMore = () => {
    setMore((value) => value + 8);

    setTimeout(() => {
      sendPostMessage();
    }, 1200);
  };

  const { Body } = templates[currentTheme];

  return (
    <>
      {Body.listLayout === "grid" ? (
        <StackGrid columnWidth={columnWidth} monitorImagesLoaded={true}>
          {reviews
            .sort((a, b) => -timeSort(a?.date, b?.date))
            .slice(0, more)
            .map((item, index) => (
              <ReviewCard key={index} item={item} />
            ))}
        </StackGrid>
      ) : (
        <div className={`reviewList-${Body.listLayout}`}>
          {reviews.slice(0, more).map((item, index) => (
            <ReviewCard key={index} item={item} />
          ))}
        </div>
      )}

      {more < reviews.length || props?.storeFrontData === null ? (
        <div
          onClick={onLoadMore}
          className="load-more"
          style={{
            ...LoadMoreButtonStyles,
            borderRadius:
              templates[currentTheme]["Body"]?.loadMoreBtnShape === "round"
                ? "100px"
                : "4px",
          }}
        >
          {loadMoreButttonText}
        </div>
      ) : null}
    </>
  );
};

export default sizeMe()(Index);

import { Col } from "antd";
import React from "react";
// import { useAuthData } from "../Auth/store";
import { Main } from "../common/Style/styled";
// import Banner from "../common/UI/GiftBanner";
import { PageHeader } from "../common/UI/page-headers/page-headers";
import CouponBox from "./common/CouponBox";
import PlanItem from "./Plans/PlanItem";
// import { useBillingData } from "./Store";
import { PricingWrapper } from "./style";

const BaseBilling = () => {
  // const displayPlans = ["FREE", "PRO", "SMART"];
  const displayPlans = ["FREE"];
  // const [{ shopName }, authActions] = useAuthData();

  return (
    <Col gutter={25} justify="center" align="middle">
      {displayPlans?.map((item, key) => (
        <PlanItem planType={item} key={key} />
      ))}
      <div className="coupons" style={{ marginBottom: 30 }}>
        <CouponBox />
      </div>
      {/* <Row align="middle" justify="center" style={{ width: "100%" }}>
        <Banner
          {...{
            type: "billing",
            applyCoupon,
            coupon,
            setCoupon,
            applyingCoupon,
            shopName,
            authActions,
          }}
        />
      </Row> */}
    </Col>
  );
};

const Upgrade = (props) => {
  return (
    <>
      <PageHeader ghost title="Pricing Table" />
      <Main>
        <BaseBilling {...props} />
      </Main>
    </>
  );
};

const Billing = (props) => (
  <PricingWrapper>
    <PageHeader
      ghost
      title="Pricing Table"
      bgColor="#ffffff"
      innerBgColor="#ffffff"
    />
    <Main style={{ background: "#ffffff" }}>
      <BaseBilling {...props} />
    </Main>
  </PricingWrapper>
);

export { Billing, Upgrade };

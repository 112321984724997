import "./index.scss";
import React, { useContext } from "react";
import { Avatar, Popover, Row, Tag, Switch, Tooltip, Col } from "antd";
import FeatherIcon from "feather-icons-react";
// import { useHistory } from "react-router-dom";
import { SyncOutlined } from "@ant-design/icons";
import Heading from "../../../common/UI/heading/heading";
import { UserDropDown } from "../style";
import { routes } from "../../../common/Routes";
import { useCreditsData } from "../../../Credits/store";
import PurchaseModal from "../../../Credits";
import { Button } from "../../../common/UI/buttons/buttons";
import { textRefactor } from "../../../common/Utils/textRefactor";
import IntroHighlight from "../../../Home/common/IntroHighlight";
import DropDownItem from "./common/DropDownItem";
import { PLANS } from "../../../../common/constants";
import { useAuthStore } from "../../../common/Stores";
import { AnalyticsContext } from "../../../common/Context/AnalyticsContext";
import { MixpanelEvents } from "../../../../common/constants/analytics/events";

const DropDown = () => {
  const [{ shopName, isActive, billingDetails, counters }, { setIsActive }] =
    useAuthStore();
  const [, { setViewPurchaseModal }] = useCreditsData();
  const { track } = useContext(AnalyticsContext);

  // const history = useHistory();

  // const permitted = [
  //   "FREE",
  //   "SMART",
  //   "LEGEND",
  //   "LIFE_TIME_SMART",
  //   "LIFE_TIME",
  //   "PG_SMART_A",
  //   "PG_SMART_C",
  //   "PG_SMART_B",
  // ].includes(billingDetails?.subscriptionType);

  return (
    <Row align="middle" style={{ height: "100%" }} className="layoutHeader">
      <Row align="middle" className="hideInMobile">
        {/* {billingDetails.subscriptionType === "SMART" && ( */}
        <PurchaseModal />
        <Popover
          placement="bottom"
          trigger={["hover"]}
          overlayClassName="creditsPop"
          // title={"Credits Remaining"}
          content={
            <Col className="creditsCol">
              <Row className="creditsRow">
                <FeatherIcon icon="award" />
                <p>
                  <span>{counters?.credits ? counters?.credits : 0}</span> AI
                  credits
                </p>
              </Row>
              {/* <Row className="creditsRow">
                <FeatherIcon icon="mail" />
                <p>
                  <span>{counters?.reqEmails ? counters?.reqEmails : 0}</span>{" "}
                  review request emails
                </p>
              </Row> */}
              <br />
              <Row justify="center">
                <Button
                  // type={permitted ? "primary" : "warning"}
                  type={"primary"}
                  onClick={
                    () =>
                      // permitted ?
                      {
                        track(MixpanelEvents.BILLING.CREDITS_MODAL("Opened"));
                        return setViewPurchaseModal(true);
                      }
                    // : history.push(routes.UPGRADE)
                  }
                >
                  {/* {permitted
                    ? "Purchase Credits"
                    : "Upgrade your plan to purchase credits"} */}
                  Purchase Credits
                </Button>
              </Row>
            </Col>
          }
        >
          <Tag style={{ cursor: "pointer" }} color="orange">
            CREDITS
          </Tag>
        </Popover>
        {/* )} */}
      </Row>
      {billingDetails?.subscriptionType ? (
        <Tooltip title="Your current plan" placement="bottom">
          <Tag
            style={{ cursor: "pointer", textTransform: "uppercase" }}
            color="geekblue"
          >
            {PLANS[billingDetails?.subscriptionType]?.name}
          </Tag>
        </Tooltip>
      ) : (
        <Tag icon={<SyncOutlined spin />} color="geekblue">
          Loading
        </Tag>
      )}
      <Tooltip placement="bottom" title="Enable our app on your store">
        <Row
          className="hideInMobile"
          align="middle"
          style={{ margin: "0 25px" }}
        >
          <p style={{ margin: 0, marginRight: "10px" }}>
            {isActive ? "Enabled" : "Disabled"}
          </p>
          <IntroHighlight step={5}>
            <Switch
              disabled={false}
              checked={isActive}
              onChange={(value) => {
                track(MixpanelEvents.GENERAL.STORE_ACTIVE_STATE(value));
                return setIsActive(value);
              }}
            />
          </IntroHighlight>
        </Row>
      </Tooltip>
      <Popover
        placement="bottomLeft"
        content={
          <UserDropDown>
            <div className="user-dropdwon">
              <figure
                className="user-dropdwon__info"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  style={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                  }}
                >
                  {shopName?.charAt(0)?.toUpperCase() || ""}
                </Avatar>
                <figcaption style={{ marginLeft: "10px" }}>
                  <Heading as="h5">{textRefactor(shopName, 50)}</Heading>
                </figcaption>
              </figure>
              <ul className="user-dropdwon__links">
                <DropDownItem
                  className="showOnlyInMobile"
                  icon="box"
                  label={
                    <Row>
                      <p style={{ margin: 0, marginRight: "10px" }}>
                        {isActive ? "Enabled" : "Disabled"}
                      </p>
                      <Switch
                        checked={isActive}
                        onChange={(value) => {
                          track(
                            MixpanelEvents.GENERAL.STORE_ACTIVE_STATE(value)
                          );
                          return setIsActive(value);
                        }}
                        loading={isActive === null}
                      />
                    </Row>
                  }
                />
                <DropDownItem
                  className="showOnlyInMobile"
                  onClick={
                    () =>
                      // permitted ?
                      {
                        track(MixpanelEvents.BILLING.CREDITS_MODAL("Opened"));
                        return setViewPurchaseModal(true);
                      }
                    // : history.push(routes.UPGRADE)
                  }
                  icon="award"
                  label={`${
                    counters?.credits ? counters?.credits : 0
                  } AI credits`}
                />
                <DropDownItem
                  link={routes.UPGRADE}
                  icon="dollar-sign"
                  label="Billing"
                />
                <DropDownItem
                  externalLink={true}
                  link="https://revyu-docs.notion.site/revyu-docs/About-06718052f99543f080a4e9f8fb8c1165"
                  icon="book"
                  label="Docs"
                />
                <DropDownItem
                  externalLink={true}
                  link="mailto:support@revuy.xyz"
                  icon="bell"
                  label="Help"
                />
              </ul>
              <a
                rel="noopener noreferrer"
                href={`https://${shopName}.myshopify.com/admin/apps`}
              >
                <div
                  className="user-dropdwon__bottomAction"
                  style={{ cursor: "pointer" }}
                >
                  <FeatherIcon icon="log-out" /> Sign Out
                </div>
              </a>
            </div>
          </UserDropDown>
        }
      >
        <Avatar
          style={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
          }}
          size="large"
        >
          {shopName.charAt(0).toUpperCase() || ""}
        </Avatar>
      </Popover>
    </Row>
  );
};

export default DropDown;

import logError from "../../../../common/utils/logError";
import { getAccessToken, injectCode } from "../../../../infrastructure/shopify";

export const getToken = async (params, shopName) => {
  try {
    const accessToken = await getAccessToken(params);
    if (accessToken) return codeInjection(shopName);
  } catch (error) {
    logError(error, "getToken()");
  }
};

export const codeInjection = async (shopName) => {
  try {
    const codeInject = await injectCode({ shop: shopName });
    if (codeInject) return codeInject.success;
  } catch (error) {
    logError(error, "codeInjection()");
  }
};

import React from "react";

const SelectBlock = ({ isSelected, value, label, onClick = () => {} }) => {
  return (
    <div
      className={`obSelectBlock ${isSelected ? "obSelectBlock-selected" : ""}`}
      onClick={() => onClick(value)}
    >
      {label}
    </div>
  );
};

export default SelectBlock;

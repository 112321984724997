import { primaryDB } from "../common/firebase";
import { get, post, put } from "../common/remote";

export const extractProducts = (params) => {
  return get("/fetchProducts", params);
};

export const getProducts = (shopName) => {
  return primaryDB.ref(`${shopName}/products`).once("value");
};

export const getProduct = (shopName, productId) => {
  return primaryDB.ref(`${shopName}/products/${productId}`).once("value");
};

export const setAverage = async (shopName, productId, average) => {
  return put("/metaAverage", {
    shop: shopName,
    product_id: productId,
    average,
  });
};

export const getAverage = async (shopName, product_id) => {
  return primaryDB
    .ref(`${shopName}/products/${product_id}/average`)
    .once("value");
};

export const setAiEnabled = async (shopName, productId, isEnabled) => {
  return primaryDB
    .ref(`${shopName}/products/${productId}/aiEnabled`)
    .set(isEnabled);
};

export const postReviews = async (shopName, productId, data) => {
  return await primaryDB.ref(`${shopName}/reviews/${productId}`).set(data);
};

export const setAiProducts = async (shopName, newProductArray) => {
  return await primaryDB
    .ref(`000_Settings/${shopName}/ai/products`)
    .set(newProductArray);
};

export const getAiProducts = async (shopName) => {
  return primaryDB.ref(`000_Settings/${shopName}/ai/products`).once("value");
};

export const setProductDetails = (shopName, productId, data) => {
  return post(`/products`, { shopName, productId, data });
  // return primaryDB.ref(`${shopName}/products/${productId}/details`).set(data);
};

export const getProductDetails = async (shopName, productId) => {
  return primaryDB
    .ref(`${shopName}/products/${productId}/details`)
    .once("value");
};

export const productAiEnabled = async (shopName, productId) => {
  return primaryDB
    .ref(`${shopName}/products/${productId}/aiEnabled`)
    .once("value");
};

import moment from "moment";
import { couponMailTemplates } from "../../../../common/constants";

const initialState = {
  loading: true,
  saving: false,
  previewDevice: "lap",
  formData: {
    isActive: false,
    discount: 10,
    discountType: "percentage",
    mode: "manual",
    typeFilter: "photo", // "photo" | "all"
    ratingFilter: 5, // 5 | 4 | 3 | 2 | 1 ...
    image: [""],
    uploadable: [""],
    template: "A",
    subject: couponMailTemplates["A"].subject,
    previewText: couponMailTemplates["A"].previewText,
    message: couponMailTemplates["A"].message,
    footer: `© ${moment().year()} {Shop Name}. All Rights reserved. Powered by Revyu.`,
    displayStoreName: null,
    displayMail: null,
  },
};

export default initialState;

import React, { useState } from "react";
import "./index.scss";
import sizeMe from "react-sizeme";
import StackGrid from "react-stack-grid";
import { useReviewWidget } from "../../DisplayReviews/ReviewContainer/store";
import { sendPostMessage } from "../../../common/Utils/postMessage";
import ReviewCard from "../../DisplayReviews/ReviewContainer/components/ReviewList/ReviewCard/layouts/Grid";

const ReviewList = (props) => {
  const { reviews } = props;
  const [widgetState] = useReviewWidget();

  const { currentTheme, templates } = widgetState;

  const LoadMoreButtonStyles = {
    backgroundColor: templates[currentTheme]["Body"]?.writeReviewBtnC,
    color: templates[currentTheme]["Header"]?.writeReviewBtnTextC,
  };

  const [more, setMore] = useState(2);
  const {
    size: { width },
  } = props;

  const childProps = {
    templates,
    currentTheme,
  };

  let columnWidth = "100%";
  if (width > 460 && width < 760) {
    columnWidth = 190;
  }
  if (width > 760 || width === 0) {
    columnWidth = 238;
  }

  const onLoadMore = () => {
    setMore((value) => value + 2);

    setTimeout(() => {
      sendPostMessage();
    }, 1200);
  };

  return (
    <>
      <StackGrid columnWidth={columnWidth} monitorImagesLoaded={true}>
        {reviews
          ?.reverse()
          .slice(0, more)
          .map((item) => (
            <ReviewCard key={item.key} {...childProps} {...item} />
          ))}
      </StackGrid>

      {more < reviews?.length ? (
        <div className="loadMoreBtnContainer">
          <div
            onClick={onLoadMore}
            className="load-more"
            style={{ ...LoadMoreButtonStyles }}
          >
            Load More
          </div>
        </div>
      ) : null}
    </>
  );
};

export default sizeMe()(ReviewList);

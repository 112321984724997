import moment from "moment";

const date = new Date();

export const defaultReview = {
  approved: true,
  content:
    "Great product. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.",
  country: "GB",
  date: moment(date.setDate(date.getDate())).format("YYYY-MM-DD HH:mm:ss"),
  displayName: "John Doe",
  email: "johndoe@gmail.com",
  photos: [
    "https://images.unsplash.com/photo-1571781926291-c477ebfd024b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80",
    "https://images.unsplash.com/photo-1571781926291-c477ebfd024b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80",
    "https://images.unsplash.com/photo-1571781926291-c477ebfd024b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80",
  ],
  rating: 4,
  reply: [
    {
      content:
        "Thank you. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      byAi: true,
    },
  ],
};

export const defaultReviews = [
  {
    ...defaultReview,
  },
  {
    ...defaultReview,
    rating: 5,
    displayName: defaultReview.displayName.replace("John", "Zach"),
    content: defaultReview.content.replace("Great", "A great"),
    date: moment(date.setDate(date.getDate() - 1)).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
  },
  {
    ...defaultReview,
    rating: 3,
    displayName: defaultReview.displayName.replace("John", "Angel"),
    content: defaultReview.content.replace("Great", "Nice"),
    date: moment(date.setDate(date.getDate() + 2)).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
  },
];

import { message, Modal } from "antd";
import { PLANS, Coupons } from "../../../common/constants";
import { logError } from "../../../common/utils";

import PlanListItems from "../common/PlanListItems";
import {
  getStorageItem,
  setStorageItem,
} from "../../../infrastructure/common/local";
import { getCoupon, useCoupon } from "../../../infrastructure/coupons";
import { saveCouponLog } from "../../../infrastructure/logs";
import {
  deleteSubscription,
  getBillingDetails,
  getSubscription,
  oneTimeBilling,
  postBillingDetails,
  updateBilling,
} from "../../../infrastructure/shopify";
import {
  changeBillingData,
  getAiWaitlist,
  getStoreCounters,
  getStoreSettings,
  setAiWaitlist,
  setStoreCounters,
  setStoreSettings,
} from "../../../infrastructure/store";
import { routes } from "../../common/Routes";
import { storeSettings } from "./constants";
import { sendDiscordNotification } from "../../../infrastructure/mail";

const actions = {
  subscribe:
    (shop, price, authActions, history, planType, setLoading, recurring) =>
    async ({ dispatch }) => {
      const subData = await getBillingDetails(shop);

      const nonRecurringPlans = ["FREEMIUM", "LIFE_TIME"];

      if (price > 0) {
        try {
          setStorageItem("shop", shop);
          const params = { shop, price, plan: planType };
          let response;
          if (recurring) {
            //To see if this is a recurring charge or a one time payments that is being made.
            response = await updateBilling(params);
          } else {
            response = await oneTimeBilling(params);
          }
          const data = response;
          if (data) {
            window.location.href = data.confirmation_url;
          } else {
            message.error("Shop not found");
          }
        } catch (error) {
          message.error(error.toString());
        }
      } else {
        if (
          // To check if there exists a billing so that we have to send back a request to shopify to cancel the ongoing subscription
          subData.exists() &&
          subData.val()?.subscriptionData?.id &&
          !nonRecurringPlans.includes(subData.val()?.subscriptionType)
        ) {
          const params = {
            shop: shop,
            id: subData.val()?.subscriptionData?.id,
          };
          try {
            const response = await deleteSubscription(params);
            if (response) {
              const subscription = {
                subscriptionType: planType,
                subscriptionData: null,
              };

              const ref = postBillingDetails(shop);
              await ref.set(subscription);

              await dispatch(
                actions.updateCounters(shop, planType, authActions)
              );
              await dispatch(actions.resetStoreSettings(shop));

              authActions.setBillingDetails(subscription);
            }
          } catch (error) {
            message.error(error.toString());
          } finally {
            history.push("/");
            setLoading(false);
          }
        } else {
          const subscription = {
            subscriptionType: planType,
            subscriptionData: null,
          };
          const ref = postBillingDetails(shop);
          await ref.set(subscription);

          await dispatch(actions.updateCounters(shop, planType, authActions));
          await dispatch(actions.resetStoreSettings(shop));

          authActions.setBillingDetails(subscription);
          history.push("/");
          setLoading(false);
        }
      }
    },

  resetStoreSettings: (shopName) => async () => {
    const storeSettingsData = await getStoreSettings(shopName);
    let prevStoreSettings = {};
    if (storeSettingsData.exists()) prevStoreSettings = storeSettingsData.val();
    await setStoreSettings(shopName, {
      ...prevStoreSettings,
      ...storeSettings,
    });
  },

  setRedirect:
    () =>
    ({ setState }) => {
      setState({ redirect: true });
    },

  saveToDb:
    (token, type, authActions) =>
    async ({ dispatch }) => {
      const shop = await getStorageItem("shop");

      const params = { shop, id: token, type };
      authActions.setShopName(shop);

      try {
        const response = await getSubscription(params);
        const data = response;
        let plan = "";
        switch (+data.price) {
          case PLANS.SMART.price:
            plan = "SMART";
            break;
          case PLANS.PRO.price:
            plan = "PRO";
            break;
          default:
            break;
        }
        const subscription = {
          subscriptionType: plan,
          subscriptionData: data,
        };
        const billingRef = postBillingDetails(shop);
        await billingRef.set(subscription);

        await dispatch(actions.updateCounters(shop, plan, authActions));

        dispatch(actions.setRedirect());
      } catch (error) {
        message.error(error.toString());
      }
    },

  updateCounters: (shopName, plan, authActions) => async () => {
    let oldCounter = {
        credits: 0,
        reqEmails: 0,
      },
      newCounter = {
        credits: 0,
        reqEmails: 0,
      };

    try {
      const _countersRaw = await getStoreCounters(shopName);
      if (_countersRaw.exists()) {
        if (_countersRaw.val()?.credits)
          oldCounter.credits = _countersRaw.val()?.credits;
        if (_countersRaw.val()?.reqEmails)
          oldCounter.reqEmails = _countersRaw.val()?.reqEmails;
      }

      newCounter.reqEmails = PLANS[plan].counters.reqEmails;
      if (plan === "FREEMIUM") {
        newCounter.credits = 0; // initially 4000 characters were provided during installation
        if (oldCounter.credits > 0) newCounter.credits += oldCounter.credits;
      } else {
        newCounter.credits = PLANS[plan].counters.credits;
      }

      await setStoreCounters(shopName, newCounter); // update in DB
      authActions.setCounters(newCounter); // update locally
    } catch (error) {
      logError(error, "@ updateCounters() billing ");
    }
  },

  checkWaitlist:
    (shopName) =>
    async ({ dispatch }) => {
      try {
        const _waitlist = await getAiWaitlist();
        if (_waitlist.exists()) {
          let isPresentInWaitlist =
            _waitlist.val().filter((shop) => shop?.shopName === shopName)
              .length > 0;
          dispatch(actions.setWaitlist(_waitlist.val()));
          dispatch(actions.setJoinWaitlist(isPresentInWaitlist));
        } else {
          logError("000_waitlist not present");
        }
      } catch (error) {
        logError(error, " @ checkWaitlist");
      }
    },

  setNewWaitlist:
    (data, setLoading) =>
    async ({ getState, dispatch }) => {
      try {
        const { waitList } = getState();
        let newData = [];
        if (waitList) newData = waitList;
        newData.push(data);
        await setAiWaitlist(newData);
        dispatch(actions.setJoinWaitlist(true));
      } catch (error) {
        logError(error, " @ setNewWaitlist");
      } finally {
        setLoading(false);
      }
    },

  setJoinWaitlist:
    (joinedWaitlist) =>
    ({ setState }) => {
      setState({ joinedWaitlist });
    },

  setWaitlist:
    (waitList) =>
    ({ setState }) => {
      setState({ waitList });
    },

  setCouponCode:
    (couponCode) =>
    ({ setState }) => {
      setState({ couponCode });
    },

  setCouponType:
    (couponType) =>
    ({ setState }) => {
      setState({ couponType });
    },

  setApplyingCoupon:
    (applyingCoupon) =>
    ({ setState }) => {
      setState({ applyingCoupon });
    },

  applyCoupon:
    (shopName, authActions, history) =>
    async ({ dispatch, getState }) => {
      dispatch(actions.setApplyingCoupon(true));
      const { couponCode, couponType } = getState();
      const couponLabel = Coupons[couponType]?.label;
      const log = {
        type: couponType,
        code: couponCode,
        stamp: Date.now(),
      };

      // common func for saving err log, used only in this action
      const saveErrLog = (reason) =>
        saveCouponLog(shopName, { ...log, status: "failed", msg: reason });

      try {
        const _couponShot = await getCoupon(couponType, couponCode);
        if (_couponShot.exists()) {
          const _couponData = _couponShot.val();

          if (!_couponData?.isUsed) {
            if (_couponData?.type === couponType) {
              await dispatch(
                actions.handleCouponSuccess(
                  shopName,
                  _couponData,
                  authActions,
                  log,
                  history
                )
              );
            } else {
              message.error(`Invalid ${couponLabel} coupon code!`);
              await saveErrLog("Invalid coupon type");
            }
          } else {
            message.error(`${couponLabel} coupon already used!`);
            await saveErrLog("Coupon already used");
          }
        } else {
          message.error(`Invalid ${couponLabel} coupon code!`);
          await saveErrLog("Coupon not found");
        }
        dispatch(actions.setCouponCode(null));
      } catch (error) {
        message.error(`Some error occured!`);
        logError(error, "applyCoupon()");
        await saveErrLog("Some error occured");
      } finally {
        dispatch(actions.setApplyingCoupon(false));
      }
    },

  handleCouponSuccess:
    (shopName, _couponData, authActions, log, history) =>
    async ({ getState, dispatch }) => {
      const { couponCode, couponType } = getState();
      const couponLabel = Coupons[couponType]?.label;
      const plan = _couponData?.plan;
      const subscription = {
        subscriptionType: plan,
        subscriptionData: null,
      };
      await changeBillingData(shopName, plan);
      await dispatch(actions.updateCounters(shopName, plan, authActions));
      authActions.setBillingDetails(subscription);
      await useCoupon(couponType, couponCode, {
        ..._couponData,
        isUsed: true,
        usedWhen: Date.now(),
        usedBy: shopName,
      });
      await saveCouponLog(shopName, {
        ...log,
        status: "success",
        msg: "Coupon applied",
      });
      await sendDiscordNotification(
        "celebration",
        `**${shopName}**: subscribed for ${plan} ($${PLANS[plan].price}) in Revyu`
      );
      message.success(`${couponLabel} Coupon applied!`);

      Modal.success({
        title: `${plan.split("_").join(" ")} is now yours.`,
        centered: true,
        content: <PlanListItems plan={plan} />,
        okText: "Continue",
        okButtonProps: { size: "large" },
        onOk: () => history.push(routes.DASHBOARD),
      });
    },
};

export default actions;

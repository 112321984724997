import { SMART } from "./SMART";

export const FREEMIUM = {
  name: "Freemium",
  price: 0.0,
  recurring: true,
  counters: {
    credits: 5000,
    reqEmails: 200,
  },
  permissions: {
    ...SMART.permissions,
  },
  features: [
    "5000 AI characters on total",
    "200 Review Email requests/month",
    "Unlimited photo reviews",
    "Unlimited review imports from AliExpress & CSV",
    // "9+ pre-built themes",
    // "Theme customization",
    "Theme customization with 9+ pre-built themes",
    "PopUp Reviews",
    "Rich SEO Snippets",
    "50 Review Limit per product",
    "10 Product Limit for Review Import",
    "Auto-generate Name, Country & Gender for AliExpress imports",
  ],
};

export const quickFiltersDesc = [
  "Import all reviews",
  "Publish all reviews",
  "Disable auto generations",
];

export const dropShippingFiltersDesc = [
  "Import reviews with rating 3 and above",
  "Publish reviews with rating 4 and above",
  "Enable auto generation for country, gender and name",
];

export const quickFilters = {
  autoPublish: {
    criteria: "all",
  },
  autoGen: {
    country: {
      active: false,
      value: "US",
    },
    gender: {
      active: false,
      value: "MX",
    },
    name: {
      active: false,
      value: "US",
    },
  },
  filter: {
    content: "both",
    countries: "All",
    rating: 1,
  },
};

export const dropShippingFilters = {
  autoPublish: {
    criteria: "4",
  },
  autoGen: {
    country: {
      active: true,
      value: "US",
    },
    gender: {
      active: true,
      value: "MX",
    },
    name: {
      active: true,
      value: "US",
    },
  },
  filter: {
    content: "both",
    countries: "All",
    rating: 3,
  },
};

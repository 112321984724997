import React from "react";
import "./index.scss";
import Avatar from "../Avatar";
import Country from "../Country";
import AuthorName from "../AuthorName";
import ReviewDate from "../ReviewDate";

const Author = (props) => {
  return (
    <div className="author-container">
      <div className="author-content">
        <Avatar {...props} />
        <div>
          <AuthorName {...props} />
          <ReviewDate {...props} />
        </div>
      </div>
      <Country {...props} />
    </div>
  );
};

export default Author;

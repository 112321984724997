import "./index.scss";
import React from "react";
import CardBody from "../../components/CardBody";
import RatingBadge from "../../components/RatingBadge";
// import ProductImages from "../../components/ProductImages";
import ReviewImages from "../../components/ReviewImages";

const GridLayout = (props) => {
  const { setShowModal } = props;
  return (
    <>
      <div className="card-img">
        <ReviewImages {...props} />
        <RatingBadge {...props} />
      </div>
      <CardBody onClick={() => setShowModal(true)} {...props} />
    </>
  );
};

export default GridLayout;

export const MixpanelEvents = {
  ONBOARDING: {
    START: "Start Onboarding",
    COMPLETE: "Complete Onboarding",
    CUSTOMIZE: "Progressed To Customize",
    FEATURE_SELECT: "Progressed to Feature Select",
    CHANGE_THEME: {
      GENERAL: "Update general elements",
      BODY: "Update body elements",
      HEADER: "Update header elements",
    },
  },
  HOME: {
    RESTART_SETUP: "Setup restarted",
    AI_BANNER: "Click on AI events banner",
    CUSTOMIZE_BANNER: "Click on Customize Review Widget banner",
  },
  MANAGE_REVIEWS: {
    CSV_BULK: (state) => `${state} import Bulk CSV Modal`,
    REFRESH: "Refreshed product list",
    REVIEW_TYPE: (value) => `Toggled review type to ${value}`,
    FILTER: (value) => `Toggled filter to ${value}`,
    PRODUCT_SELECT: (id) => `Selected product ${id}`,
    VIEW_IN_STORE: (id) => `Clicked on View in store for product ${id}`,
    BULK_EDIT: (type, id) => `${type} all reviews for the product ${id}`,
  },
  SETTINGS: {
    SAVE: "Save settings",
    TOGGLE_TYPE: (type) => `Toggled settings type to ${type}`,
    CHANGE_SETTINGS: (setting, value) => `Changed ${setting} to ${value}`,
    INFO_MODAL: (setting, state) => `${state} info modal of ${setting}`,
  },
  THEME_SETTINGS: {
    SAVE: "Saved revyu widget theme",
    RESET: (state) => `Reset revyu widget theme ${state}`,
    ALTER_THEME: (type, key, value) =>
      `In ${type} theme settings changed the ${key} to ${value}`,
  },
  WIDGETS: {
    TOGGLE_WIDGET: (type, value) => `${value} ${type} widget to the store`,
  },
  BILLING: {
    TP_COUPON: (source, coupon) => `Coupon: ${coupon} applied from ${source}`,
    CREDITS_MODAL: (state) => `Buy credits modal ${state}`,
    GET_STARTED: (plan) => `Get started button clicked with plan: ${plan}`,
    BILLING_COMPLETE: (type, id) =>
      `Billing completed of type: ${type} and id: ${id}`,
    CREDITS_PURCHASE: (credit, amount) =>
      `Purchased credits ${credit} for ${amount}`,
  },
  POPUP: {
    TOGGLE_STATE: (state) => `Popup widget toggled to state: ${state}`,
    SAVE_SETTINGS: `Popup settings saved`,
    ALTER_SETTINGS: (key, value) =>
      `${key} value changed to ${value} in popup settings`,
    RESET: (state) => `Reset popup widget settings ${state}`,
  },
  GENERAL: {
    ROUTE_TO: (to, via) => `Routed from to ${to} via ${via}`,
    STORE_ACTIVE_STATE: (value) => `Store active state changed to ${value}`,
    START: "Session start",
  },
  USER_ENGAGEMENT: {
    TOGGLE: (state, engage = "mail") =>
      `${
        engage === "mail" ? "Review request mail" : "Coupon"
      } state toggled to ${state}`,
    EDIT: (engage = "mail") =>
      `${
        engage === "mail" ? "Review request mail" : "Coupon"
      } edit button clicked`,
    TEST_MAIL: (engage = "mail") =>
      `${engage === "mail" ? "Review request" : "Coupon"} test mail sent.`,
    SAVE: (engage = "mail") =>
      `Save ${engage === "mail" ? "Review request mail" : "Coupon"} settings`,
    ALTER_SETTINGS: (type, key, value, engage = "mail") =>
      `Changed ${key} value to ${value} in ${type} in ${
        engage === "mail" ? "review request mail" : "coupon"
      } settings`,
    TOGGLE_DEVICE: (type, engage = "mail") =>
      `${
        engage === "mail" ? "Review request mail" : "Coupon"
      } view device toggled to ${type}`,
  },
  MANAGE_PRODUCT_REVIEWS: {
    PUBLISH_REVIEW: (state) => `${state ? "Published" : "Unpublished"} review`, // state => true || false
    EDIT_MODAL: (state) => `Edit review modal ${state}`, //state => opened || closed
    EDIT: `Edited review`,
    REPLY_MODAL: (state) => `Reply to review modal ${state}`, // state => opened || closed
    REPLY: {
      GENERATE: `Generated review reply using AI for review`,
      DELETE: `Deleted review reply`,
      REPLY: `Replied to review`,
    },
    FIX_COUNTRY: `Fix country button clicked.`,
    FIX_DATES: `Fix dates button clicked.`,
    AI: {
      AI_MODAL: (state) => `Ai Enable modal ${state}`, // state => opened || closed
      ENABLE_REVIEW_GEN: (state) => `${state} review generateion feature`, // State => Enabled || Disabled || Saved
    },
    DELETE: `Deleted review`,
    BULK_ACTION: (state) => `${state} all selected reviews`, // State => Published || Unpublished || Deleted
    VIEW_IN_STORE: `View is store button clicked`,
    NEW_REVIEW: {
      NEW_REVIEW_MODAL: (state) => `New review modal ${state}`, // state => opened || closed
      ALI_IMPORT: `Clicked on Ali Express import`,
      CSV_IMPORT: `Clicked on CSV import`,
    },
    SEND_COUPON: `Coupon sent!`,
  },
};

import React, { useContext } from "react";
import { MixpanelEvents } from "../../../../common/constants/analytics/events";
import { AnalyticsContext } from "../../../common/Context/AnalyticsContext";
import { Button } from "../../../common/UI";
import "./index.scss";

const ObBodyLayout = ({
  children,
  image,
  title = "",
  nextBtnText = "Next",
  nextBtnAction = () => {},
  nextBtnLoading = false,
}) => {
  const { track } = useContext(AnalyticsContext);

  const handleNextButtonAction = () => {
    track(MixpanelEvents.ONBOARDING.CUSTOMIZE, {
      step: 1,
    });
    nextBtnAction();
  };

  return (
    <section className="onboardingBody">
      <div className="onboardingLeft">
        <div className="obContent">
          <h1 className="obTitle">{title}</h1>
          <div className="obChildren">{children}</div>
          <Button
            type="warning"
            size="large"
            loading={nextBtnLoading}
            onClick={handleNextButtonAction}
          >
            {nextBtnText}
          </Button>
        </div>
      </div>
      <div className="onboardingRight">{image}</div>
    </section>
  );
};

export default ObBodyLayout;

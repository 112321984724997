import { rrMailVariants } from "../variants/rrMailVariants";

export const rrMailTemplates = {
  A: {
    name: "Grateful",
    subject: "Your Feedback Matters!",
    previewText: "We hope you're enjoying your purchase from our store.",
    message: `Dear ${rrMailVariants["Buyer First Name"]},

We hope you're enjoying the product ${rrMailVariants["Product Name"]} you recently purchased from our store.

Your satisfaction is our top priority, and we would greatly appreciate it if you could take a moment to leave us a review of the product ${rrMailVariants["Product Name"]}. Your feedback helps us improve and provide the best possible shopping experience for all of our customers.

Thank you for choosing our store and for your support.

Best regards,
${rrMailVariants["Shop Name"]}`,
  },
  B: {
    name: "Casual",
    subject: "How Was Your Experience?",
    previewText:
      "We value your opinion and would love to hear about your experience with us.",
    message: `Hi ${rrMailVariants["Buyer First Name"]},

We hope you're having a great day!

We wanted to reach out and ask for your thoughts on the product ${rrMailVariants["Product Name"]} you recently purchased from our store. Your feedback is extremely valuable to us and helps us understand how we can better serve our customers. If you have a moment, we would really appreciate it if you could leave a review of your experience with the product ${rrMailVariants["Product Name"]}.
  
Thanks for your time and for choosing our store. We hope to see you again soon!
    
Best regards,
${rrMailVariants["Shop Name"]}`,
  },
  C: {
    name: "Professional",
    subject: "Request for Customer Feedback",
    previewText:
      "We value your input and would appreciate if you could leave a review of your recent purchase.",
    message: `Dear ${rrMailVariants["Buyer First Name"]},

Thank you for your recent purchase from our store. We hope you are satisfied with the product ${rrMailVariants["Product Name"]} you received.

At ${rrMailVariants["Shop Name"]}, we value the input of our customers and would appreciate if you could take a few minutes to leave a review of your experience with the product ${rrMailVariants["Product Name"]}. Your feedback will help us improve and provide the best possible shopping experience for all of our customers.

Thank you for your time and for choosing our store.

Best regards,
${rrMailVariants["Shop Name"]}`,
  },
  D: {
    name: "Urgent",
    subject: "Quick Review Request",
    previewText:
      "Can you help us out by leaving a review of your recent purchase?",
    message: `Hi ${rrMailVariants["Buyer First Name"]},

We hope you're enjoying the product ${rrMailVariants["Product Name"]} you recently purchased from our store.

We strive to provide the best possible shopping experience for all of our customers, and one way we do this is by collecting feedback from people like you. If you have a moment, we would really appreciate it if you could take the time to leave a review of the product ${rrMailVariants["Product Name"]}. Your thoughts are important to us and will help us improve.

Thanks in advance for your help, and we hope to see you again soon.

Best regards,
${rrMailVariants["Shop Name"]}`,
  },
};

export { PLANS } from "./plans";
export { Coupons } from "./coupons";
export { defaultDevShop } from "./shop";
export { countries } from "./countryNames";
export { credentials } from "./credentials";
export { bannerImage } from "./placeholders";
export { settingsConst } from "./reviewFilter";

export { popupThemes } from "./themes/popupThemes";
export { reviewThemes } from "./themes/reviewThemes";

export { rrMailVariants } from "./variants/rrMailVariants";
export { couponMailVariants } from "./variants/couponMailVariants";

export { rrMailTemplates } from "./templates/rrMailTemplates";
export { couponMailTemplates } from "./templates/couponMailTemplates";

export const minCreditsForGen = 64;

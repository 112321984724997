export const stripShopName = (shop) => {
  return shop.split(".myshopify.com")[0];
};

export const checkString = (val) => (Number.isInteger(val) ? val : 0);

export const calculateAvg = (payLoad) => {
  // Consider reviews that are approved only.
  payLoad = payLoad.filter((rev) => rev.approved);
  const noOfReviews = payLoad.length;
  const ratingSum = payLoad.reduce(
    (acc, item) => acc + checkString(+item.rating),
    0
  );
  let avgRating = 0;
  if (noOfReviews > 0) avgRating = (ratingSum / noOfReviews).toFixed(1);
  return {
    total: noOfReviews,
    avgRating,
  };
};

import React, { useContext } from "react";
import {
  // Badge,
  Menu,
  // Row
} from "antd";
import { NavLink, useRouteMatch, Link } from "react-router-dom";
import { routes } from "../../common/Routes";
import { FeatherIcon } from "../../common/Assets/Icons";
import { AnalyticsContext } from "../../common/Context/AnalyticsContext";
import { MixpanelEvents } from "../../../common/constants/analytics/events";
// import { useAuthData } from "../Auth/store";

const MenuItems = ({ darkMode, topMenu, events }) => {
  const { path } = useRouteMatch();
  const { track } = useContext(AnalyticsContext);

  const handleTracking = (r) =>
    track(MixpanelEvents.GENERAL.ROUTE_TO(r, "Nav Link"));

  let pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const subPath = pathArray[2] || "";
  // const [{ isThemeApp }] = useAuthData();

  const [openKeys, setOpenKeys] = React.useState([
    `${pathArray.length > 2 ? mainPath : "dashboard"}`,
  ]);

  const [selectedKey, setSelectedKey] = React.useState([
    `${
      pathArray.length === 2
        ? mainPath === ""
          ? "dashboard"
          : mainPath
        : subPath
    }`,
  ]);

  React.useEffect(() => {
    setOpenKeys([`${pathArray.length > 2 ? mainPath : "dashboard"}`]);
    setSelectedKey(
      pathName === "/"
        ? routes.DASHBOARD
        : Object.values(routes).find(
            (route) => route !== "/" && pathName.startsWith(route)
          )
    );
  }, [mainPath, pathArray.length, subPath, pathName]);

  const onOpenChange = (keys) => {
    setOpenKeys(
      keys[keys.length - 1] !== "recharts"
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      theme={darkMode && "dark"}
      defaultSelectedKeys={[
        `${
          pathArray.length === 2
            ? mainPath === ""
              ? "dashboard"
              : mainPath
            : subPath
        }`,
      ]}
      selectedKeys={selectedKey}
      defaultOpenKeys={
        !topMenu ? [`${pathArray.length > 2 ? mainPath : "dashboard"}`] : []
      }
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      <Menu.Item
        onClick={() => handleTracking(routes.DASHBOARD)}
        key={routes.DASHBOARD}
        icon={
          <NavLink className="menuItem-icon" to={routes.DASHBOARD}>
            <FeatherIcon icon="home" />
          </NavLink>
        }
      >
        <Link to={routes.DASHBOARD}>Dashboard</Link>
      </Menu.Item>
      <Menu.Item
        onClick={() => handleTracking(routes.REVIEWS_MANAGE)}
        key={routes.REVIEWS_MANAGE}
        icon={
          <NavLink className="menuItem-icon" to={routes.REVIEWS_MANAGE}>
            <FeatherIcon icon="star" />
          </NavLink>
        }
      >
        <Link to={routes.REVIEWS_MANAGE}>Reviews</Link>
      </Menu.Item>
      <Menu.SubMenu
        key="settings"
        title="Settings"
        icon={<FeatherIcon icon="settings" />}
      >
        <Menu.Item
          onClick={() => handleTracking(routes.SETTINGS_GENERAL)}
          key={routes.SETTINGS_GENERAL}
        >
          <Link to={routes.SETTINGS_GENERAL}>General</Link>
        </Menu.Item>
        <Menu.Item
          onClick={() => handleTracking(routes.SETTINGS_WIDGETS)}
          key={routes.SETTINGS_WIDGETS}
        >
          <Link to={routes.SETTINGS_WIDGETS}>Widgets</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key="widgets"
        title="Theme"
        icon={<FeatherIcon icon="layers" />}
      >
        <Menu.Item
          onClick={() => handleTracking(routes.WIDGET_REVIEW_CUSTOMIZE)}
          key={routes.WIDGET_REVIEW_CUSTOMIZE}
        >
          <Link to={routes.WIDGET_REVIEW_CUSTOMIZE}>Review</Link>
        </Menu.Item>
        <Menu.Item
          onClick={() => handleTracking(routes.WIDGET_POPUP_CUSTOMIZE)}
          key={routes.WIDGET_POPUP_CUSTOMIZE}
        >
          <Link to={routes.WIDGET_POPUP_CUSTOMIZE}>Popup</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key="user"
        title="Outreach"
        icon={<FeatherIcon icon="custom_user_engagement" />}
      >
        <Menu.Item
          onClick={() => handleTracking(routes.SETTINGS_EMAIL)}
          key={routes.SETTINGS_EMAIL}
        >
          <Link to={routes.SETTINGS_EMAIL}>Email</Link>
        </Menu.Item>
        <Menu.Item
          onClick={() => handleTracking(routes.COUPONS)}
          key={routes.COUPONS}
        >
          <Link to={routes.COUPONS}>Coupons</Link>
        </Menu.Item>
      </Menu.SubMenu>
      {/* <Menu.Item
        key={routes.INTEGRATIONS}
        icon={
          <NavLink className="menuItem-icon" to={routes.INTEGRATIONS}>
            <FeatherIcon icon="tool" />
          </NavLink>
        }
      >
        <Link to={routes.INTEGRATIONS}>Integrations</Link>
      </Menu.Item> */}
      <Menu.Item
        key={routes.UPGRADE}
        onClick={() => handleTracking(routes.UPGRADE)}
        icon={
          <NavLink className="menuItem-icon" to={routes.UPGRADE}>
            <FeatherIcon icon="dollar-sign" />
          </NavLink>
        }
      >
        <Link to={routes.UPGRADE}>Billing</Link>
      </Menu.Item>
    </Menu>
  );
};

export default MenuItems;

import { get } from "../common/remote";

const rootPath = `/ai`;

export const getProductReview = (params) => {
  return get(`${rootPath}/productReview`, params);
};

export const getReviewReply = (params) => {
  return get(`${rootPath}/reviewReply`, params);
};

export const getKeywordsReview = (params) => {
  return get(`${rootPath}/getKeywordReview`, params);
};

import React, { createContext } from "react";
import MixpanelActions from "../../../common/utils/analytics";
import { useAuthData } from "../../Auth/store";

export const AnalyticsContext = createContext();

const AnalyticsContextProvider = ({ children }) => {
  const [
    { shopData, shopName, isActive, isThemeApp, billingDetails, counters },
  ] = useAuthData();

  const track = (event, extraData = {}) => {
    MixpanelActions.track(event, {
      shopName,
      isThemeApp,
      isActive,
      ...shopData,
      ...billingDetails,
      ...counters,
      ...extraData,
    });
  };

  return (
    <AnalyticsContext.Provider value={{ track }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsContextProvider;

const planFeatures = {
  PG_SMART_A: [
    "15000 AI Credits",
    "2000 Review Email requests/month",
    "Smart review sorting by AI",
    "AI review replier",
    "AI review writer",
  ],
  PG_SMART_B: [
    "30000 AI Credits",
    "4000 Review Email requests/month",
    "Smart review sorting by AI",
    "AI review replier",
    "AI review writer",
  ],
  PG_SMART_C: [
    "100000 AI Credits",
    "10000 Review Email requests/month",
    "Smart review sorting by AI",
    "AI review replier",
    "AI review writer",
  ],
};
export default planFeatures;

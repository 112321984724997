import { Row, Tooltip } from "antd";
import { FeatherIcon } from "../../Assets/Icons";
import "./index.scss";

const InputWrapper = ({
  title = "",
  children,
  style = {},
  childStyle = {},
  message = "",
  className = "",
  help = "",
}) => {
  return (
    <div className={`inputWrapper ${className}`} style={style}>
      <Row align="middle" className="inputWrapper-titleCont">
        <h3 className="inputWrapper-title">{title}</h3>
        {help && (
          <Tooltip title={help}>
            <p className="inputWrapper-helpIcon">
              <FeatherIcon size={16} icon="help-circle" />
            </p>
          </Tooltip>
        )}
      </Row>
      <div className="inputWrapper-children" style={{ ...childStyle }}>
        {children}
      </div>
      <p className="inputWrapper-message">{message}</p>
    </div>
  );
};

export default InputWrapper;

import { HeartFilled, StarFilled } from "@ant-design/icons";
import "./index.scss";
import { Rate } from "antd";
import React from "react";

export const PlainRating = (props) => {
  const { rating, templates, currentTheme } = props;
  const { General } = templates[currentTheme];
  return (
    <div className="stars-group">
      <Rate
        value={rating}
        defaultValue={5}
        disabled
        character={General?.icon === "heart" ? <HeartFilled /> : <StarFilled />}
        style={{
          color: General?.iconColor,
          fontSize: "14px",
        }}
      />
    </div>
  );
};

const RatingBadge = (props) => {
  return (
    <div className="clearfix">
      <div className="stars-container">
        <PlainRating {...props} />
      </div>
    </div>
  );
};

export default RatingBadge;

export const planWords = {
  FREE: {
    userType: "Forever Free",
    // btnType: "white",
    // headingType: "black",
    // badgeType: "gray",
    
    btnType: "warning",
    headingType: "warning",
    badgeType: "warning",
  },
  PRO: {
    userType: "Everything in FREE +",
    btnType: "primary",
    headingType: "primary",
    badgeType: "primary",
  },
  SMART: {
    userType: "Everything in PRO +",
    btnType: "warning",
    headingType: "warning",
    badgeType: "warning",
  },
};

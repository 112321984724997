// import { Result } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import { routes } from "../common/Routes/routes";
import { Main } from "../common/Style/styled";
import { Button } from "../common/UI/buttons/buttons";
import Heading from "../common/UI/heading/heading";
import { ErrorWrapper } from "../common/Style/styled";
import { PageNotFound } from "../common/Assets/Images";

const Error404 = () => {
  return (
    <Main style={{ maxHeight: "100vh" }}>
      <ErrorWrapper>
        <img src={PageNotFound} alt="404" />
        <Heading className="error-text" as="h3">
          404
        </Heading>
        <p>Sorry! the page you are looking for doesn't exist.</p>
        <NavLink to={routes.DASHBOARD}>
          <Button size="default" type="primary" to="/admin">
            Return Home
          </Button>
        </NavLink>
      </ErrorWrapper>
    </Main>
  );
};

export default Error404;
